// DashItemModal mirrors the appearance of a DashItem and duplicates/adds to its content and animates center-screen when a DashItem is expanded.

import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import images from '../../../config/images';
import './style.css';
import DashItem_EMS from '../DashItem_EMS';

class DashItemModal extends React.Component {

	// Set initial props
    static defaultProps = {
        
    }

    constructor(props) {
        super(props);

        this.expandedWidth = 800;
        this.expandedHeight = 500;
        this.collapsedWidth = 256;
        this.collapsedHeight = 256;

        this.mounted = false;

        this.style = {
            overflow: 'hidden',
            contain: 'content',
            boxShadow: '0 4px 5px rgba(0, 0, 0, 0.2)',
            willChange: 'transform',
            animationDuration: '200ms',
            animationTimingFunction: 'step-end',
            display: 'absolute',
            textAlign: 'center',
            padding: '20px',
            margin: '20px',
            width: this.collapsedWidth+'px',
            height: this.collapsedHeight+'px',
            backgroundColor: this.props.config.showBackground ? this.props.config.color : this.hexToRGB(this.props.config.color, '0'),
            //ackgroundImage: 'url('+this.props.config.icon+')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            transition: '0.3s ease',
            //webkitTransition: 'background 0.3s ease',
            //mozTransition: 'background 0.3s ease',
            //oTransition: 'background 0.3s ease',
        }

        if (this.props.config.icon){
            this.style.backgroundImage = 'url('+this.props.config.icon+')';
        }

        if (this.props.config.showBackground){
            this.style.boxShadow = '0 4px 5px rgba(0, 0, 0, 0.2)'
            
        } else {
             this.style.backgroundColor = 'rgba(0,0,0,0)' 
       
        }

        this.placeholderStyles = {
            position: 'fixed',
            top: '50%',
            left: '50%',
            display: 'absolute',
            textAlign: 'center',
            width: this.expandedWidth+'px',
            height: this.expandedHeight+'px',
            marginTop: -this.expandedHeight/2 + 'px',
            marginLeft: -this.expandedWidth/2 + 'px',
            backgroundColor: this.props.config.color,
            transition: '0.3s ease',
            WebkitTransition: '0.3s ease',
            opacity: 1.0,
            zIndex: 1000,
            visibility: 'visible',
        };

        this.style = {...this.style, ...this.placeholderStyles};
        


        // Set initial state
        this.state = {
            style: this.style,
            expandIconOffset: this.props.config.showBackground ? '30px' : '48px',
            expanded: false,
        };
    }

    componentWillMount(){
        // set rect to config origin bounding rect
        this.style.top = this.props.config.boundingRect.top + 'px';
        this.style.left = this.props.config.boundingRect.left + 'px';
        this.style.width = this.props.config.boundingRect.width + 'px';
        this.style.height = this.props.config.boundingRect.height + 'px';
        this.style.marginTop = '0px';
        this.style.marginLeft = '0px';
    }

    componentDidMount() {
        this.mounted = true;

        this.expandModal();
        this.init();
    }

    expandModal = () => {
        this.style.top = '50%';
        this.style.left = '50%';
        this.style.width = this.expandedWidth+'px';
        this.style.height = this.expandedHeight+'px';
        this.style.marginTop = -this.expandedHeight/2 + 'px';
        this.style.marginLeft = -this.expandedWidth/2 + 'px';
        this.setState({ style: this.style, expandIconOffset: '30px', expanded: true});
        if (this.props.config.icon){
            this.style.backgroundImage = 'url('+images.blank+')';
        }
        if (!this.props.config.showBackground){
            this.style.backgroundColor = this.hexToRGB(this.props.config.color, 1);
            this.style.boxShadow = '0 4px 5px rgba(0, 0, 0, 0.2)'
            
        }
        //document.querySelector("#foo").style.backgroundImage = "url(b.jpg)";
    }

    // Triggered internally
    collapseModal = () => {
        let newRect = this.props.updateRectFunction();
        this.props.hideAnimation();
        this.style.top = newRect.top + 'px';
        this.style.left = newRect.left + 'px';
        this.style.width = newRect.width + 'px';
        this.style.height = newRect.height + 'px';
        //this.style.top = this.props.config.boundingRect.top + 'px';
        //this.style.left = this.props.config.boundingRect.left + 'px';
        //this.style.width = this.props.config.boundingRect.width + 'px';
        //this.style.height = this.props.config.boundingRect.height + 'px';
        this.style.marginTop = '0px';
        this.style.marginLeft = '0px';
        if (this.props.config.icon){
            this.style.backgroundImage = 'url('+this.props.config.icon+')';
        }
        if (!this.props.config.showBackground){
            this.style.backgroundColor = this.hexToRGB(this.props.config.color, '0');
            this.style.boxShadow = '0 4px 5px rgba(0, 0, 0, 0)'
            
        }
        this.setState({ style: this.style, expandIconOffset: this.props.config.showBackground ? '30px' : '48px', expanded: false });
        setTimeout(this.unmountComponent, 300);
    }

    // Triggered externally
    handleCollapseModal = () => {
        let newRect = this.props.updateRectFunction();
        //this.traceRect(newRect);
        this.style.top = newRect.top + 'px';
        this.style.left = newRect.left + 'px';
        this.style.width = newRect.width + 'px';
        this.style.height = newRect.height + 'px';
        //this.style.top = this.props.config.boundingRect.top + 'px';
        //this.style.left = this.props.config.boundingRect.left + 'px';
        //this.style.width = this.props.config.boundingRect.width + 'px';
        //this.style.height = this.props.config.boundingRect.height + 'px';
        this.style.marginTop = '0px';
        this.style.marginLeft = '0px';
        this.setState({ style: this.style, expanded: false });
        setTimeout(this.unmountComponent, 300);
    }

    unmountComponent = () => {
        this.props.handleCloseItem();
    }

    componentWillUnmount() {
        this.mounted = false;
    }


    componentWillUpdate(nextProps, nextState) {
        if (this.props.config.id !== nextProps.config.id){

        }

        
        if (nextProps.willClose && nextProps.willClose !== this.props.willClose){
            this.handleCollapseModal();
        }
        
    }

    // ---- Member Methods (Use arrow syntax to auto-bind) ----

    init = () => {

    }



    // ---- Render Methods ----

    renderInnerComponent = () => {
        let componentName = this.props.config.expandedComponentName;

        if (componentName){
            switch(componentName){
                case 'DashItem_EMS':
                    return (<DashItem_EMS />);
                    break;
            }

        }
        /*
        {Component && this.state.expanded ? <div className='contentDiv'>
            <h4><u>Rental Status:</u></h4>
            <p>• 240 Items On Rent</p>
            <p>• 12 Need Confirmation</p>
        </div> : null}
        */
        {/* this.props.config.expandedComponentName ? 'ok' : 'none' */}
    }

    render() {
        let style = { ...this.style };
        //style.backgroundColor = this.props.config.color;

        
    	return (
    		<div ref={(ref) => this.myRef = ref} className='DashItemModal' style={style}>
	    		<div>
	    			<h2 className='DashItem-Title'>{this.props.config.name}</h2>
                    { this.state.expanded && this.renderInnerComponent() }

	    		</div>
                <a onClick={this.collapseModal}><div style={{ position: 'absolute', bottom: '0', right: '0', cursor: 'pointer', width: this.state.expandIconOffset, height: this.state.expandIconOffset }}><Glyphicon glyph="resize-small" style={{ position: 'absolute', top: '0', left: '0', color: 'rgba(0,0,0,0.6)', backgroundColor: 'rgba(0,0,0,0.1)', padding: '3px', border: '1px solid rgba(0,0,0,0.6)' }}/></div></a>
    		</div>
    	);
    }

    // ---- Utility Methods ----

    rectsAreIdentical = (rect1, rect2) => {
        if (rect1.left !== rect2.left) return false;
        if (rect1.top !== rect2.top) return false;
        if (rect1.width !== rect2.width) return false;
        if (rect1.height !== rect2.height) return false;
        return true;
    }

    rectDifference = (rect1, rect2) => {
        let obj = { left: 0, top: 0, bottom: 0, right: 0, width: 0, height: 0 };
        obj.left = rect2.left - rect1.left;
        obj.top = rect2.top - rect1.top;
        obj.bottom = rect2.bottom - rect1.bottom;
        obj.right = rect2.right - rect1.right;
        obj.width = rect2.width - rect1.width;
        obj.height = rect2.height - rect1.height;
    }

    traceRect = (rect) => {
        console.log('Rect: left: '+rect.left+' top:'+rect.top+' w: '+rect.width+' h: '+rect.height);
    }

    hexToRGB = (hex, alpha) => {
        let r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return "rgba(" + r + "," + g + "," + b + "," + alpha + ")";
        } else {
            return "rgb(" + r + "," + g + "," + b + ")";
        }
    }
}

export default DashItemModal;
