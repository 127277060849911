
import React from 'react';
import { Modal, Button, FormGroup, ControlLabel, FormControl, Alert, Form, Row, Col, HelpBlock } from 'react-bootstrap';
import API from '../../lib/API';
import { validateEmail } from '../../lib/API';
import { user } from '../../config/data';
import randombytes from 'randombytes';
import images from '../../config/images';
import { MoonLoader } from 'react-spinners';
import './style.css';
import Utility from '../../lib/Utility';
import EventCenter from '../../lib/EventCenter';
import moment from 'moment';


class Login extends React.Component {

	// Set initial props
    static defaultProps = {
        allowDeviceTokenForAuthentication: false,
        allowBadgeNumberForAuthentication: false,
    }

    constructor(props) {
        super(props);

        this.mounted = false;

        // Use state-oriented styles here, static ones should go into the CSS
        this.style = {
            
        };

        this.deviceToken = '';
        this.timeout = null;

        if (this.props.appStore.settings.USEAUTHV2){
            this.signUpButton = true;
        } else {
            this.signUpButton = false;
        }
        if (this.props.appStore.settings.USESIGNUPCODE){
            this.useSignUpCode = true;
        } else {
            this.useSignUpCode = false;
        }


        // Set initial state
        this.state = {
            username: '',
            password: '',
            targetusername: '',
            email: '',
            errorMessage: '',
            successMessage: '',
            emailValid: false,
            deviceApproved: false,
            deviceToken: '',
            secondStagePasswordRequired: false,
            loginButtonCopy: this.props.allowDeviceTokenForAuthentication ? 'Continue' : 'Log In',
            loading: false,
            width: 0,
            height: 0,
            forgotPassword: false,
            showingPassword: false,
            showSignInCodeField: false,
            signUpCopy: 'Sign Up',
            signUpCode: null,
            signUpCodeValid: null,
            logInAsMode: false,
            loading: false
        };
    }

    componentDidMount() {
        this.mounted = true;
        window.addEventListener('resize', this.updateWindowDimensions);
        EventCenter.subscribe('onShowLogInAsModal', this.onShowLogInAsModal);
        this.init();
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', this.updateWindowDimensions);
        EventCenter.unsubscribe('onShowLogInAsModal', this.onShowLogInAsModal);

    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.needToLoginWithPassword && nextProps.needToLoginWithPassword !== this.props.needToLoginWithPassword){
            // a password change happened, let's log the user in
            this.setState({ password: nextProps.needToLoginWithPassword });
            //console.log('new pw: '+nextProps.needToLoginWithPassword);
            this.loginUser(null, nextProps.needToLoginWithPassword);
        }
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    // ---- Member Methods (Use arrow syntax to auto-bind) ----

    init = () => {
        // Get or generate new device token
        //this.deviceToken = this.props.localStorage.get('deviceToken');
        let { settings } = this.props.appStore;
        this.deviceToken = this.props.localStorage.getItem('deviceToken'+settings.ENV);
        if (this.deviceToken === undefined || this.deviceToken === ''){
            this.deviceToken = randombytes(32).toString('hex');
            //this.deviceToken = Math.floor(Math.random() * 1000000000000);
            //this.props.localStorage.set('deviceToken', this.deviceToken);
            this.props.localStorage.setItem('deviceToken'+settings.ENV, this.deviceToken);
        } 
        //console.log('devicetoken: '+JSON.stringify(this.deviceToken));
    }

    onShowLogInAsModal = (args) => {
        this.setState({ logInAsMode: true });

    }

    handleError = (error) => {
        console.log(error);
        this.setState({ errorMessage: error });

        if (this.state.width < 768){
            let that = this;
            if (error && error !== ''){
                this.timeout = setTimeout(function(){ that.handleError(''); }, 15000);
            } else {
                if (this.timeout){
                    clearTimeout(this.timeout);
                }
            }
        }
    }

    handleSuccess = (success) => {
        this.setState({ successMessage: success });

        if (this.state.width < 768){
            let that = this;
            if (success && success !== ''){
                this.timeout = setTimeout(function(){ that.handleSuccess(''); }, 15000);
            } else {
                if (this.timeout){
                    clearTimeout(this.timeout);
                }
            }
        }
    }

    loginUser = (event, newPassword = null) => {
        this.setState({ showSignInCodeField: false, signUpCopy: 'Sign Up', signUpCode: null, signUpCodeValud: null });
        
        event && event.preventDefault();
        //console.log('authenticating...');
        let pw = newPassword ? newPassword : this.state.password;
        let username = this.state.username !== '' ? this.state.username.toLowerCase() : user.email.toLowerCase();
        let { settings } = this.props.appStore;

        if (username === '' || pw === ''){
            return;
        }

        if (!this.props.allowDeviceTokenForAuthentication){
            Utility.info(this.props.webApplicationId)
            this.setState({ loading: true });
            API.authenticate(username, pw, this.props.webApplicationId, `${settings.API_HTTP_PROTOCOL}${settings.AUTH_DOMAIN}${settings.AUTH_BASE_PATH}`, (this.state.logInAsMode && this.state.targetusername !== '') ? this.state.targetusername : null )
            .then((response) => { if (!response.ok) { throw Error(response.statusText); } return response; })
            .then((response) => response.json())
            .then((responseJson) => {
                let error = responseJson.Error ?? responseJson.error;
                let result = responseJson.Result ?? responseJson.result ?? responseJson.Results ?? responseJson.results;
                let messages = responseJson.Messages ?? responseJson.messages;
                let expiration = responseJson.Expiration ?? responseJson.expiration;

                if (!error && !result) {
                    // the response is in the legacy format
                    result = responseJson;
                }
                if (!error && result.odaKey !== undefined){
                    user.email = result.email;
                    user.username = result.username;
                    user.changePassword = result.changePassword;
                    user.webApplicationID = result.webApplicationID;
                    user.role = result.role;
                    user.odaKey = result.odaKey;
                    user.expiration = Date.parse(result.expiration);
                    user.webApplications = result.webApplications;
                    user.availableApplications = [];
                    user.errorMessage = result.errorMessage;

                    user.profile = result.profile;

                    if (user.changePassword){
                        // *$* TODO: Force change password
                        user.errorMessage = 'Your password is out of date, please change your password and try logging in again to continue.';
                        this.setState({ logInAsMode: false });
                        this.props.userLoggedIn(user);
                        this.props.changePasswordCallback(true);
                        this.handleError(user.errorMessage);
                        } else {
                        // Move on
                        this.setState({ errorMessage: '', logInAsMode: false });
                        this.props.userLoggedIn(user);
                    }

                    //this.buttonClicked = false;
                    
                } else {
                    if (messages && messages.length){
                        user.errorMessage = messages[0].Description;
                    } else {
                        user.errorMessage = "The server returned an error.  Please check what you entered and try again.";
                    }
                    this.handleError(user.errorMessage);
                    
                    //this.buttonClicked = false;
                }
                if (this.mounted){
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                console.error(error);
                
                user.errorMessage = 'An error occurred: '+error;
                this.handleError(user.errorMessage);
                if (this.mounted){
                    this.setState({ loading: false });
                }
                //this.buttonClicked = false;
            });

        } else {

            this.setState({ secondStagePasswordRequired: true, loginButtonCopy: 'Log In' });

        }
    }

    

    onUsernameChange = (event) => {
        //console.log('event: '+event);
        this.setState({ username: event.target.value });
    }

    onTargetUsernameChange = (event) => {
        //console.log('event: '+event);
        this.setState({ targetusername: event.target.value });
    }

    onPasswordChange = (event) => {
        this.setState({ password: event.target.value });
    }

    onSignUpCodeChange = (event) => {
        let { settings } = this.props.appStore;

        this.setState({ signUpCode: event.target.value });
        let retries = 0;
        let value = event.target.value;
        if (value.length >= settings.REGISTRATION_CODE_LENGTH){


            this.checkCode(value, retries);

        }
    }

    checkCode = (value, retries) => {
        let { settings } = this.props.appStore;

        let params = { code: value, token: '' };

        return API.POST("RegisterUsers/Verify", params, settings.API_HTTP_PROTOCOL + settings.USERS_API_DOMAIN + settings.USERS_API_BASE_PATH)
                .then((response) => response.json())
                .then((responseJson) => {
                    responseJson = responseJson.result;

                    console.log('--- login.onSignUp');
                    
                    if (responseJson.error) {
                        //this.handleError(json.messages[0].description, 'danger');
                        this.setState({signUpCodeValid: 'error'});
                    } else {
                        //this.setState({ message: '', loading, webApplication: json.result.webApplication, company: json.result.company, token, code, askForCode: false, showPage: true, header: 'Please enter your information.' })
                        this.setState({signUpCodeValid: 'success'});

                    }
                })
                .catch((error) => {
                  console.error(error);
                  
                  if (retries++ < 2){
                    this.checkCode(value, retries);
                  }
                  //user.errorMessage = 'A network error occurred, please check your internet connectivity.';
                  //this.handleError(user.errorMessage);
                 
                });
    }

    onEmailChange = (event) => {
        this.setState({ email: event.target.value });
    }

    getUsernameValidationState = () => {
        //const length = this.state.username.length;

    }

    getPasswordValidationState = () => {
        //const length = this.state.password.length;

    }

    getEmailValidationState = () => {
        //const length = this.state.password.length;
        if (this.state.email && this.state.email !== ''){
            let valid = validateEmail(this.state.email.toLowerCase());
            if (valid !== this.state.emailValid){
                this.setState({emailValid: valid});
            }
            return valid ? 'success' : 'error';
        }
    }

    toHexString = (byteArray) => {
      return Array.from(byteArray, function(byte) {
        return ('0' + (byte & 0xFF).toString(16)).slice(-2);
      }).join('')
    }

    getLoginMessage = () => {
        if (!this.state.forgotPassword){
            if (this.props.allowDeviceTokenForAuthentication && !this.state.secondStagePasswordRequired){
                if (this.props.allowBadgeNumberForAuthentication){
                    return 'Please enter your username or badge number here.';
                } else {
                    return 'Please enter your username here.';
                }
            } else {
                if (this.props.allowBadgeNumberForAuthentication){
                    return 'Please enter your username or badge number and password here.';
                } else {
                    if (this.state.logInAsMode){
                        return 'Please enter the target username you wish to log in as, followed by your username and password.';
                    }
                    return 'Please enter your username and password here.';
                }
            }
        } else {
            return "Please enter the email address associated with your account, and we'll send you a link to reset your password.";
        }
    }

    forgotPasswordClicked = () => {
        this.props.resetStoredUser();
        if (validateEmail(this.state.username.toLowerCase())){
            this.setState({forgotPassword: true, email: this.state.username.toLowerCase(), emailValid: true});
        } else {
           this.setState({forgotPassword: true});
        }
    }

    getFilePath = () => {
        let file_path = '';
        if(this.props.appStore.settings.ENV === 'test' || this.props.appStore.settings.ENV === 'stage' || this.props.appStore.settings.ENV === 'prod'){
            file_path = this.props.appStore.settings.REFERENCE_GUIDES_FILE_PATH_PROD;
        }
        else{
            file_path = this.props.appStore.settings.REFERENCE_GUIDES_FILE_PATH_LOCAL;
        }
        return file_path;
    }


    loginHelpClicked = () => {
        let refer_guides = this.props.appStore.settings.REFERENCE_GUIDES;
        let  file_path = this.getFilePath();
        for (let i = 0; i < refer_guides.length; i++) {
            if(refer_guides[i].category == "Login"){
                window.open(file_path+refer_guides[i].file_name, "_blank");
            }
        }
    }

    isLoginQRGAvailable = () => {
        let refer_guides = this.props.appStore.settings.REFERENCE_GUIDES;
        let  file_path = this.getFilePath();
        for (let i = 0; i < refer_guides.length; i++) {
            if(refer_guides[i].category == "Login"){
                return true;
            }
        }
        return false;
    }

    showPasswordClicked = () => {
        this.setState({ showingPassword: !this.state.showingPassword });
    }

    submitForgotPassword = () => {
        let { settings } = this.props.appStore;

        let params = { email: this.state.email, webApplicationId: this.props.webApplicationId };
        this.setState({ loading: true });

        API.POST("Forgot", params, `${settings.API_HTTP_PROTOCOL}${settings.AUTH_DOMAIN}${settings.AUTH_BASE_PATH}`)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson !== undefined && responseJson.Error !== true && responseJson.error !== true){
            if (responseJson.Messages && responseJson.Messages.length){
                this.handleSuccess(responseJson.Messages[0].Description);
            } else if (responseJson.messages && responseJson.messages.length){
                this.handleSuccess(responseJson.messages[0].description);
            } else {
                this.handleSuccess('Your password has been emailed to you.  Please check your email.  It may take a few seconds to arrive.');
            }
            this.setState({forgotPassword: false, password: ''});

          } else {
            if (responseJson.Messages && responseJson.Messages.length){
                user.errorMessage = responseJson.Messages[0].Description;
            } else if (responseJson.messages && responseJson.messages.length){
                user.errorMessage = responseJson.messages[0].description;
            } else {
                user.errorMessage = 'We\'re sorry, An error occurred, please try again later.';
            }
            this.handleError(user.errorMessage);
            
          }
          
          if (this.mounted){
            this.setState({ loading: false });
          }

        })
        .catch((error) => {
          console.error(error);
          
          user.errorMessage = 'A network error occurred, please check your internet connectivity.';
          this.handleError(user.errorMessage);
          if (this.mounted){
            this.setState({ loading: false });
          }
          //this.buttonClicked = false;
        });
        
    }

    signUp = () => {
        let { settings } = this.props.appStore;
        //Utility.log('--- Login.signUp');
        if (this.useSignUpCode){
            if (this.state.showSignInCodeField){
                if (this.state.signUpCode){
                    window.location = `${settings.API_HTTP_PROTOCOL}${settings.REGISTRATION_SITE_BASEPATH}${settings.REGISTRATION_SITE_PATH}?c=${this.state.signUpCode.trim()}&e=${this.state.username}&t=`;
                }
            } else {
                let state = { showSignInCodeField: true, signUpCopy: 'Continue', signUpCode: null, signUpCodeValid: null };

                this.setState(state);
                //if (this.codeFieldRef) this.codeFieldRef.focus();
            }
        } else {
            window.location = `${settings.API_HTTP_PROTOCOL}${settings.REGISTRATION_SITE_BASEPATH}${settings.REGISTRATION_SITE_PATH}`;
        }
    }



    // ---- Render Methods ----

    renderSignUpFields = () => {
        if (!this.signUpButton) return null;

         return (<div className="signup-container"><div className="pull-left" style={{ width: '250px' }} ><Form>
                    <Button bsStyle="success" bsSize="large" className="pull-left" style={{ zIndex: 100 }} onClick={this.signUp} disabled={!(!this.state.showSignInCodeField || !this.useSignUpCode || (this.useSignUpCode && this.state.signUpCodeValid === 'success'))}>{this.state.signUpCopy}</Button>
                    
                    {this.state.showSignInCodeField &&
                        <div className='signup-code-field' style={{ }}>
                            <FormGroup controlId='signup-code-field' validationState={this.state.signUpCodeValid}>
                                <FormControl
                                    ref={(ref) => this.codeFieldRef = ref}
                                    type='text'
                                    value={this.state.signUpCode ? this.state.signUpCode : ''}
                                    placeholder={`Enter Code`}
                                    onChange={this.onSignUpCodeChange}
                                    autoFocus
                                    onKeyPress={event => {
                                                        if (event.key === "Enter"){
                                                            event.preventDefault();
                                                            this.signUp();
                                                        }
                                                    }}
                                                    
                                />
                                <FormControl.Feedback />
                            </FormGroup>
                        </div>
                    } 
                        {/* <SignUpRequiredFormElement name='signup-code-field' label='Code' value={this.state.signUpCode ? this.state.signUpCode : ''} onChange={this.onSignUpCodeChange} validator={this.state.signUpCodeValid} /></div> */}
            </Form>
            { this.state.showSignInCodeField && this.props.appStore.settings.SIGNUPCODEMESSAGE && <div className='signup-help' dangerouslySetInnerHTML={ { __html: this.props.appStore.settings.SIGNUPCODEMESSAGE } } />}
        </div></div>);
    }

    createMotd = () => {
        /*
        if (typeof(this.props.appStore.settings.SHOWMOTD) === 'object'){
            let start = moment(this.props.appStore.settings.SHOWMOTD.start);
            let end = moment(this.props.appStore.settings.SHOWMOTD.end);
            if (moment().isBetween(start,end, 'days', '[]')){
                */
                return {__html: this.props.appStore.settings.SHOWMOTD.msg};
            /*} else {
                return null;
            }

        } else {
            return {__html: this.props.appStore.settings.SHOWMOTD};

        } */
    }

    render() {
        const style = {...this.style, ...this.props.innerStyle};
        let { settings } = this.props.appStore;
    	return (
    		<div ref={(ref) => this.myRef = ref} className='Login' style={style}>
                <Modal show={!this.props.isAuthenticated || this.state.logInAsMode} onHide={() => { this.setState({ logInAsMode: false }); }} autoFocus={true} backdrop={'static'} dialogClassName={'modal-login'} enforceFocus={true}>
                  <Modal.Header closeButton={this.state.logInAsMode}>
                    {/* <Modal.Title>Login</Modal.Title> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div><img src={images.imageList[settings.APP_LOGO_LOGIN] ? images.imageList[settings.APP_LOGO_LOGIN] : settings.APP_LOGO_LOGIN} style={{ maxWidth: '400px', height: 'auto', padding: '25px 25px 25px 12px'}}  alt={''} /></div>
                        <div className="login-logo-right"><img src={this.state.logInAsMode ? images.login_su : (this.state.selectedPlant && this.state.selectedPlant.logo && !settings.LOGIN_LOGO_OVERRIDE) ? this.state.selectedPlant.logo : (images.imageList[settings.CLIENT_LOGO_LOGIN] ? images.imageList[settings.CLIENT_LOGO_LOGIN] : settings.CLIENT_LOGO_LOGIN)} style={{ maxWidth: '220px', height: 'auto', padding: '25px 30px', marginLeft: '-30px' }} alt={''} /></div>
                        {this.props.env !== 'prod' ? <span style={{ color: '#FF0000', position: 'absolute', left: '320px', top: '60px', fontSize: '16px' }}>{this.props.env}</span> : null }

                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ padding: '16px' }}>
                    <h4>{this.state.logInAsMode ? 'Hello Superuser!' : 'Welcome to the '+ (settings.APPLICATION_NAME ? settings.APPLICATION_NAME : '') +' login screen.'}</h4>
                    <p>{this.getLoginMessage()}</p>
                    {this.props.appStore.settings.SHOWMOTD ? <p className='login-motd' dangerouslySetInnerHTML={this.createMotd()} /> : null}

                    <br />

                    <form>

                        { this.state.logInAsMode && <FormGroup
                            controlId="form-login-targetusername"
                            validationState={this.getUsernameValidationState()}
                            >
                            <ControlLabel>Target Username</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.targetusername}
                                placeholder={"Enter username you wish to log in as."}
                                onChange={this.onTargetUsernameChange}
                                autoFocus
                                />
                        </FormGroup>}

                        { !this.state.forgotPassword && <FormGroup
                            controlId="form-login-username"
                            validationState={this.getUsernameValidationState()}
                            >
                            <ControlLabel>{this.state.logInAsMode ? 'Your ' : ''}Username{ this.props.allowBadgeNumberForAuthentication ? ' or Badge Number' : null }</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.username}
                                placeholder={"Enter username"}
                                onChange={this.onUsernameChange}
                                autoFocus
                                />
                        </FormGroup>}

                        { !this.state.forgotPassword && !this.state.showSignInCodeField && (!this.props.allowDeviceTokenForAuthentication || this.state.secondStagePasswordRequired) ?
                            <FormGroup
                                controlId="form-login-password"
                                validationState={this.getPasswordValidationState()}
                                >
                                <ControlLabel>Password</ControlLabel>
                                <FormControl
                                    type={this.state.showingPassword ? "text" : "password"}
                                    value={this.state.password}
                                    placeholder={"Enter password"}
                                    onChange={this.onPasswordChange}
                                    onKeyPress={event => {
                                        if (event.key === "Enter"){
                                            this.loginUser(event);
                                        }
                                    }}
                                    />
                            <a style={{ fontSize: '11px', float: 'left' }} onClick={this.forgotPasswordClicked} href="#">Forgot Password</a>
                            <a style={{ fontSize: '11px', color: 'red', float: 'left', paddingLeft: '140px'}} onClick={this.loginHelpClicked} href="#">{this.isLoginQRGAvailable() ? 'Login Help' : ''}</a>
                            <a style={{ fontSize: '11px', float: 'right', paddingRight: '36px' }} onClick={this.showPasswordClicked} href="#">{this.state.showingPassword ? 'Hide' : 'Show'} Password</a>

                        </FormGroup>
                         : null }

                         { this.state.forgotPassword && <FormGroup
                            controlId="form-login-username"
                            validationState={this.getEmailValidationState()}
                            >
                            <ControlLabel>Email Address</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.email}
                                placeholder={"Enter email"}
                                onChange={this.onEmailChange}
                                autoFocus
                                />
                            </FormGroup>}

                    </form>

                    { (this.state.errorMessage !== '') ?
                        <Alert bsStyle="danger">
                            {this.state.errorMessage}<br />
                            {(this.state.errorMessage.indexOf('Invalid Logon, please double check your username and password.') === -1) && (this.state.errorMessage.indexOf('Your password is out of date, please change your password and try logging in again to continue.') === -1) && <span></span>}
                        </Alert> : null
                    }

                    { (this.state.successMessage !== '') ?
                        <Alert bsStyle="success">
                            {this.state.successMessage}
                        </Alert> : null
                    }
                    </div>
                   </Modal.Body>
                  <Modal.Footer>
                    {this.renderSignUpFields()}

                    { (!this.state.forgotPassword && !this.state.showSignInCodeField) && <Button bsStyle="primary" bsSize="large" style={ this.state.loading ? { opacity: 0.8, filter: 'brightness(60%)' } : {}} onClick={!this.state.loading ? this.loginUser : null}>{this.state.loginButtonCopy}</Button>}
                    {/* !this.state.forgotPassword && (this.state.username === '' || this.state.password === '') && <Button bsStyle="primary" bsSize="large" onClick={this.loginUser} disabled>{this.state.loginButtonCopy}</Button> */}
                    { (this.state.forgotPassword || this.state.showSignInCodeField) && <Button bsSize="large" onClick={() => this.setState({ forgotPassword: false, showSignInCodeField: false })}>Cancel</Button>}
                    { this.state.forgotPassword && !this.state.emailValid && <Button bsStyle="primary" bsSize="large" disabled onClick={this.submitForgotPassword}>Send Email</Button>}
                    { this.state.forgotPassword && this.state.emailValid && <Button bsStyle="primary" bsSize="large" onClick={this.submitForgotPassword}>Send Email</Button>}
                  </Modal.Footer>
                </Modal>

    		</div>
    	);
    }
}

export default Login;


const SignUpRequiredFormElement = (props) => {
                
    return <FormGroup controlId={props.name} validationState={props.validator}>
            <FormControl
                ref={(ref) => this.codeFieldRef = ref}
                type={props.type}
                value={props.value ? props.value : ''}
                placeholder={`Enter ${props.label}`}
                onChange={props.onChange}
                autoFocus
                onKeyPress={event => {
                                    if (event.key === "Enter"){
                                        event.preventDefault();
                                        this.signUp(event);
                                    }
                                }}
            />
            <FormControl.Feedback />
    </FormGroup>;
}

