
import React from 'react';
import { Glyphicon, Modal, Button, FormGroup, ControlLabel, FormControl, Alert } from 'react-bootstrap';
import API from '../../lib/API';
import { user } from '../../config/data'
import './style.css';
import images from '../../config/images';
import { Switch, Route, withRouter, BrowserRouter, Link  } from 'react-router-dom';
import ReactPlayer from 'react-player';


class QRG extends React.Component {

	// Set initial props
    static defaultProps = {
        
    }

    constructor(props) {
        super(props);

      
        this.mounted = false;

        // Use state-oriented styles here, static ones should go into the CSS
        this.style = {
            
        };

        // Set initial state
        this.state = {
            errorMessage: '',
            loading: false,
            sendToVideo: false,
            sendToPdf: false,
            urlToOpen: ''
        };
    }

    componentDidMount() {
        this.mounted = true;
        
        this.init();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillUpdate(nextProps, nextState) {
       
        
    }

    // ---- Member Methods (Use arrow syntax to auto-bind) ----

    init = () => {
        
    }

    close = (event) => {
        this.setState({ errorMessage: '' });
        this.props.closeHelp();
    }

    fileToOpen = () => {

    }

    handleError = (error) => {
        console.log(error);
        this.setState({ errorMessage: error });
    }

    // handleVideoPage = (url) => {
    //     this.setState({ sendToVideo: true });
    //     this.setState({ urlToOpen: url} );
    //  }
    
    // handleCloseVideo = () => {
    //     this.setState({ sendToVideo: false });
    // }

    // handlePdfOpen = (url) => {
    //     this.setState({ sendToPdf: true });
    //     console.log('urlToOpen'+url);
    //     this.setState({ urlToOpen: url});
    //  }

    // handleClosePdf = () => {
    //     this.setState({ sendToPdf: false });
    // }

    getFilePath = () => {
        let file_path = '';
        if(this.props.appStore.settings.ENV === 'test' || this.props.appStore.settings.ENV === 'stage' || this.props.appStore.settings.ENV === 'prod'){
            file_path = this.props.appStore.settings.REFERENCE_GUIDES_FILE_PATH_PROD;
        }
        else{
            file_path = this.props.appStore.settings.REFERENCE_GUIDES_FILE_PATH_LOCAL;
        }
        return file_path;
    }

    createLinkTable = () => {
        let refer_guides = this.props.appStore.settings.REFERENCE_GUIDES;
        let table = [];
        let file_path = this.getFilePath();
        let firstFlag = false;
        for (let i = 0; i < refer_guides.length; i++) {
            //hiding Login category and autoplay videos
            if(refer_guides[i].category != "Login" && !refer_guides[i].autoPlayVideo){
                if(firstFlag)
                {
                    if(refer_guides[i-1].category != refer_guides[i].category){
                        table.push(<br></br>);
                        table.push(<tr>{refer_guides[i].category}</tr>);
                        table.push(<tr><a href={file_path+refer_guides[i].file_name} target='_blank' className="btnStack">{refer_guides[i].display_name}</a><br></br></tr>);
                    }
                    else{
                        table.push(<tr><a href={file_path+refer_guides[i].file_name} target='_blank' className="btnStack">{refer_guides[i].display_name}</a><br></br></tr>);
                    }
                }
                else{
                    table.push(<tr>{refer_guides[i].category}</tr>);
                    table.push(<tr><a href={file_path+refer_guides[i].file_name} target='_blank' className="btnStack">{refer_guides[i].display_name}</a><br></br></tr>);
                    firstFlag = true;
                }
            }
        }
        return table
      }

    getAutoPlayVideo = () =>{
        let refer_guides = this.props.appStore.settings.REFERENCE_GUIDES;
        let table = [];
        let file_path = this.getFilePath();

        for (let i = 0; i < refer_guides.length; i++) {
            if(refer_guides[i].file_type == "video" && refer_guides[i].autoPlayVideo){
                table.push(<tr><a><ReactPlayer url ={file_path+refer_guides[i].file_name} controls width='600px'/></a></tr>);
            }
        }
        return table
    }

    isVideoAvailable = () => {
        let refer_guides = this.props.appStore.settings.REFERENCE_GUIDES;
        let file_path = this.getFilePath();

        for (let i = 0; i < refer_guides.length; i++) {
            if(refer_guides[i].file_type == "video" && refer_guides[i].autoPlayVideo){
                return true;
            }
        }
        return false;
    }

    render() {
        const style = {...this.style, ...this.props.innerStyle};
    	return (
    		<div ref={(ref) => this.myRef = ref} className='QRG' style={style}>
                {this.isVideoAvailable() ? 
                <Modal
                    size="xl"
                    show={this.props.showQRG} 
                    onHide={this.props.closeHelp} 
                    autoFocus={true} 
                    dialogClassName={'modal-90w'} 
                    enforceFocus={true}
                    className='content'
                    appStore={this.props.appStore}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className='title'>Quick Reference Guides
                    </Modal.Title>
                    
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                        <div className = 'links'>
                            <div>
                                {this.createLinkTable()}
                            </div>
                            <div className = 'video'>
                                {this.getAutoPlayVideo()}
                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
                    :
                    <Modal
                    size="sm"
                    show={this.props.showQRG} 
                    onHide={this.props.closeHelp} 
                    autoFocus={true} 
                    dialogClassName={'modal-30w'} 
                    enforceFocus={true}
                    className='content'
                    appStore={this.props.appStore}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className='title'>Quick Reference Guides
                    </Modal.Title>
                    
                  </Modal.Header>
                  <Modal.Body>
                    <div className = "justLinks">
                        <div>
                            {this.createLinkTable()}
                        </div>
                    </div>
                    

                  </Modal.Body>
                </Modal>
            }
       		</div>
    	);
    }
}

export default QRG;
