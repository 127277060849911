import Utility from "../lib/Utility";
import images from './images';
import DashItem_EMS from '../dashboard/components/DashItem_EMS';

class SettingsLoader {

  GETCONFIG() {
    console.log('Fetching config');
    return fetch('./config.json?r='+Math.floor(Math.random() * 1000000), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
  load() {
    console.log('Loading config');
    return this.GETCONFIG()
      .then((response) => response.json())
      .then((json) => {
        if (json !== undefined) {
          console.log(json);
          let settings = this.setConfig(json);
          return settings;
        }

      }).catch(error => console.error(error));
  }
//This is what you would put in a .env file
  // REACT_APP_OVERRIDE=true
  // REACT_APP_ENV=dev
  // REACT_APP_DEBUG=true
  // REACT_APP_WEB_APPLICATION_ID=99
  // REACT_APP_WEB_APPLICATION_ID_INDEX=0
  // REACT_APP_API_HTTP_PROTOCOL=
  // REACT_APP_API_DOMAIN=dev.odadevel.com/
  // REACT_APP_API_BASE_PATH=trucks/oda.favorites/
  // REACT_APP_AUTH_DOMAIN=dev.odadevel.com/
  // REACT_APP_AUTH_BASE_PATH=trucks/oda.favorites/
  // REACT_APP_TOKEN=trucks.oda.token
  // REACT_APP_CLIENT_LOGO_NAV=suncor_logo_med
  // REACT_APP_CLIENT_LOGO_LOGIN=sift_logo_small
  setConfig(config) {
    let env = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV : config.ENV;
    if (env){
      env = env.toLowerCase();
    }

    /*
    let API_HTTP_PROTOCOL = 'https://';
    let API_WS_PROTOCOL = 'wss://';
    let API_DOMAIN = '';              // Base domain the API is on
    let API_BASE_PATH = '';           // Base path (after the domain) the site is on
    let AUTH_DOMAIN = '';             // Base AUTH API domain
    let AUTH_BASE_PATH = 'oda.api/';  // Base AUTH API path (after the domain)
    let WEB_APPLICATION_ID = 0;
    let WEB_APPLICATION_ID_INDEX = 0; // Which index in the array of IDs listed in WEB_APPLICATIONS below we should pay attention to (dev/test/prod)
    let TOKEN = 'dev.emsfthills.token';      // The name of whatever cookie you want the site to use
    let SITE_USES_SUBWEBAPPLICATIONS = true;   // If TRUE, the website will ping the /AuthorizeV2 endpoint and save its webApplications to the cookie; this will affect any other sites sharing this cookie that uses the webApplications returned by the result.

    if (env === 'dev'){
      API_DOMAIN = AUTH_DOMAIN = 'dev.odadevel.com/';
      API_BASE_PATH = 'detroit/';
      AUTH_BASE_PATH = 'detroit/oda.api/';
      WEB_APPLICATION_ID = 111;
      WEB_APPLICATION_ID_INDEX = 0;
      TOKEN = 'trucks.emsfthills.token';

    } else if (env === 'davedev'){
      // add '"proxy": "https://dev.odadevel.com/trucks/",' to package.json
      
      API_DOMAIN = 'dev.odadevel.com';
      AUTH_DOMAIN = 'columbia.hq.ondemandanalysis.com/';
      API_BASE_PATH = 'detroit/';
      AUTH_BASE_PATH = 'oda.api/';
      WEB_APPLICATION_ID = 111;
      WEB_APPLICATION_ID_INDEX = 0;
      TOKEN = 'trucks.emsfthills.token';

    } else if (env === 'mazama'){
      API_DOMAIN = 'www.odadevel.com/';
      AUTH_DOMAIN = 'www.odadevel.com/';
      WEB_APPLICATION_ID = 1195;
      WEB_APPLICATION_ID_INDEX = 1;
      TOKEN = 'test.emsfthills.token';

    } else if (env === 'test'){
      API_DOMAIN = 'apitest.ondemandanalysis.com/';
      AUTH_DOMAIN = 'apitest.ondemandanalysis.com/';
      AUTH_BASE_PATH = '';
      WEB_APPLICATION_ID = 1195;
      WEB_APPLICATION_ID_INDEX = 1;
      TOKEN = 'test.emsfthills.token';

    } else if (env === 'production' || env === 'prod'){
      API_DOMAIN = 'api.ondemandanalysis.com/';
      AUTH_DOMAIN = 'api.ondemandanalysis.com/';
      AUTH_BASE_PATH = '';
      WEB_APPLICATION_ID = 175;
      WEB_APPLICATION_ID_INDEX = 2;
      TOKEN = 'prod.emsfthills.token'; 
    } 
    */
    //Finalize all the environmental variables. REACT_APP will OVERRIDE any settings. Those can be passed in through cmd line or .env files.

    /*
    let EXCLUDED_APPLICATIONS = [
        {
          id: [0, 106, 1191, 160, 111, 1195, 175],
          name: 'SiteServices Auth',
        },
      ];

      
    let WEB_APPLICATIONS = [
            { 
              id: [101, 1186, 156],      // 111 - dev, 1186 - test, 156 - prod
              config: {
                      id: 1186,    
                      name: 'Rentals',
                      link: 'newPage1',
                      icon: images.icon_siterentals,
                      //glyphicon: 'glyphicons-truck',
                      color: '#a4bad4',
                      showBackground: true,
                      expandable: false,
                      expandedComponent: DashItem_EMS,
                      expandedComponentName: 'DashItem_EMS',
                      blockToken: false,

              },
            },
            { 
              id: [114, 1200, 177],    // was: 108 - dev, 1192 - test, 173 - prod
              config: {
                      id: 1200,
                      name: 'Asset Forecasting',
                      link: 'newPage2',
                      icon: images.icon_siteforecasting,
                      //glyphicon: 'glyphicons-cloud-upload',
                      color: '#d96773',
                      showBackground: true,
                      expandable: false,
                      blockToken: false,

              },
            },
            { 
              id: [116, 1207, 179],   // 116 - dev, 1207 - test, 179 - prod
              config: {
                      id: 1207,
                      name: 'Asset Tracking',
                      link: 'newPage4',
                      icon: images.icon_assettracking,
                      //glyphicon: 'glyphicons-cloud-upload',
                      color: '#f2ca46',
                      showBackground: true,
                      expandable: false,
                      blockToken: true,
                      isOwnSubApplication: false,
              },
            },
            { 
              id: [113, 1199, 176],   //  SUB WEB APPLICATION - Child of: asset management - fuel cards: 113 - dev, 1199 - test, 176 - prod
              config: {
                      id: 1199,
                      name: 'Fuel Cards',
                      link: 'newPage6',
                      icon: images.glyphicon_gas_station,
                      icon_small: images.icon_gas_full,
                      //glyphicon: 'glyphicons-cloud-upload',
                      color: '#f2ca46',
                      showBackground: true,
                      expandable: false,
                      blockToken: true,

              },
            },
            { 
              id: [117, 1208, 180],   //  SUB WEB APPLICATION - Child of: asset management - radios: 117 - dev, 1208 - test, 180 - prod
              config: {
                      id: 1208,
                      name: 'Radios',
                      link: 'newPage8',
                      icon: images.icon_radio,
                      icon_small: images.icon_radio_full,
                      //glyphicon: 'glyphicons-cloud-upload',
                      color: '#f2ca46',
                      showBackground: true,
                      expandable: false,
                      blockToken: true,

              },
            },
            { 
              id: [118, 1209, 182],   //  SUB WEB APPLICATION - Child of: asset management - radios: 118 - dev, 1209 - test, 182 - prod
              config: {
                      id: 1208,
                      name: 'Propane Cylinders',
                      link: 'newPage7',
                      icon: images.icon_propane,
                      icon_small: images.icon_propane_full,
                      //glyphicon: 'glyphicons-cloud-upload',
                      color: '#f2ca46',
                      showBackground: true,
                      expandable: false,
                      blockToken: true,

              },
            },
            { 
              id: [112, 1196, 174],    // 112 - dev, 1196 - test, 174 - prod
              config: {
                      id: 1196,
                      name: 'Uploads',
                      link: 'newPage5',
                      icon: images.icon_upload,
                      glyphicon: 'glyphicons-cloud-upload',
                      color: '#a1d68d',
                      showBackground: true,
                      expandable: false,
                      blockToken: true,
              },
            },
            { 
              id: [115, 1201, 181],    // 115 - dev, 1201 - test, 181 - prod
              config: {
                      id: 1201,
                      name: 'LEMS',
                      link: 'newPage3',
                      icon: images.icon_lems,
                      //glyphicon: 'glyphicons-cloud-upload',
                      color: '#bbbbbb',       //#d183cf
                      showBackground: true,
                      expandable: false,
                      blockToken: false,
                }
            },
            { 
              id: [0, 1213, 186],    // ? - dev, 1213 - test, 186 - prod
              config: {
                      id: 1213,
                      name: 'Maintenance Tables',
                      link: 'newPage10',
                      icon: images.icon_maintenancetables,
                      //icon_small: images.icon_maintenancetables_full,
                      //glyphicon: 'glyphicons-cloud-upload',
                      color: '#d99a67',
                      showBackground: true,
                      expandable: false,
                      blockToken: false,
                }
            },  
            { 
              id: [0, 1184, 184],    // ? - dev, 1184 - test, 184 - prod
              config: {
                      id: 1184,
                      name: 'Administration',
                      link: 'newPage9',
                      icon: images.icon_admin,
                      icon_small: images.icon_admin_full,
                      //glyphicon: 'glyphicons-cloud-upload',
                      color: '#b1a4d4',
                      showBackground: true,
                      expandable: false,
                      blockToken: false,
                      isOwnSubApplication: true,
                }
            },
            { 
              id: [0, 1211, 185],    // ? - dev, 1211 - test, 185 - prod
              config: {
                      id: 1211,
                      name: 'Users',
                      link: 'newPage9.1',
                      icon: images.users,
                      icon_small: images.icon_users_full,
                      //glyphicon: 'glyphicons-cloud-upload',
                      color: '#b1a4d4',
                      showBackground: true,
                      expandable: false,
                      blockToken: false,
                }
            },
            { 
              id: [0, 1212, 178],    // ? - dev, 1212 - test, 178 - prod
              config: {
                      id: 1212,
                      name: 'Favorites',
                      link: 'newPage9.2',
                      icon: images.icon_favorites,
                      icon_small: images.icon_favorites_full,
                      //glyphicon: 'glyphicons-cloud-upload',
                      color: '#b1a4d4',
                      showBackground: true,
                      expandable: false,
                      blockToken: false,
                }
            },  
          ];
      */

      /*
    Utility.log('process.env.REACT_APP_OVERRIDE');
    Utility.log(process.env.REACT_APP_OVERRIDE);

    config.ENV = env;
    config.WEB_APPLICATION_ID = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_WEB_APPLICATION_ID) ? process.env.REACT_APP_WEB_APPLICATION_ID : WEB_APPLICATION_ID;
    config.WEB_APPLICATION_ID_INDEX = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_WEB_APPLICATION_ID_INDEX) ? process.env.REACT_APP_WEB_APPLICATION_ID_INDEX : WEB_APPLICATION_ID_INDEX;
    config.API_HTTP_PROTOCOL = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_API_HTTP_PROTOCOL) ? process.env.REACT_APP_API_HTTP_PROTOCOL : API_HTTP_PROTOCOL;
    config.API_WS_PROTOCOL = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_API_WS_PROTOCOLL) ? process.env.REACT_APP_API_WS_PROTOCOL : API_WS_PROTOCOL;
    config.API_DOMAIN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_API_DOMAIN) ? process.env.REACT_APP_API_DOMAIN : API_DOMAIN;
    config.AUTH_DOMAIN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_AUTH_DOMAIN) ? process.env.REACT_APP_AUTH_DOMAIN : AUTH_DOMAIN;
    config.API_BASE_PATH = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_API_BASE_PATH) ? process.env.REACT_APP_API_BASE_PATH : API_BASE_PATH;
    config.AUTH_BASE_PATH = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_AUTH_BASE_PATH) ? process.env.REACT_APP_AUTH_BASE_PATH : AUTH_BASE_PATH;
    config.TOKEN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_TOKEN) ? process.env.REACT_APP_TOKEN : TOKEN;
    config.DEBUG = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_DEBUG) ? process.env.REACT_APP_DEBUG : config.DEBUG;
    config.CLIENT_LOGO_NAV = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_CLIENT_LOGO_NAV) ? process.env.REACT_APP_CLIENT_LOGO_NAV : config.CLIENT_LOGO_NAV;
    config.CLIENT_LOGO_LOGIN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_CLIENT_LOGO_LOGIN) ? process.env.REACT_APP_CLIENT_LOGO_NAV : config.CLIENT_LOGO_NAV;
    config.WEB_APPLICATIONS = process.env.WEB_APPLICATIONS && !(!process.env.WEB_APPLICATIONS) ? process.env.WEB_APPLICATIONS : config.WEB_APPLICATIONS;
    config.EXCLUDED_APPLICATIONS = process.env.EXCLUDED_APPLICATIONS && !(!process.env.EXCLUDED_APPLICATIONS) ? process.env.EXCLUDED_APPLICATIONS : config.EXCLUDED_APPLICATIONS;
    config.SITE_USES_SUBWEBAPPLICATIONS = SITE_USES_SUBWEBAPPLICATIONS;
  */

    Utility.log('process.env.REACT_APP_OVERRIDE');
    Utility.log(process.env.REACT_APP_OVERRIDE);

    config.ENV = env;
    config.SITE = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_SITE) ? process.env.REACT_APP_SITE : config.SITE.toUpperCase();
    config.SITES = process.env.SITES && !(!process.env.SITES) ? process.env.SITES : config.SITES;
    config.APPLICATION_NAME = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APPLICATION_NAME) ? process.env.REACT_APPLICATION_NAME : config.APPLICATION_NAME;

    config.WEB_APPLICATION_ID = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_WEB_APPLICATION_ID) ? process.env.REACT_APP_WEB_APPLICATION_ID : (config.WEB_APPLICATION_ID ? config.WEB_APPLICATION_ID : ((env === 'dev' || env === 'local') ? config.WEB_APPLICATION_ID_DEV : env === 'test' ? config.WEB_APPLICATION_ID_TEST : env === 'prod' || env === 'stage' ? config.WEB_APPLICATION_ID_PROD : 0));
    config.WEB_APPLICATION_ID_INDEX = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_WEB_APPLICATION_ID_INDEX) ? process.env.REACT_APP_WEB_APPLICATION_ID_INDEX : (env === 'dev' || env === 'local' ? 0 : env === 'test' ? 1 : env === 'prod' || env === 'stage' ? 2 : 1);

    config.API_HTTP_PROTOCOL = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_API_HTTP_PROTOCOL) ? process.env.REACT_APP_API_HTTP_PROTOCOL : config.API_HTTP_PROTOCOL;
    config.API_WS_PROTOCOL = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_API_WS_PROTOCOL) ? process.env.REACT_APP_API_WS_PROTOCOL : config.API_WS_PROTOCOL;

    config.API_DOMAIN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_API_DOMAIN) ? process.env.REACT_APP_API_DOMAIN : (env === 'local' ? config.API_DOMAIN_LOCAL : env === 'dev' ? config.API_DOMAIN_DEV : env === 'test' ? config.API_DOMAIN_TEST : env === 'stage' ? config.API_DOMAIN_STAGE : env === 'prod' ? config.API_DOMAIN_PROD : '');
    config.AUTH_DOMAIN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_AUTH_DOMAIN) ? process.env.REACT_APP_AUTH_DOMAIN : (env === 'local' ? config.AUTH_DOMAIN_LOCAL : env === 'dev' ? config.AUTH_DOMAIN_DEV : env === 'test' ? config.AUTH_DOMAIN_TEST : env === 'stage' ? config.AUTH_DOMAIN_STAGE : env === 'prod' ? config.AUTH_DOMAIN_PROD : '');

    config.API_BASE_PATH = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_API_BASE_PATH) ? process.env.REACT_APP_API_BASE_PATH : (env === 'local' ? config.API_BASEPATH_LOCAL : env === 'dev' ? config.API_BASEPATH_DEV : env === 'test' ? config.API_BASEPATH_TEST : env === 'stage' ? config.API_BASEPATH_STAGE : env === 'prod' ? config.API_BASEPATH_PROD : '');
    config.AUTH_BASE_PATH = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_AUTH_BASE_PATH) ? process.env.REACT_APP_AUTH_BASE_PATH : (env === 'local' ? config.AUTH_BASEPATH_LOCAL : env === 'dev' ? config.AUTH_BASEPATH_DEV : env === 'test' ? config.AUTH_BASEPATH_TEST : env === 'stage' ? config.AUTH_BASEPATH_STAGE : env === 'prod' ? config.AUTH_BASEPATH_PROD : '');

    config.ATTRIBUTES_API_DOMAIN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_ATTRIBUTES_API_DOMAIN) ? process.env.REACT_APP_ATTRIBUTES_API_DOMAIN : (env === 'local' ? config.ATTRIBUTES_API_DOMAIN_LOCAL : env === 'dev' ? config.ATTRIBUTES_API_DOMAIN_DEV : env === 'test' ? config.ATTRIBUTES_API_DOMAIN_TEST : env === 'stage' ? config.ATTRIBUTES_API_DOMAIN_STAGE : env === 'prod' ? config.ATTRIBUTES_API_DOMAIN_PROD : '');
    config.ATTRIBUTES_API_BASE_PATH = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_ATTRIBUTES_API_BASE_PATH) ? process.env.REACT_APP_ATTRIBUTES_API_BASE_PATH : (env === 'local' ? config.ATTRIBUTES_API_BASEPATH_LOCAL : env === 'dev' ? config.ATTRIBUTES_API_BASEPATH_DEV : env === 'test' ? config.ATTRIBUTES_API_BASEPATH_TEST : env === 'stage' ? config.ATTRIBUTES_API_BASEPATH_STAGE : env === 'prod' ? config.ATTRIBUTES_API_BASEPATH_PROD : '');

    config.REFERENCE_GUIDES = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_REFERENCE_GUIDES) ? process.env.REACT_REFERENCE_GUIDES : config.REFERENCE_GUIDES;
    config.REFERENCE_GUIDES_FILE_PATH_PROD = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_REFERENCE_GUIDES_FILE_PATH_PROD) ? process.env.REACT_REFERENCE_GUIDES_FILE_PATH_PROD : config.REFERENCE_GUIDES_FILE_PATH_PROD;
    config.REFERENCE_GUIDES_FILE_PATH_LOCAL = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_REFERENCE_GUIDES_FILE_PATH_LOCAL) ? process.env.REACT_REFERENCE_GUIDES_FILE_PATH_LOCAL : config.REFERENCE_GUIDES_FILE_PATH_LOCAL;
    
    config.USERS_API_DOMAIN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_USERS_API_DOMAIN) ? process.env.REACT_APP_USERS_API_DOMAIN : (env === 'local' ? config.USERS_API_DOMAIN_LOCAL : env === 'dev' ? config.USERS_API_DOMAIN_DEV : env === 'test' ? config.USERS_API_DOMAIN_TEST : env === 'stage' ? config.USERS_API_DOMAIN_STAGE : env === 'prod' ? config.USERS_API_DOMAIN_PROD : '');
    config.USERS_API_BASE_PATH = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_USERS_API_BASE_PATH) ? process.env.REACT_APP_USERS_API_BASE_PATH : (env === 'local' ? config.USERS_API_BASE_PATH_LOCAL : env === 'dev' ? config.USERS_API_BASE_PATH_DEV : env === 'test' ? config.USERS_API_BASE_PATH_TEST : env === 'stage' ? config.USERS_API_BASE_PATH_STAGE : env === 'prod' ? config.USERS_API_BASE_PATH_PROD : '');

    /*
    config.ASSETS_API_DOMAIN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_ASSETS_API_DOMAIN) ? process.env.REACT_APP_ASSETS_API_DOMAIN : (env === 'local' ? config.ASSETS_API_DOMAIN_LOCAL : env === 'dev' ? config.ASSETS_API_DOMAIN_DEV : env === 'test' ? config.ASSETS_API_DOMAIN_TEST : env === 'prod' ? config.ASSETS_API_DOMAIN_PROD : '');
    config.ASSETS_API_BASE_PATH = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_ASSETS_API_BASE_PATH) ? process.env.REACT_APP_ASSETS_API_BASE_PATH : (env === 'local' ? config.ASSETS_API_BASEPATH_LOCAL : env === 'dev' ? config.ASSETS_API_BASEPATH_DEV : env === 'test' ? config.ASSETS_API_BASEPATH_TEST : env === 'prod' ? config.ASSETS_API_BASEPATH_PROD : '');
    */

    config.TOKEN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_TOKEN) ? process.env.REACT_APP_TOKEN : (env === 'local' ? config.TOKEN_LOCAL : env === 'dev' ? config.TOKEN_DEV : env === 'test' ? config.TOKEN_TEST : env === 'stage' ? config.TOKEN_STAGE : env === 'prod' ? config.TOKEN_PROD : config.TOKEN_TEST);

    config.DEBUG = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_DEBUG) ? process.env.REACT_APP_DEBUG : config.DEBUG;
    config.CLIENT_TIMEZONE = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_CLIENT_TIMEZONE) ? process.env.REACT_APP_CLIENT_TIMEZONE : config.CLIENT_TIMEZONE;
    config.CLIENT_LOGO_NAV = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_CLIENT_LOGO_NAV) ? process.env.REACT_APP_CLIENT_LOGO_NAV : config.CLIENT_LOGO_NAV;
    config.CLIENT_LOGO_LOGIN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_CLIENT_LOGO_LOGIN) ? process.env.REACT_APP_CLIENT_LOGO_LOGIN : config.CLIENT_LOGO_LOGIN;
    config.LOGIN_LOGO_OVERRIDE = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_LOGIN_LOGO_OVERRIDE) ? process.env.REACT_APP_LOGIN_LOGO_OVERRIDE : config.LOGIN_LOGO_OVERRIDE;
    
    config.APP_LOGO_NAV = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_APP_LOGO_NAV) ? process.env.REACT_APP_APP_LOGO_NAV : config.APP_LOGO_NAV;
    config.APP_LOGO_LOGIN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_APP_LOGO_LOGIN) ? process.env.REACT_APP_APP_LOGO_LOGIN : config.APP_LOGO_LOGIN;
    config.NAVBAR_APP_LOGO_MARGIN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_NAVBAR_APP_LOGO_MARGIN) ? process.env.REACT_APP_NAVBAR_APP_LOGO_MARGIN : config.NAVBAR_APP_LOGO_MARGIN;
    config.NAVBAR_CLIENT_LOGO_MARGIN = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_NAVBAR_CLIENT_LOGO_MARGIN) ? process.env.REACT_APP_NAVBAR_CLIENT_LOGO_MARGIN : config.NAVBAR_CLIENT_LOGO_MARGIN;
    
    config.NAVBAR_BKG_IMAGE = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_NAVBAR_BKG_IMAGE) ? process.env.REACT_APP_NAVBAR_BKG_IMAGE : config.NAVBAR_BKG_IMAGE;
    config.NAVBAR_NAVBAR_BKG_COLOR = process.env.NAVBAR_BKG_COLOR && !(!process.env.REACT_APP_NAVBAR_BKG_COLOR) ? process.env.REACT_APP_NAVBAR_BKG_COLOR : config.NAVBAR_BKG_COLOR;
    config.NAVBAR_BKG_OPACITY = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_NAVBAR_BKG_OPACITY) ? process.env.REACT_APP_NAVBAR_BKG_OPACITY : config.NAVBAR_BKG_OPACITY;
    

    config.WEB_APPLICATIONS = process.env.WEB_APPLICATIONS && !(!process.env.WEB_APPLICATIONS) ? process.env.WEB_APPLICATIONS : config.WEB_APPLICATIONS;
    config.EXCLUDED_APPLICATIONS = process.env.EXCLUDED_APPLICATIONS && !(!process.env.EXCLUDED_APPLICATIONS) ? process.env.EXCLUDED_APPLICATIONS : config.EXCLUDED_APPLICATIONS;
    
    config.SITE_USES_SUBWEBAPPLICATIONS = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_SITE_USES_SUBWEBAPPLICATIONS) ? process.env.REACT_APP_SITE_USES_SUBWEBAPPLICATIONS : config.SITE_USES_SUBWEBAPPLICATIONS;
    config.USEAUTHV2 = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_USEAUTHV2) ? process.env.REACT_APP_USEAUTHV2 : config.USEAUTHV2;
    config.USESIGNUPCODE = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_USESIGNUPCODE) ? process.env.REACT_APP_USESIGNUPCODE : config.USESIGNUPCODE;
    config.SIGNUPCODEMESSAGE = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_SIGNUPCODEMESSAGE) ? process.env.REACT_APP_SIGNUPCODEMESSAGE : config.SIGNUPCODEMESSAGE;
    
    config.REGISTRATION_SITE_BASEPATH = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_REGISTRATION_SITE_BASEPATH) ? process.env.REACT_APP_REGISTRATION_SITE_BASEPATH : (env === 'dev' ? config.REGISTRATION_SITE_BASEPATH_DEV : env === 'test' ? config.REGISTRATION_SITE_BASEPATH_TEST : env === 'stage' ? config.REGISTRATION_SITE_BASEPATH_STAGE : env === 'prod' ? config.REGISTRATION_SITE_BASEPATH_PROD : '');
    config.REGISTRATION_SITE_PATH = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_REGISTRATION_SITE_PATH) ? process.env.REACT_APP_REGISTRATION_SITE_PATH : config.REGISTRATION_SITE_PATH;
    config.REGISTRATION_CODE_LENGTH = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_REGISTRATION_CODE_LENGTH) ? process.env.REACT_APP_REGISTRATION_CODE_LENGTH : config.REGISTRATION_CODE_LENGTH;

    config.SHOWMOTD = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_SHOWMOTD) ? process.env.REACT_APP_SHOWMOTD : config.SHOWMOTD;
    config.MISC = process.env.REACT_APP_OVERRIDE && !(!process.env.REACT_APP_MISC) ? process.env.REACT_APP_MISC : config.MISC;


    // For icon & icon_small attributes of WEB_APPLICATIONS, you can either simmply an "images.whatever" reference to an imported image, or you can specify the path of the image you have in /public
    if (config.WEB_APPLICATIONS){
      for (let i=0; i < config.WEB_APPLICATIONS.length; i++){
        let webApp = config.WEB_APPLICATIONS[i];
        if (webApp.config.icon && webApp.config.icon.indexOf('images.',0) !== -1){
          webApp.config.icon = images[webApp.config.icon.split('.')[1]];
          //tility.log('image: '+webApp.config.icon);
        } 

        if (webApp.config.icon_small && webApp.config.icon_small.indexOf('images.',0) !== -1){
          webApp.config.icon_small = images[webApp.config.icon_small.split('.')[1]];
        } 

        //Utility.log('webApp.icon: '+webApp.config.icon);
      }
    }
    Utility.log('config loaded');

    return config;
  }
}
export default SettingsLoader;
