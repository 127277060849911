
import React from 'react';
import { Modal, Button, FormGroup, ControlLabel, FormControl, Alert, Form, Row, Col, Panel, Glyphicon } from 'react-bootstrap';
import API from '../../lib/API';
import { validateEmail } from '../../lib/API';
import { user } from '../../config/data';
import randombytes from 'randombytes';
import images from '../../config/images';
import _ from 'lodash';

import './style.css';
import Utility from '../../lib/Utility';

class PlantSelectorModal extends React.Component {

	// Set initial props
    static defaultProps = {

    }

    constructor(props) {
        super(props);

        this.mounted = false;

        // Use state-oriented styles here, static ones should go into the CSS
       this.style = {

       };

        this.timeout = null;

        // Set initial state

        this.state = {
            defaultPlant: {},
            errorMessage: '',
            successMessage: '',
            width: window.innerWidth,
            height: window.innerHeight,
            showing: true,
            plants: this.props.appStore.settings.SITES,
        }


        /*
        if (this.props.appStore.settings.SITE === 'CNRL'){
            this.state = {
                plants: [   {
                                id: 1,
                                name: 'Horizon',
                                icon: images.icon_plant,
                                backgroundColor: '#c62148',
                                disabled: false,
                            }, 
                            {
                                id: 2,
                                name: 'Albian Sands',
                                icon: images.icon_plant,
                                backgroundColor: '#aaaaaa',
                                disabled: false,
                            }

                ],
                defaultPlant: {},
                errorMessage: '',
                successMessage: '',
                width: window.innerWidth,
                height: window.innerHeight,
                showing: true,
            };
        } else if (this.props.appStore.settings.SITE === 'SYNCRUDE'){
            this.state = {
                plants: [   {
                                id: 1,
                                name: 'Mildred Lake',
                                icon: images.icon_plant,
                                backgroundColor: '#c62148',
                                disabled: false,
                            }, 
                            {
                                id: 2,
                                name: 'Aurora',
                                icon: images.icon_plant,
                                backgroundColor: '#aaaaaa',
                                disabled: false,
                            }

                ],
                defaultPlant: {},
                errorMessage: '',
                successMessage: '',
                width: window.innerWidth,
                height: window.innerHeight,
                showing: true,
            };
        } else if (this.props.appStore.settings.SITE === 'SUNCOR' || this.props.appStore.settings.SITE === 'SYNCRUDE'){
            this.state = {
                plants: [   {
                                id: 1,
                                name: 'Fort Hills',
                                icon: images.icon_plant_truck,
                                backgroundColor: '#c62148',
                                disabled: false,
                            }, 
                            {
                                id: 2,
                                name: 'Base Plant',
                                icon: images.icon_plant_truck,
                                backgroundColor: '#c62148',
                                disabled: false,
                            }, 
                            {
                                id: 3,
                                name: 'Mackay River',
                                icon: images.icon_plant,
                                backgroundColor: '#aaaaaa',
                                disabled: true,
                            }, 
                            {
                                id: 4,
                                name: 'Firebag',
                                icon: images.icon_plant,
                                backgroundColor: '#aaaaaa',
                                disabled: true,
                            }, 

                ],
                defaultPlant: {},
                errorMessage: '',
                successMessage: '',
                width: window.innerWidth,
                height: window.innerHeight,
                showing: true,
            };
        } else {
            this.state = {
                plants: [   {
                                id: 1,
                                name: 'Fort Hills',
                                icon: images.icon_plant_truck,
                                backgroundColor: '#c62148',
                                disabled: false,
                            }, 
                            {
                                id: 2,
                                name: 'Base Plant',
                                icon: images.icon_plant_truck,
                                backgroundColor: '#aaaaaa',
                                disabled: true,
                            }, 
                            {
                                id: 3,
                                name: 'Mackay River',
                                icon: images.icon_plant,
                                backgroundColor: '#aaaaaa',
                                disabled: true,
                            }, 
                            {
                                id: 4,
                                name: 'Firebag',
                                icon: images.icon_plant,
                                backgroundColor: '#aaaaaa',
                                disabled: true,
                            }, 

                ],
                defaultPlant: {},
                errorMessage: '',
                successMessage: '',
                width: window.innerWidth,
                height: window.innerHeight,
                showing: true,
            };
        }
        */

        if (this.props.sites && this.props.sites.length > 0){
            for (let i=0; i < this.state.plants.length; i++){
                if (this.props.sites.find(p => this.state.plants[i].id === p)){
                    this.state.plants[i].backgroundColor = '#c62148';
                    this.state.plants[i].disabled = false;
                } else {
                    this.state.plants[i].backgroundColor = '#aaaaaa';
                    this.state.plants[i].disabled = true;
                }
            }
        }
    }

    componentDidMount() {
        this.mounted = true;
        window.addEventListener('resize', this.updateWindowDimensions);
        this.init();
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState){
        if (this.props.sites && this.props.sites.length > 0){
            for (let i=0; i < this.state.plants.length; i++){
                if (this.props.sites.find(p => this.state.plants[i].id === p)){
                    this.state.plants[i].backgroundColor = '#c62148';
                    this.state.plants[i].disabled = false;
                } else {
                    this.state.plants[i].backgroundColor = '#aaaaaa';
                    this.state.plants[i].disabled = true;
                }
            }
        }
    }


    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    // ---- Member Methods (Use arrow syntax to auto-bind) ----

    init = () => {
        // Get or generate new device token
        //this.deviceToken = this.props.localStorage.get('deviceToken');
        let { settings } = this.props.appStore;
        /*
        this.defaultPlant = this.props.localStorage.getItem(settings.TOKEN+'_defaultPlant_'+settings.ENV);
        if (this.defaultPlant === undefined || this.defaultPlant === null || _.isEmpty(this.defaultPlant)){

            //this.props.localStorage.setItem(settings.TOKEN+'_defaultPlant_'+settings.ENV, this.defaultPlant);
        } 
        */
    }

    handleError = (error) => {
        console.log(error);
        this.setState({ errorMessage: error });

        if (this.state.width < 768){
            let that = this;
            if (error && error !== ''){
                this.timeout = setTimeout(function(){ that.handleError(''); }, 15000);
            } else {
                if (this.timeout){
                    clearTimeout(this.timeout);
                }
            }
        }
    }

    handleSuccess = (success) => {
        this.setState({ successMessage: success });

        if (this.state.width < 768){
            let that = this;
            if (success && success !== ''){
                this.timeout = setTimeout(function(){ that.handleSuccess(''); }, 15000);
            } else {
                if (this.timeout){
                    clearTimeout(this.timeout);
                }
            }
        }
    }

    handleClick = (config) => {
        if (!config.disabled){
            this.props.onPlantSelected(config);
        }
    }    


    // ---- Render Methods ----

    renderPlants = () => {

        return (<div className='plants-container'>
                    {this.state.plants.map((p, index) => this.renderPlant(p, index))}
                </div>
            );
    }

    renderPlant = (config, index) => {

         let plantStyle = {
            //position: 'relative',
            overflow: 'hidden',
            contain: 'content',
            boxShadow: '0 4px 5px rgba(0, 0, 0, 0.2)',
            //willChange: 'transform',
            //animationDuration: '200ms',
            //animationTimingFunction: 'step-end',
            //display: 'absolute',
            textAlign: 'center',
            padding: '20px',
            margin: '20px',
            width: '256px',
            height: '128px',
            //backgroundImage: 'url('+config.icon+')',
            icon: config.icon,
            backgroundColor: config.backgroundColor,
            backgroundRepeat: 'no-repeat',
            //transition: '0.2s transform ease',
            //WebkitTransition: '-webkit-transform 0.2s ease',
        }

        return (
                <React.Fragment key={'key-'+config.id}>
                {/* ((this.state.width <= 952) ? <div /> : null) */}
                <div ref={(ref) => this.myRef = (ref !== null ? ref : this.myRef)} className="Plant" style={plantStyle}>
                    <div onClick={() => this.handleClick(config)} style={config.disabled ? { width: '100%', height: '100%'} : { width: '100%', height: '100%', cursor: 'pointer' }}>
                        <div ref={(ref) => this.contentRef = ref} className={"Plant-Contents"}>
                            {config.name.length > 0 && config.icon ? <div className={this.state.width < 952 ? 'Plant-Title_small' : 'Plant-Title'} >{config.name}</div> : <div className={this.state.width < 952 ? 'Plant-Title_small' : 'Plant-Title'}>{config.name}</div> }
                            <div className="plant-icon-container-container">
                                {/* <div className="plant-icon-container"> */}
                                    <img className="plant-icon" src={config.icon} />
                                {/* </div> */}
                            </div>
                        </div>
                       
                    </div>
                </div>
                {/* ((this.state.width <= 952) ? <div /> : null) */}
                </React.Fragment>
            );
    }

    render() {
        const style = {...this.style, ...this.props.innerStyle};
        let { settings } = this.props.appStore;
        let ua = window.navigator.userAgent;
        let isIE = /MSIE|Trident/.test(ua);
        
        

        return (
            <div ref={(ref) => this.myRef = ref} className='PlantSelectorModal' style={style}>
                <Modal show={this.props.show} animation={!isIE} onHide={this.props.onClosePlantSelectorModal} autoFocus={true} backdrop={'static'} dialogClassName={'modal-PlantSelector'} enforceFocus={true}>
                {/*
                  <Modal.Header closeButton>
                    &nbsp;
                  </Modal.Header>
                */}
                  <Modal.Body style={{ backgroundColor: '#F5F5F5', borderRadius: '5px' }}>
                    <div style={{ padding: '16px', textAlign: 'center' }}>
                     {/* <div className='plant-selector-close-btn'><Glyphicon glyph='remove-circle' style={{ color: '#666666', cursor: 'pointer', width: '100%', height: '100%', fontSize: '1.5em' }} onClick={this.props.onClosePlantSelectorModal} /></div> */}
                     <h1><p><b>Please Select Your Site</b></p></h1>
                     {this.renderPlants()}


                    { (this.state.errorMessage !== '') ?
                        <Alert bsStyle="danger">
                            {this.state.errorMessage}<br />
                        </Alert> : null
                    }

                    { (this.state.successMessage !== '') ?
                        <Alert bsStyle="success">
                            {this.state.successMessage}
                        </Alert> : null
                    }
                    </div>
                   </Modal.Body>
                   {/*
                  <Modal.Footer>
                    { !this.state.forgotPassword && <Button bsStyle="primary" bsSize="large" onClick={this.UserContactInfoUser}>{this.state.UserContactInfoButtonCopy}</Button>}
                    { this.state.forgotPassword && <Button bsSize="large" onClick={() => this.setState({ forgotPassword: false })}>Cancel</Button>}
                  </Modal.Footer>
                    */}
                </Modal>

            </div>
        );
    }

    /*
    render() {
        const style = {...this.style, ...this.props.innerStyle};
        let { settings } = this.props.appStore;
    	return (
    		<div ref={(ref) => this.myRef = ref} className='PlantSelectorModal' style={style}>
	    		<Modal show={this.state.showing} onHide={() => {}} autoFocus={true} backdrop={'static'} dialogClassName={'modal-login'} enforceFocus={true}>
                  <Modal.Header>
                    
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div><img src={images.imageList[settings.APP_LOGO_LOGIN] ? images.imageList[settings.APP_LOGO_LOGIN] : settings.APP_LOGO_LOGIN} style={{ maxWidth: '400px', height: 'auto', padding: '25px'}}  alt={''} /></div>
                        <div className="login-logo-right"><img src={images.imageList[settings.CLIENT_LOGO_LOGIN] ? images.imageList[settings.CLIENT_LOGO_LOGIN] : settings.CLIENT_LOGO_LOGIN} style={{ maxWidth: '220px', height: 'auto', padding: '25px 30px', marginLeft: '-30px' }} alt={''} /></div>
                        {this.props.env === 'dev' || this.props.env === 'davedev' || this.props.env === 'test' ? <span style={{ color: '#FF0000', position: 'absolute', left: '320px', top: '60px', fontSize: '16px' }}>{this.props.env}</span> : null }

                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ padding: '16px' }}>
                    <h4>Welcome to the SiteServices login screen.</h4>
                    <p>{this.getPlantSelectorModalMessage()}</p>
                    <br />

                    <form>
                        { !this.state.forgotPassword && <FormGroup
                            controlId="form-login-username"
                            validationState={this.getUsernameValidationState()}
                            >
                            <ControlLabel>Username{ this.props.allowBadgeNumberForAuthentication ? ' or Badge Number' : null }</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.username}
                                placeholder={"Enter username"}
                                onChange={this.onUsernameChange}
                                autoFocus
                                />
                        </FormGroup>}

                        { !this.state.forgotPassword && (!this.props.allowDeviceTokenForAuthentication || this.state.secondStagePasswordRequired) ?
                            <FormGroup
                                controlId="form-login-password"
                                validationState={this.getPasswordValidationState()}
                                >
                                <ControlLabel>Password</ControlLabel>
                                <FormControl
                                    type="password"
                                    value={this.state.password}
                                    placeholder={"Enter password"}
                                    onChange={this.onPasswordChange}
                                    onKeyPress={event => {
                                        if (event.key === "Enter"){
                                            this.loginUser(event);
                                        }
                                    }}
                                    />
                            <a style={{ fontSize: '11px' }} onClick={this.forgotPasswordClicked} href="#">Forgot Password</a>
                        </FormGroup>
                         : null }

                         { this.state.forgotPassword && <FormGroup
                            controlId="form-login-username"
                            validationState={this.getEmailValidationState()}
                            >
                            <ControlLabel>Email Address</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.email}
                                placeholder={"Enter email"}
                                onChange={this.onEmailChange}
                                autoFocus
                                />
                            </FormGroup>}

                    </form>

                    { (this.state.errorMessage !== '') ?
                        <Alert bsStyle="danger">
                            {this.state.errorMessage}<br />
                            {(this.state.errorMessage.indexOf('Invalid Logon, please double check your username and password.') === -1) && (this.state.errorMessage.indexOf('Your password is out of date, please change your password and try logging in again to continue.') === -1) && <span></span>}
                        </Alert> : null
                    }

                    { (this.state.successMessage !== '') ?
                        <Alert bsStyle="success">
                            {this.state.successMessage}
                        </Alert> : null
                    }
                    </div>
                   </Modal.Body>
                  <Modal.Footer>
                    {this.renderSignUpFields()}

                    { !this.state.forgotPassword && <Button bsStyle="primary" bsSize="large" onClick={this.loginUser}>{this.state.loginButtonCopy}</Button>}
                    
                    { this.state.forgotPassword && <Button bsSize="large" onClick={() => this.setState({ forgotPassword: false })}>Cancel</Button>}
                    { this.state.forgotPassword && !this.state.emailValid && <Button bsStyle="primary" bsSize="large" disabled onClick={this.submitForgotPassword}>Send Email</Button>}
                    { this.state.forgotPassword && this.state.emailValid && <Button bsStyle="primary" bsSize="large" onClick={this.submitForgotPassword}>Send Email</Button>}
                  </Modal.Footer>
                </Modal>

    		</div>
    	);
    }
    */
}

export default PlantSelectorModal;
