import Utility from './Utility';
import EventCenter from './EventCenter';

// ---- Main API 
class API {
	// ---- Endpoint constants

	static user = {};
	static settings = {};
	static endpoints = {
		authenticate: `Authenticate`,		// GET, Pass username + ':' + password in header, plus webApplicationId
		authorize: 'AuthorizeV2',			// GET, pass webApplicationId, apiMethod
		logout: 'Logout',					// GET, pass webApplicationId
		changePassword: 'ChangePassword',	// GET, pass webApplicationId, newPassword
		change: 'Change',					// GET, pass webApplicationId, newPassword (JSON Friendly)
	};

	// AUTH_BASE_PATH: settings.AUTH_BASE_PATH,
	// AUTH_DOMAIN: settings.AUTH_DOMAIN,
	// API_BASE_PATH: settings.API_BASE_PATH,
	// API_DOMAIN: settings.API_DOMAIN,

	// ---- Methods
	/*
	static GET(url, params, baseURL = null) {
		baseURL = !baseURL ? `${this.settings.API_HTTP_PROTOCOL}${this.settings.API_DOMAIN}${this.settings.API_BASE_PATH}` : baseURL;
		//Using the !user returns true if user is null or undefined. !(!this.user) lets us know that it is NOT undefined or null. 
		if (!(!this.user) && this.user.odaKey !== '') {
			Utility.info(convertToQueryString(params));
			return fetch(`${baseURL}${url}${convertToQueryString(params)}`, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Basic ' + Base64.btoa(this.user.username + ":" + this.user.odaKey),
				},
				//body: JSON.stringify(params)
			});
		} else {
			return fetch(`${baseURL}${url}${convertToQueryString(params)}`, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				//body: JSON.stringify(params)
			});
		}
	}
	*/



	
	static GET(url, params, baseURL = null) {
		baseURL = !baseURL ? `${this.settings.API_HTTP_PROTOCOL}${this.settings.API_DOMAIN}${this.settings.API_BASE_PATH}` : baseURL;
		//Using the !user returns true if user is null or undefined. !(!this.user) lets us know that it is NOT undefined or null. 
		let user = this.user;
		if (!(!this.user) && this.user.odaKey !== '' && this.user.odaKey !== undefined && this.user.odaKey !== null) {
			Utility.info(convertToQueryString(params));

			return fetch(`${baseURL}${url}${convertToQueryString(params)}`, {
					method: 'GET',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Pragma': 'no-cache',
						'Authorization': 'Basic ' + Base64.btoa(user.username + ":" + user.odaKey),
					},
					//body: JSON.stringify(params)
				}).then(response => {
					
					switch (response.status) {
				      case 500: Utility.error('(500) Server error'); break;
				      case 401: Utility.error('(401) Unauthorized'); EventCenter.broadcast('onUnauthorized', { response: response }); break;
				      default: break;
				    }

				    if (response.ok) {
				      const contentType = response.headers.get('Content-Type') || '';
				      if (contentType.includes('application/json')) {
				      	return Promise.resolve(response);
				      } else {
				      	return Promise.reject(new Error('Response is not JSON.'));
				      }
				    } else {
				      // push error further for the next `catch`, like
				      return Promise.reject(new Error('Did not receive a 200 response.'));
				    }
				}).catch(error => {
				    return Promise.reject(error);
				});
			
			
		} else {
			
			return fetch(`${baseURL}${url}${convertToQueryString(params)}`, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Pragma': 'no-cache',
				},
				//body: JSON.stringify(params)
			}).then(response => {
				switch (response.status) {
			      case 500: Utility.error('(500) Server error'); break;
			      case 401: Utility.error('(401) Unauthorized'); EventCenter.broadcast('onUnauthorized', { response: response }); break;
			      default: break;
			    }

			    if (response.ok) {
			      const contentType = response.headers.get('Content-Type') || '';
			      if (contentType.includes('application/json')) {
			      	return Promise.resolve(response);
			      } else {
			      	return Promise.reject(new Error('Response is not JSON.'));
			      }
			    } else {
			      // push error further for the next `catch`, like
			      return Promise.reject(new Error('Did not receive a 200 response.'));
			    }
			}).catch(error => {
			    return Promise.reject(error);
			});
			
		}
	}
	

	//This is for pulling down Excel files.
	static GET2(url, params, baseURL = null) {
		baseURL = !baseURL ? `${this.settings.API_HTTP_PROTOCOL}${this.settings.API_DOMAIN}${this.settings.API_BASE_PATH}` : baseURL;
		//Using the !user returns true if user is null or undefined. !(!this.user) lets us know that it is NOT undefined or null. 
		if (!(!this.user) && this.user.odaKey != null && this.user.odaKey !== undefined && this.user.odaKey !== null) {
			Utility.info(convertToQueryString(params));
			return fetch(`${baseURL}${url}${convertToQueryString(params)}`, {
				method: 'GET',
				headers: {
					'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml',
					'Content-Type': 'application/json',
					'Pragma': 'no-cache',
					'Authorization': 'Basic ' + Base64.btoa(this.user.username + ":" + this.user.odaKey),
				},
				//body: JSON.stringify(params)
			});
		} else {
			return fetch(`${baseURL}${url}/${params}`, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Pragma': 'no-cache',
				},
				//body: JSON.stringify(params)
			});
		}
	}


	static PUT(url, params, baseURL = null) {
		baseURL = !baseURL ? `${this.settings.API_HTTP_PROTOCOL}${this.settings.API_DOMAIN}${this.settings.API_BASE_PATH}` : baseURL;
		let user = this.user;
		if (!(!this.user) && this.user.odaKey !== '' && this.user.odaKey !== undefined && this.user.odaKey !== null) {
			Utility.info(convertToQueryString(params));

			return fetch(`${baseURL}${url}`, {
					method: 'PUT',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Pragma': 'no-cache',
						'Authorization': 'Basic ' + Base64.btoa(user.username + ":" + user.odaKey),
					},
					body: JSON.stringify(params)
				}).then(response => {
					
					switch (response.status) {
				      case 500: Utility.error('(500) Server error'); break;
				      case 401: Utility.error('(401) Unauthorized'); EventCenter.broadcast('onUnauthorized', { response: response }); break;
				      default: break;
				    }

				    if (response.ok) {
				      const contentType = response.headers.get('Content-Type') || '';
				      if (contentType.includes('application/json')) {
				      	return Promise.resolve(response);
				      } else {
				      	return Promise.reject(new Error('Response is not JSON.'));
				      }
				    } else {
				      // push error further for the next `catch`, like
				      return Promise.reject(new Error('Did not receive a 200 response.'));
				    }
				}).catch(error => {
				    return Promise.reject(error);
				});
			
			
		} else {
			
			return fetch(`${baseURL}${url}`, {
				method: 'PUT',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Pragma': 'no-cache',
				},
				body: JSON.stringify(params)
			}).then(response => {
				switch (response.status) {
			      case 500: Utility.error('(500) Server error'); break;
			      case 401: Utility.error('(401) Unauthorized'); EventCenter.broadcast('onUnauthorized', { response: response }); break;
			      default: break;
			    }

			    if (response.ok) {
			      const contentType = response.headers.get('Content-Type') || '';
			      if (contentType.includes('application/json')) {
			      	return Promise.resolve(response);
			      } else {
			      	return Promise.reject(new Error('Response is not JSON.'));
			      }
			    } else {
			      // push error further for the next `catch`, like
			      return Promise.reject(new Error('Did not receive a 200 response.'));
			    }
			}).catch(error => {
			    return Promise.reject(error);
			});
			
		}
	}


	static POST(url, params, baseURL = null) {
		baseURL = !baseURL ? `${this.settings.API_HTTP_PROTOCOL}${this.settings.API_DOMAIN}${this.settings.API_BASE_PATH}` : baseURL;
		let user = this.user;

		if (!(!this.user) && this.user.odaKey !== '' && this.user.odaKey !== undefined && this.user.odaKey !== null) {
			Utility.info(convertToQueryString(params));

			return fetch(`${baseURL}${url}`, {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Pragma': 'no-cache',
						'Authorization': 'Basic ' + Base64.btoa(user.username + ":" + user.odaKey),
					},
					body: JSON.stringify(params)
				}).then(response => {
					
					switch (response.status) {
				      case 500: Utility.error('(500) Server error'); break;
				      case 401: Utility.error('(401) Unauthorized'); EventCenter.broadcast('onUnauthorized', { response: response }); break;
				      default: break;
				    }

				    if (response.ok) {
				      const contentType = response.headers.get('Content-Type') || '';
				      if (contentType.includes('application/json')) {
				      	return Promise.resolve(response);
				      } else {
				      	return Promise.reject(new Error('Response is not JSON.'));
				      }
				    } else {
				      // push error further for the next `catch`, like
				      return Promise.reject(new Error('Did not receive a 200 response.'));
				    }
				}).catch(error => {
				    return Promise.reject(error);
				});
			
			
		} else {
			
			return fetch(`${baseURL}${url}`, {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Pragma': 'no-cache',
				},
				body: JSON.stringify(params)
			}).then(response => {
				switch (response.status) {
			      case 500: Utility.error('(500) Server error'); break;
			      case 401: Utility.error('(401) Unauthorized'); EventCenter.broadcast('onUnauthorized', { response: response }); break;
			      default: break;
			    }

			    if (response.ok) {
			      const contentType = response.headers.get('Content-Type') || '';
			      if (contentType.includes('application/json')) {
			      	return Promise.resolve(response);
			      } else {
			      	return Promise.reject(new Error('Response is not JSON.'));
			      }
			    } else {
			      // push error further for the next `catch`, like
			      return Promise.reject(new Error('Did not receive a 200 response.'));
			    }
			}).catch(error => {
			    return Promise.reject(error);
			});
			
		}
	}

	static DELETE(url, params, baseURL = null) {
		baseURL = !baseURL ? `${this.settings.API_HTTP_PROTOCOL}${this.settings.API_DOMAIN}${this.settings.API_BASE_PATH}` : baseURL;
		let user = this.user;

		if (!(!this.user) && this.user.odaKey !== '' && this.user.odaKey !== undefined && this.user.odaKey !== null) {
			Utility.info(convertToQueryString(params));

			return fetch(`${baseURL}${url}`, {
					method: 'DELETE',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Pragma': 'no-cache',
						'Authorization': 'Basic ' + Base64.btoa(user.username + ":" + user.odaKey),
					},
					body: JSON.stringify(params)
				}).then(response => {
					
					switch (response.status) {
				      case 500: Utility.error('(500) Server error'); break;
				      case 401: Utility.error('(401) Unauthorized'); EventCenter.broadcast('onUnauthorized', { response: response }); break;
				      default: break;
				    }

				    if (response.ok) {
				      const contentType = response.headers.get('Content-Type') || '';
				      if (contentType.includes('application/json')) {
				      	return Promise.resolve(response);
				      } else {
				      	return Promise.reject(new Error('Response is not JSON.'));
				      }
				    } else {
				      // push error further for the next `catch`, like
				      return Promise.reject(new Error('Did not receive a 200 response.'));
				    }
				}).catch(error => {
				    return Promise.reject(error);
				});
			
			
		} else {
			
			return fetch(`${baseURL}${url}`, {
				method: 'DELETE',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Pragma': 'no-cache',
				},
				body: JSON.stringify(params)
			}).then(response => {
				switch (response.status) {
			      case 500: Utility.error('(500) Server error'); break;
			      case 401: Utility.error('(401) Unauthorized'); EventCenter.broadcast('onUnauthorized', { response: response }); break;
			      default: break;
			    }

			    if (response.ok) {
			      const contentType = response.headers.get('Content-Type') || '';
			      if (contentType.includes('application/json')) {
			      	return Promise.resolve(response);
			      } else {
			      	return Promise.reject(new Error('Response is not JSON.'));
			      }
			    } else {
			      // push error further for the next `catch`, like
			      return Promise.reject(new Error('Did not receive a 200 response.'));
			    }
			}).catch(error => {
			    return Promise.reject(error);
			});
			
		}
	}

	static DELETE_NO_BODY(url, params, baseURL = null) {
		baseURL = !baseURL ? `${this.settings.API_HTTP_PROTOCOL}${this.settings.API_DOMAIN}${this.settings.API_BASE_PATH}` : baseURL;
		//Using the !user returns true if user is null or undefined. !(!this.user) lets us know that it is NOT undefined or null. 
		let user = this.user;
		if (!(!this.user) && this.user.odaKey !== '') {
			Utility.info(convertToQueryString(params));

			return fetch(`${baseURL}${url}${convertToQueryString(params)}`, {
					method: 'DELETE',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Pragma': 'no-cache',
						'Authorization': 'Basic ' + Base64.btoa(user.username + ":" + user.odaKey),
					},
					//body: JSON.stringify(params)
				}).then(response => {
					
					switch (response.status) {
				      case 500: Utility.error('(500) Server error'); break;
				      case 401: Utility.error('(401) Unauthorized'); EventCenter.broadcast('onUnauthorized', { response: response }); break;
				      default: break;
				    }

				    if (response.ok) {
				      const contentType = response.headers.get('Content-Type') || '';
				      if (contentType.includes('application/json')) {
				      	return Promise.resolve(response);
				      } else {
				      	return Promise.reject(new Error('Response is not JSON.'));
				      }
				    } else {
				      // push error further for the next `catch`, like
				      return Promise.reject(new Error('Did not receive a 200 response.'));
				    }
				}).catch(error => {
				    return Promise.reject(error);
				});
			
			
		} else {
			
			return fetch(`${baseURL}${url}${convertToQueryString(params)}`, {
				method: 'DELETE',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Pragma': 'no-cache',
				},
				//body: JSON.stringify(params)
			}).then(response => {
				switch (response.status) {
			      case 500: Utility.error('(500) Server error'); break;
			      case 401: Utility.error('(401) Unauthorized'); EventCenter.broadcast('onUnauthorized', { response: response }); break;
			      default: break;
			    }

			    if (response.ok) {
			      const contentType = response.headers.get('Content-Type') || '';
			      if (contentType.includes('application/json')) {
			      	return Promise.resolve(response);
			      } else {
			      	return Promise.reject(new Error('Response is not JSON.'));
			      }
			    } else {
			      // push error further for the next `catch`, like
			      return Promise.reject(new Error('Did not receive a 200 response.'));
			    }
			}).catch(error => {
			    return Promise.reject(error);
			});
			
		}
	}

	static authenticate(username, password, webApplicationId, baseURL = null, asUser = null) {
		Utility.info(webApplicationId)
		baseURL = !baseURL ? `${this.settings.API_HTTP_PROTOCOL}${this.settings.AUTH_DOMAIN}${this.settings.AUTH_BASE_PATH}` : baseURL;

		Utility.info('API.authenticate: baseURL: ' + baseURL + ' AUTH_DOMAIN: ' + this.settings.AUTH_DOMAIN + ' AUTH_BASE_PATH: ' + this.settings.AUTH_BASE_PATH);
		if (asUser){
			return fetch(`${baseURL}AuthenticateAs`, {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Pragma': 'no-cache',
					'Authorization': 'Basic ' + Base64.btoa(username + ":" + password)
				},
				body: JSON.stringify({ email: asUser, webApplicationID: webApplicationId})
			});
		} else {
			return fetch(`${baseURL}Authenticate?webApplicationId=${webApplicationId}`, {
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Pragma': 'no-cache',
					'Authorization': 'Basic ' + Base64.btoa(username + ":" + password)
				}
			});
		}
		
	}

	
	static openTabWithAuthHeader(url, user) {
		let win = window.open('_blank');
		if (this.user.odaKey != null) {
			fetch(`${url}`, {
				method: 'GET',
				redirect: 'follow',
				headers: {
					'Authorization': 'Basic ' + Base64.btoa(this.user.username + ":" + this.user.odaKey),
				},
				//body: JSON.stringify(params)
			}).then(function (response) {
				return response.blob();
			})
				.then(function (myBlob) {
					let myURL = window.URL || window.webkitURL
					// eslint-disable-next-line
					var objectURL = myURL.createObjectURL(myBlob);
					win.location = url;
				});
		} else {
			win.location = url;
		}
	}
};


		/*
        createBlob(url, function(blob) {
        	let myURL = window.URL || window.webkitURL
            let url = myURL.createObjectURL(blob);
            win.location = url;
        });
        */


// ---- Helper functions

export const validateEmail = (email) => {
  // eslint-disable-next-line	
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/;
  return re.test(email);
}

export const fixedEncodeURIComponent = (str) => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
}

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
const Base64 = {
  btoa: (input:string = '')  => {
    let str = input;
    let output = '';

    // eslint-disable-next-line
    for (let block = 0, charCode, i = 0, map = chars; str.charAt(i | 0) || (map = '=', i % 1); output += map.charAt(63 & block >> 8 - i % 1 * 8)) {

      charCode = str.charCodeAt(i += 3/4);

      if (charCode > 0xFF) {
        throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
      }
      
      // eslint-disable-next-line
      block = block << 8 | charCode;
    }
    
    return output;
  },

  atob: (input:string = '') => {
    let str = input.replace(/=+$/, '');
    let output = '';

    if (str.length % 4 === 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    // eslint-disable-next-line
    for (let bc = 0, bs = 0, buffer, i = 0; buffer = str.charAt(i++); ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer, bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0) {
      buffer = chars.indexOf(buffer);
    }

    return output;
  }
};

function convertToQueryString(obj){
	//if (obj == null || _.isEmpty(obj)) { return '' };
	if (obj == null || Object.keys(obj).length === 0) { return ''; };

	let str = '?';
	for (let prop in obj){
		let val = obj[prop]; //JSON.stringify(obj[prop]);

		//console.log('prop='+prop+'    val='+val+'    encodeProp='+ fixedEncodeURIComponent(prop) + '   encodeVal=' + fixedEncodeURIComponent(val) );
		
		//if (Object.prototype.toString.call(obj) === '[object Date]'){

		//}

		str = str + fixedEncodeURIComponent(prop) + '=' + fixedEncodeURIComponent(val) + '&';
	}

	return str.slice(0, -1);
}

// Can be used in JSON.parse to bring functions back to life - BEWARE CODE INJECTION!  Use wisely.
// eslint-disable-next-line
let reviver = (key, value) => {  
  if (typeof value === 'string' 
      && value.indexOf('function ') === 0) {    
    let functionTemplate = `(${value}).call(this)`;  
    // eslint-disable-next-line 
    return new Function(functionTemplate);  
  }  
  return value;
};

// Can be used in JSON.stringify to stringify functions
// eslint-disable-next-line
let replacer = (key, value) => {  
  // if we get a function give us the code for that function  
  if (typeof value === 'function') {
    return value.toString();  
  }   
  return value;
} 
// eslint-disable-next-line
let createBlob = (url, user, success) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader("Authorization", 'Basic '+Base64.btoa(user.username + ":" + user.odaKey));
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.responseType = "blob";
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
            if (success) success(xhr.response);
        }
    };
    xhr.send(null);
}


// ---- Example API Call:
/*
	let params = { webApplicationId: this.props.webApplicationId };
	API.GET("Logout",params)
	.then((response) => response.json())
	.then((responseJson) => {
	  if (responseJson !== undefined && responseJson.odaKey !== undefined){
	    
	    this.localStorage.remove('user');
	    this.setState({ user: emptyUser });

	  } else {
	    
	    user.errorMessage = responseJson.errorMessage;
	    this.handleError(user.errorMessage);
	    
	    //this.buttonClicked = false;
	  }
	  
	  if (this.mounted){
	    this.setState({ loading: false });
	  }

	})
	.catch((error) => {
	  console.error(error);
	  
	  user.errorMessage = 'A network error occurred, please check your internet connectivity.';
	  this.handleError(user.errorMessage);
	  if (this.mounted){
	    this.setState({ loading: false });
	  }
	  //this.buttonClicked = false;
	});
*/

export default API;




