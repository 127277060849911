
import React from 'react';
import images from '../../../config/images';
import './style.css';

class DashItem_EMS extends React.Component {

	// Set initial props
    static defaultProps = {
        
    }

    constructor(props) {
        super(props);

        this.mounted = false;

        // Use state-oriented styles here, static ones should go into the CSS
        this.style = {
            
        };

        // Set initial state
        this.state = {
            errorMessages: [],
        };
    }

    componentDidMount() {
        this.mounted = true;
        
        this.init();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillUpdate(nextProps, nextState) {
        
    }

    // ---- Member Methods (Use arrow syntax to auto-bind) ----

    init = () => {
        
    }


    // ---- Render Methods ----

    render() {
        const style = {...this.style, ...this.props.innerStyle};
    	return (
    		<div ref={(ref) => this.myRef = ref} className='DashItem_EMS' style={style}>
	    		<div className='contentDiv'>
                    <h4><u>Rental Status:</u></h4>
                    <p>• 240 Items On Rent</p>
                    <p>• 12 Need Confirmation</p>
                    <div style={{ position: 'absolute', right: 20, bottom: 20 }}>
                        <div style={{ textAlign: 'center', fontSize: 11, width: 100 }}>
                        SITERENTALS MAP
                        <a href='https://siteservicesdemo.siterentals.com/Maps' target='_blank'><img src={images.map_icon} /></a>
                        </div>
                    </div>
                </div>
    		</div>
    	);
    }
}

export default DashItem_EMS;
