// DashCollection is the root-level node of the DashItem/DashItemModal system for displaying & navigating through information

import React from 'react';
import './style.css';
import DashItem from '../DashItem';
import DashItemModal from '../DashItemModal';
import ModalBackdrop from '../ModalBackdrop';

class DashCollection extends React.Component {

	// Set initial props
    static defaultProps = {
        
    }

    constructor(props) {
        super(props);

        this.mounted = false;

        this.style = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            flexWrap: 'wrap',
            position: 'relative',
            marginTop: 20,
            marginBottom: 20,
            minWidth: 300,
        }

        this.dashItemRects = {};

        // Set initial state
        this.state = {
            dashItems: this.props.dashItems,
            dashItemModal: null,
            modalBackdrop: false,
            hideModalBackdrop: true,
            hideModal: false,
            width: window.innerWidth,
            height: window.innerHeight,
            marginLeft: 0, //window.innerWidth < 520 ? 0 : this.props.appStore.settings.SITE === 'SUNCOR' || this.props.appStore.settings.SITE === 'SYNCRUDE' ? 0 : 80,
            marginRight: 0, //window.innerWidth < 520 ? 0 : this.props.appStore.settings.SITE === 'SUNCOR' || this.props.appStore.settings.SITE === 'SYNCRUDE' ? 0 : 80,
        };
    }

    componentDidMount() {
        this.mounted = true;
        window.addEventListener("resize", this.updateDimensions);
        this.init();
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("resize", this.updateDimensions);
    }

    /*
    shouldComponentUpdate(nextProps, nextState){
        return true;
    }
    */

    updateDimensions = () => {
        
        if (window.innerWidth < 520){
           this.setState({width: window.innerWidth, height: window.innerHeight, marginLeft: 0, marginRight: 0 });
        } else {
           //this.setState({width: window.innerWidth, height: window.innerHeight, marginLeft: this.props.appStore.settings.SITE === 'SUNCOR' || this.props.appStore.settings.SITE === 'SYNCRUDE' ? 0 : 80, marginRight: this.props.appStore.settings.SITE === 'SUNCOR' || this.props.appStore.settings.SITE === 'SYNCRUDE' ? 0 : 80 });
           this.setState({width: window.innerWidth, height: window.innerHeight, marginLeft: 0, marginRight: 0 });
        }

    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.dashItems.length !== this.state.dashItems.length){
            this.setState({ dashItems: nextProps.dashItems });
        }
    }

    // ---- Member Methods (Use arrow syntax to auto-bind) ----

    init = () => {

    }

    handleExpandItem = (config, refreshFunction) => {
        const dashItems = [...this.state.dashItems];

        for (let i=0; i < dashItems.length; i++){
            if (dashItems[i].id === config.id){
                dashItems[i].config.expanded = true;
                this.dashItemRects[config.id] = config.boundingRect;
            } else {
                dashItems[i].config.expanded = false;
            }
        }

        this.dashItemRectUpdateFunction = refreshFunction;
        this.setState({ dashItems, dashItemModal: { config }, modalBackdrop: true, hideModalBackdrop: false });
    }

    handleCloseItem = () => {
        //this.setState({ dashItemModal: null });

        this.handleShowAllItems();
    }

    handlePrepareToShowAllItems = () => {
        this.setState({ hideModalBackdrop: true });
    }

    handleBackdropClicked = () => {
        this.setState({ hideModal: true });
    }

    handleShowAllItems = () => {
        const dashItems = [...this.state.dashItems];

        for (let i=0; i < dashItems.length; i++){
            dashItems[i].config.expanded = false;
        }

        this.setState({ dashItems, dashItemModal: null, modalBackdrop: false, hideModal: false });
    }

    handleGetPlaceholder = () => {
        return this.placeholderRef;
    }

    handleReportPlaceholder = (ref) => {
        console.log('handleReportPlaceholder: '+ref);
        this.placeholderRef = ref;
    }

    handleReportBoundingRect = (dashItemConfig, refreshFunction) => {
        //console.log('Rect - id: '+dashItemConfig.id);
        //this.traceRect(dashItemConfig.boundingRect);
        
        this.dashItemRects[dashItemConfig.id] = dashItemConfig.boundingRect;
        this.dashItemRectUpdateFunction = refreshFunction;
    }
    dashItemRectUpdateFunction = () => {}

    // ---- Render Methods ----

    render() {
        let style = { ...this.style, marginLeft: this.state.marginLeft, marginRight: this.state.marginRight }
    	return (
    		<div className="DashCollection" style={style}>
                {this.state.dashItems.map((item) => {
                    return (
                                <DashItem 
                                    key={item.id}
                                    location={this.props.location} 
                                    config={item.config}
                                    handleExpandItem={this.handleExpandItem}
                                    handleReportBoundingRect={this.handleReportBoundingRect}
                                    user={this.props.user}
                                    appStore={this.props.appStore}
                                    {...this.state}
                                />
                            );
                    })}

                { this.state.modalBackdrop && <ModalBackdrop onClick={this.handleCloseItem} showing={this.state.modalBackdrop} hide={this.state.hideModalBackdrop} hideAnimation={this.handleBackdropClicked} /> }

                { this.state.dashItemModal && <DashItemModal key={'dashItemModal'} config={ this.state.dashItemModal.config } willClose={this.state.hideModal} handleCloseItem={this.handleCloseItem} hideAnimation={this.handlePrepareToShowAllItems} updateRectFunction={this.dashItemRectUpdateFunction} user={this.props.user} />}
                
                {/* <DashItem key='DashItem-Placeholder' placeholder={true} config={{color: 0x000000, id: 0}} handleExpandItem={() => {}} handleGetPlaceholder={this.handleGetPlaceholder} handleReportPlaceholder={this.handleReportPlaceholder} /> */}
            </div>
    	);
    }

    // ---- Utility Methods ----

    rectsAreIdentical = (rect1, rect2) => {
        if (rect1.left !== rect2.left) return false;
        if (rect1.top !== rect2.top) return false;
        if (rect1.width !== rect2.width) return false;
        if (rect1.height !== rect2.height) return false;
        return true;
    }

    rectDifference = (rect1, rect2) => {
        let obj = { left: 0, top: 0, bottom: 0, right: 0, width: 0, height: 0 };
        obj.left = rect2.left - rect1.left;
        obj.top = rect2.top - rect1.top;
        obj.bottom = rect2.bottom - rect1.bottom;
        obj.right = rect2.right - rect1.right;
        obj.width = rect2.width - rect1.width;
        obj.height = rect2.height - rect1.height;
    }

    traceRect = (rect) => {
        console.log('Rect: left: '+rect.left+' top:'+rect.top+' w: '+rect.width+' h: '+rect.height);
    }
}

export default DashCollection;
