
import React from 'react';
import { Modal, Button, FormGroup, ControlLabel, FormControl, Alert } from 'react-bootstrap';
import API from '../../lib/API';
import { user } from '../../config/data';
import PasswordForm from '../ResetPassword/PasswordForm';
import './style.css';

class ChangePassword extends React.Component {

	// Set initial props
    static defaultProps = {
        
    }

    constructor(props) {
        super(props);

        this.mounted = false;

        // Use state-oriented styles here, static ones should go into the CSS
        this.style = {
            
        };

        // Set initial state
        this.state = {
            password_new: '',
            password_new2: '',
            errorMessage: '',
            loading: false,
        };
    }

    componentDidMount() {
        this.mounted = true;
        
        this.init();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillUpdate(nextProps, nextState) {
        
    }

    // ---- Member Methods (Use arrow syntax to auto-bind) ----

    init = () => {
        
    }

    handleError = (error) => {
        console.log(error);
        this.setState({ errorMessage: error });
    }

    cancel = (event) => {
        this.setState({ errorMessage: '' });
        this.props.userCancelledChangePassword();
    }

    changePassword = (event) => {
        event && event.preventDefault();
        let { settings } = this.props.appStore;
        let params = { WebApplicationId: this.props.webApplicationId, NewPassword: this.state.password_new };
        API.POST(API.endpoints.change, params, `${API.settings.API_HTTP_PROTOCOL}${API.settings.AUTH_DOMAIN}${API.settings.AUTH_BASE_PATH}`)
        .then((response) => response.json())
        .then((responseJson) => {
            let error = responseJson.Error ?? responseJson.error;
            let result = responseJson.Result ?? responseJson.result ?? responseJson.Results ?? responseJson.results;
            let messages = responseJson.Messages ?? responseJson.messages;
            let expiration = responseJson.Expiration ?? responseJson.expiration;

            if (!error){

                this.setState({ errorMessage: '' });
                this.props.userChangedPassword(this.props.passwordExpired, this.state.password_new);
                alert('Your password has successfully been changed.')
            } else {
                if (messages && messages.length){
                    user.errorMessage = messages[0].Description;
                } else {
                    user.errorMessage = "An unknown error occurred.  Please try again later."
                }
                this.handleError(user.errorMessage);
                
                //this.buttonClicked = false;
            }
            
            if (this.mounted){
                this.setState({ loading: false });
            }

        })
        .catch((error) => {
          console.error(error);
          
          user.errorMessage = 'A network error occurred, please check your internet connectivity.';
          this.handleError(user.errorMessage);
          if (this.mounted){
            this.setState({ loading: false });
          }
          //this.buttonClicked = false;
        });
        
    }

    onPasswordChange1 = (event) => {
        this.setState({ password_new: event.target.value });
    }

    onPasswordChange2 = (event) => {
        this.setState({ password_new2: event.target.value });
    }

    /*
    getPasswordValidationState1 = () => {
        //const length = this.state.username.length;

    }

    getPasswordValidationState2 = () => {
        const length = this.state.password_new2.length;
        if (length > 0){
            if (this.state.password_new !== this.state.password_new2){
                return 'error';
            } else {
                return 'success';
            }
        }
    }
    */

    onPasswordChange = (password) => {
		this.setState({ password_new: password });
	};
    isValid = () => {
		let { password_new } = this.state;

		return (
			(password_new !== '' ? true : false) 
		);
	};

    // ---- Render Methods ----

    render() {
        const style = {...this.style, ...this.props.innerStyle};
    	return (
    		<div ref={(ref) => this.myRef = ref} className='ChangePassword' style={style}>
	    		<Modal show={this.props.changingPassword} onHide={() => {}} autoFocus={true} backdrop={'static'} dialogClassName={'modal-password'} enforceFocus={true}>
                  <Modal.Header>
                    <Modal.Title>Change Password</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='password-form-container'>
                        <form>
					        <PasswordForm passwordSet={this.onPasswordChange} inRegistration={true} />
                        </form>
                    </div>
                    {/* <form>
                        <FormGroup
                            controlId="form-login-username"
                            validationState={this.getPasswordValidationState1()}
                            >
                            <ControlLabel>Enter New Password</ControlLabel>
                            <FormControl
                                type="password"
                                value={this.state.password_new}
                                placeholder={"Enter password"}
                                onChange={this.onPasswordChange1}
                                />
                        </FormGroup>
                        <FormGroup
                            controlId="form-login-password"
                            validationState={this.getPasswordValidationState2()}
                            >
                            <ControlLabel>Confirm New Password</ControlLabel>
                            <FormControl
                                type="password"
                                value={this.state.password_new2}
                                placeholder={"Enter password"}
                                onChange={this.onPasswordChange2}
                                onKeyPress={event => {
                                        if (event.key === "Enter"){
                                            this.changePassword(event);
                                        }
                                    }}
                                />
                        </FormGroup>

                                </form> */}

                    { (this.state.errorMessage !== '') ?
                        <Alert bsStyle="danger">
                            {this.state.errorMessage}
                        </Alert> : null
                    }
                   </Modal.Body>
                  <Modal.Footer>
                    <Button bsStyle="primary" bsSize="large" disabled={!this.isValid()} onClick={this.changePassword}>Submit</Button>
                    <Button bsSize="large" onClick={this.cancel}>Cancel</Button>
                  </Modal.Footer>
                </Modal>

    		</div>
    	);
    }
}

export default ChangePassword;
