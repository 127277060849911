
import React from 'react';
import './style.css';
import { BarLoader } from 'react-spinners';
import Dashboard from '../../dashboard/containers/Dashboard';
import PlantSelectorModal from '../../components/PlantSelectorModal';
import EventCenter from '../../lib/EventCenter';
import _ from 'lodash';
import Utility from '../../lib/Utility';

class Content extends React.Component {

	// Set initial props
    static defaultProps = {
        
    }

    constructor(props) {
        super(props);

        this.mounted = false;

        // Use state-oriented styles here, static ones should go into the CSS
        this.style = {
            
        };

        // Set initial state
        this.state = {
            loading: false,
            showPlantSelector: props.appStore.settings.SITES.length > 1 && !this.props.savedPlant && this.props.user ? true : false,
            selectedPlant: this.props.savedPlant ? this.props.savedPlant : { id: 0 },
            errorMessages: [],
        };

        Utility.log('--- Content: this.props.savedPlant: '+this.props.savedPlant);
        Utility.log('--- Content: showPlantSelector: '+this.state.showPlantSelector);
    }

    componentDidMount() {
        this.mounted = true;
        
        EventCenter.subscribe('onUserLoggedIn', this.onUserLoggedIn);
        EventCenter.subscribe('onShowPlantMenu', this.onShowPlantMenu);
        EventCenter.subscribe('onPlantSelected', this.onPlantSelectedEvent);
        this.init();
    }

    componentWillUnmount() {
        this.mounted = false;

        EventCenter.unsubscribe('onUserLoggedIn');
        EventCenter.unsubscribe('onShowPlantMenu');
        EventCenter.unsubscribe('onPlantSelected');
    }

    componentWillUpdate(nextProps, nextState) {
        
    }

    // ---- Member Methods (Use arrow syntax to auto-bind) ----

    init = () => {
        
    }

    onUserLoggedIn = (args) => {
        let { appStore } = this.props;
        //if (appStore.settings.STIE === 'FTHILLS' || appStore.settings.SITE === 'SUNCOR'){
        if (appStore.settings.SITES.length > 1){
            this.setState({ showPlantSelector: true });
        }
        //}
    }

    onShowPlantMenu = (args) => {
        let { appStore } = this.props;

        //if (appStore.settings.STIE === 'FTHILLS' || appStore.settings.SITE === 'SUNCOR'){
        if (appStore.settings.SITES.length > 1){
            this.setState({ showPlantSelector: true });
        }  
        //}
    }

    onClosePlantSelectorModal = () => {
        this.setState({ showPlantSelector: false });
    }

    onPlantSelected = (plant) => {
        EventCenter.broadcast('onPlantSelected', plant);
        this.setState({ showPlantSelector: false });
    }

    onPlantSelectedEvent = (plant) => {
        this.setState({ selectedPlant: plant });
        console.log('onPlantSelectedEvent('+plant.id+')');
    }

    filterApplications = (applications, plant) => {
        console.log('filterApplications('+applications+', '+plant+')');

        if (!plant || !applications || !plant.id) return applications;

        let filteredApplications = [];
        for (let i=0; i < applications.length; i++){
            if (!applications[i].config.site || applications[i].config.site.find(s => s === plant.id)){
                filteredApplications.push(applications[i]);
            }
        }

        return filteredApplications;
    }


    calculateAvailableSites = (applications) => {
        if (!applications) return [];

        let sites = [];
        for (let i=0; i < applications.length; i++){
            if (applications[i].config.site){
                if (applications[i].config.important === true){        // || applications[i].config.site.important === undefined
                    sites = _.union(applications[i].config.site, sites);
                }
            }
        }

        //console.log('calculateAvailableSites - ');
        //Utility.log(sites);
        //Utility.log(applications);

        return sites;
    }

    // ---- Render Methods ----

    render() {
        let { appStore } = this.props;
        const style = {...this.style, ...this.props.innerStyle};
    	return (
    		<div ref={(ref) => this.myRef = ref} className='Content' style={style}>
                

                <PlantSelectorModal appStore={this.props.appStore} show={this.state.showPlantSelector} applications={this.filterApplications(this.props.applications, this.state.selectedPlant)} sites={this.calculateAvailableSites(this.props.applications)} onPlantSelected={this.onPlantSelected} onClosePlantSelectorModal={this.onClosePlantSelectorModal} />

                {!this.state.showPlantSelector ? <Dashboard env={this.props.env} location={this.state.location} applications={this.filterApplications(this.props.applications, this.state.selectedPlant)} user={this.props.appStore.user} appStore={this.props.appStore} /> : null }
                {/* !this.state.showPlantSelector ? <Dashboard env={this.props.env} location={this.state.location} applications={[]} user={this.props.appStore.user} appStore={this.props.appStore} /> : null */}
	    		
                <div style={{ position: 'absolute', left: '50%', top:'50%', width:'30px', height:'30px', zIndex: 10000, margin: '-15px 0 0 -15px' }}>
                    <BarLoader
                        color={'#2c77bc'} 
                        loading={this.state.loading} 
                        />
                </div>
    		</div>
    	);
    }
}

export default Content;
