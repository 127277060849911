
class Utility {
    static debug = true;
    static logObject (obj, detail) {
        Utility.logDetail(detail);
        if (Utility.debug)
            console.info(JSON.parse(JSON.stringify(obj)));
    }
    static log (message, detail)  {
        Utility.logDetail(detail);
        if (typeof (message) === Object)
            Utility.logObject(message);
        else
            if (Utility.debug)
                console.log(message);
    }
    static error(message, detail) {
        Utility.logDetail(detail);
        if (Utility.debug)
            console.error(message);
    }
    static info(message, detail) {
        Utility.logDetail(detail);
        if (Utility.debug)
            console.info(message);
    }
    static warning(message, detail) {
        Utility.logDetail(detail);
        if (Utility.debug)
            console.warn(message);
    }
    static trace(message, detail) {
        Utility.logDetail(detail);
        if (Utility.debug)
            console.trace(message);
    }
    static logArray = (message, detail) => {
        Utility.logDetail(detail);
        if (Utility.debug) {
            message.forEach(element => {
                Utility.log(element);
            });
        }   
    }
    static logDetail = (detail) => {
        if (Utility.debug && !Utility.isNullOrUndefined(detail) && !Utility.isEmptyString(detail))
            console.log(detail);
    }
    static dataURLToBlob(dataURL) {
        var BASE64_MARKER = ';base64,';
        var parts = dataURL.split(BASE64_MARKER);
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
    }
    static isNullOrUndefined = (object) => {
        return object === undefined || object === null;
    }
    static isObjectEmpty = (object) => {
        return Object.keys(object).length === 0;
    }
    static isEmptyString = (object) => {
        return object === '';
    }
    static exists = (object) => {
        return !Utility.isNullOrUndefined(object) && !Utility.isObjectEmpty(object) && !Utility.isEmptyString(object);
    }
}
export default Utility