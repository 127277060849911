import React from 'react';
import './style.css';
import {
	FormControl,
	FormGroup,
	ControlLabel,
	ListGroup,
	ListGroupItem,
	Col,
	Row,
} from 'react-bootstrap';

const regexUpper = new RegExp('^(?=.*[A-Z])');
const regexLower = new RegExp('^(?=.*[a-z])');
// eslint-disable-next-line
const regexSpecial = /\W/; //Check Special
// eslint-disable-next-line
const regexNumber = /\d/; //new RegExp('^(?=.*[0-9])');
// eslint-disable-next-line
const negex = new RegExp('^(?=.*[*\\[\\]\'"])'); //Don't allow these

class PasswordForm extends React.Component {
	// Set initial props
	static defaultProps = {};
	//http://localhost:3000/#/reset?t=84BF07DE82DC460CB73736DAEBBC292E2FAA3A659334CE7F1B48D082CBBE421D255F9EA3FFE6C4258E6CB49EAE2BF09DC5C1E2B3515A3C034691D5C04BC57F84
	constructor(props) {
		super(props);

		this.mounted = false;

		// Use state-oriented styles here, static ones should go into the CSS
		this.style = {};

		// Set initial state
		this.state = {
			header: 'Please enter a valid password.',
			message: '',
			password: '',
			confirm: '',
			email: props.email,
		};
	}

	componentDidMount() {
		this.mounted = true;

		this.init();
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	componentWillUpdate(nextProps, nextState) {}

	// ---- Member Methods (Use arrow syntax to auto-bind) ----
	init = () => {};
	isValid = (password, confirm) => {
		return password === confirm ? this.validatePassword(confirm) : false;
	};
	isConfirmValid = () => {
		let { password, confirm } = this.state;

		return this.isValid(password, confirm) ? 'success' : 'error';
	};
	isPasswordValid = () => {
		let { password } = this.state;

		return this.validatePassword(password) ? 'success' : 'error';
	};
	validatePassword = (password) => {
		return (
			regexUpper.test(password) &&
			regexLower.test(password) &&
			regexSpecial.test(password) &&
			regexNumber.test(password) &&
			!negex.test(password) &&
			this.passwordNoColon()
		);
	};
	passwordUpper = () => {
		let { password } = this.state;

		return regexUpper.test(password) ? 'success' : 'error';
	};
	passwordLower = () => {
		let { password } = this.state;

		return regexLower.test(password) ? 'success' : 'error';
	};
	passwordNumber = () => {
		let { password } = this.state;

		return regexNumber.test(password) ? 'success' : 'error';
	};
	passwordSpecial = () => {
		let { password } = this.state;

		return regexSpecial.test(password) && !negex.test(password)
			? 'success'
			: 'error';
	};
	passwordMatch = () => {
		let { password, confirm } = this.state;

		return password.length > 0 && password === confirm ? 'success' : 'error';
	};
	passwordLength = () => {
		let { password } = this.state;
		return password.length >= 8 && password.length <= 25 ? 'success' : 'error';
	};
	passwordNoColon = () => {
		let { password } = this.state;
		return password.indexOf(':') === -1 ? 'success' : 'error';
	}
	handleError = (message, messageStyle = 'warning') => {
		this.setState({
			header: 'Something went wrong...',
			showAlert: true,
			message,
			messageStyle,
		});
	};
	// ---- Events
	onPasswordChange = (e) => {
		let password = e.target.value;
		let { confirm } = this.state;
		this.setState({ password });

		if (this.isValid(password, confirm)) this.props.passwordSet(password);
		else this.props.passwordSet('');
	};
	onConfirmChange = (e) => {
		let confirm = e.target.value;
		let { password } = this.state;
		this.setState({ confirm });

		if (this.isValid(password, confirm)) this.props.passwordSet(confirm);
		else this.props.passwordSet('');
	};

	// ---- Render Methods ----
	renderValidationList = () => {
		return (
			<ListGroup>
				<ValidationListItem
					text='Must have at least 1 uppercase letter.'
					id='upper'
					validator={this.passwordUpper}
				/>
				<ValidationListItem
					text='Must have at least 1 lowercase letter.'
					id='lower'
					validator={this.passwordLower}
				/>
				<ValidationListItem
					text='Must have at least 1 number.'
					id='number'
					validator={this.passwordNumber}
				/>
				<ValidationListItem
					text={`Must have at least 1 special character: ! ? @ # $`}
					id='special'
					validator={this.passwordSpecial}
				/>
				<ValidationListItem
					text={`Must not contain a colon (:)`}
					id='special'
					validator={this.passwordNoColon}
				/>
				<ValidationListItem
					text='Must be between 8 and 25 characters long.'
					id='length'
					validator={this.passwordLength}
				/>
				<ValidationListItem
					text='Passwords must match'
					id='length'
					validator={this.passwordMatch}
				/>
			</ListGroup>
		);
	};

	render = () => {
        let { password, confirm } = this.state;

        return (
            <div className='password-validation-container'>
                <FormGroup>
                    <Col sm={8} className='pw-reset-form-item'>
                        <FormGroup controlId='password' validationState={this.isPasswordValid()}>
                            <Col className='pw-reset-form-label' componentClass={ControlLabel} sm={6}>Password</Col>
                            <Col sm={3} className='pw-reset-form-item'>
                                <FormControl
                                    type='password'
                                    value={password}
                                    placeholder={'Enter password'}
                                    onChange={this.onPasswordChange}
                                    autoFocus
                                    autoComplete='new-password'
                                />
                                <FormControl.Feedback />
                                {/* <Col sm={5}></Col> */}
                            </Col>
                        </FormGroup>
                        <FormGroup controlId='confirm' validationState={this.isConfirmValid()}>
                            <Col className='pw-reset-form-label' componentClass={ControlLabel} sm={6}>Confirm Password</Col>
                            <Col sm={3} className='pw-reset-form-item'>
                                <FormControl
                                    type='password'
                                    value={confirm}
                                    placeholder={'Confirm password'}
                                    onChange={this.onConfirmChange}
                                    autoComplete='new-password'
                                />
                                <FormControl.Feedback />
                            </Col>
                            {/* <Col sm={5}></Col> */}
                        </FormGroup>
                        <Col sm={4}></Col>
                        <br></br><br></br><br></br>&nbsp;
                    </Col>

                        <h3>Password Validation</h3>
                        {this.renderValidationList()}
                </FormGroup>
            </div>
        )

    }
}

export default PasswordForm;

const ValidationListItem = (props) => {
	return (
		<ListGroupItem className='reset-list-item'>
			<FormGroup controlId={props.id} validationState={props.validator()}>
				<FormControl.Static>{props.text}</FormControl.Static>
				<FormControl.Feedback />
			</FormGroup>
		</ListGroupItem>
	);
};
