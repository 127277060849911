// ModalBackdrop is a semi-opaque div that blocks the actions of all the divs behind it when a modal comes up, and optionally allows for closing the modal when clicked

import React from 'react';
import './style.css';

class ModalBackdrop extends React.Component {

	// Set initial props
    static defaultProps = {
        
    }

    constructor(props) {
        super(props);

        this.mounted = false;

        // Use state-oriented styles here, static ones should go into the CSS
        this.style = {
            backgroundColor: 'rgba(0,0,0,0.0)',
            transition: 'background-color 300ms linear',
            willChange: 'transform',
            animationDuration: '200ms',
            animationTimingFunction: 'step-end',
        };

        // Set initial state
        this.state = {
            errorMessages: [],
            showing: false,
            hide: this.props.hide,
        };

        
    }

    componentDidMount() {
        this.mounted = true;
        
        this.setState({ showing: true });

        this.init();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillUpdate(nextProps, nextState) {
        console.log('componentWillUpdate: nextProps.showing='+nextProps.showing+' nextProps.hide='+nextProps.hide);
        /*
        if (nextProps.showing !== this.props.showing || nextProps.hide){
            this.setState({ showing: nextProps.showing, hide: nextProps.hide });
        }
        */
    }

    handleClick = () => {
        this.props.hideAnimation();
        this.setState({ hide: true });
        setTimeout(this.props.onClick, 300);
        //this.props.onClick();
    }

    // ---- Member Methods (Use arrow syntax to auto-bind) ----

    init = () => {
        
    }


    // ---- Render Methods ----

    render() {

        const style = {...this.style, ...this.props.innerStyle };
        if (this.props.hide || this.state.hide){
            style.backgroundColor = 'rgba(0,0,0,0.0)';
        } else if (this.state.showing){
            style.backgroundColor = 'rgba(0,0,0,0.5)';
        }

    	return (
    		<div ref={(ref) => this.myRef = ref} className='ModalBackdrop' style={style} onClick={this.handleClick}>
	    		
    		</div>
    	);
    }
}

export default ModalBackdrop;
