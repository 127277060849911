//This is just aholder for app state. More can be added to this later.
class AppStore {
    constructor(settings) {
        this.settings = settings;
        this.user = {};
        this.setUser = (user) => {
            this.user = user;
        }
    }
}
export default AppStore