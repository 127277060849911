/* eslint-disable global-require */

import siterentals_logo_long from '../images/logo_SiteServices.png';
import siterentals_logo_nav from '../images/logo_SiteServices.png';
import sitestays_icon from '../images/sitestaysicon_250x250.png';
import blank from '../images/blank.png';
import hero from '../images/forthills_header3.jpg';
import client_logo_nav from '../images/suncor_logo_nav.png';
import client_logo_login from '../images/suncor_logo_med.png';
import login_su from '../images/su.gif';
import shadow_bottom_inner from '../images/shadow_bottom_inner.png';
import glyphicon_truck from '../images/glyphicons-59-truck_70.svg';
import glyphicon_gas_station from '../images/glyphicons-472-gas-station_70.svg';
import glyphicon_cloud_upload from '../images/glyphicons-364-cloud-upload_70.svg';
import glyphicon_fuel_cards from '../images/icon_fuel_cards_80.svg';

import map_icon from '../images/map_icon.png';
import icon_assettracking from '../images/icon_assettracking_80.svg';
import icon_gas from '../images/icon_gas_80.svg';
import icon_lems from '../images/icon_lems.svg';
import icon_siteforecasting from '../images/icon_siteforecasting_80.svg';
import icon_siterentals from '../images/icon_dozer.svg'; //'../images/icon_siterentals_80.svg';
import icon_upload from '../images/icon_upload_80.svg';
import icon_gas_full from '../images/icon_gas_full_80.svg';
import icon_radio_full from '../images/icon_radio_full_80.svg';
import icon_radio from '../images/icon_radio_80.svg';
import icon_propane_full from '../images/icon_propane_full_80.svg';
import icon_propane from '../images/icon_propane_80.svg';
import icon_cog_full from '../images/icon_cog_full_80.svg';
import icon_cog from '../images/icon_cog_80.svg';
import icon_admin_full from '../images/icon_admin_full_80.svg';
import icon_admin from '../images/icon_admin_80.svg';
import icon_favorites_full from '../images/icon_favorites_full_80.svg';
import icon_favorites from '../images/icon_favorites_80.svg';
import icon_maintenancetables_full from '../images/icon_maintenancetables_full_80.svg';
import icon_maintenancetables from '../images/icon_maintenancetables_80.svg';
import icon_users_full from '../images/icon_users_full_80.svg';
import icon_users from '../images/icon_users_80.svg';
import icon_users2_full from '../images/icon_users2_full_80.svg';
import icon_users2 from '../images/icon_users2_80.svg';
import icon_workrequests_full from '../images/icon_workrequests2_full_80.svg';
import icon_workrequests from '../images/icon_workrequests4_80.svg';
import icon_workrequests_0 from '../images/icon_workrequests0_80.svg';
import icon_ems_v1 from '../images/icon_ems_scaffolding_insulation_80.svg';
import icon_ems_v15 from '../images/icon_ems_cranes_pv_generalequipment_80.svg';
import icon_sift from '../images/icon_sift_80.svg'
import icon_sift_full from '../images/icon_sift_full_80.svg'
import icon_dollar from '../images/glyphicons-basic-227-dollar_small.svg'
import icon_dollar_full from '../images/glyphicons-basic-227-dollar2.svg'
import icon_talkoot from '../images/icon_talkoot.svg'
import icon_talkoot_full from '../images/icon_talkoot.svg'
import icon_cranes from '../images/icon_crane.svg'
import icon_cranes_full from '../images/icon_crane.svg'
import icon_labour from '../images/icon_labour.svg'
import icon_labour_full from '../images/icon_labour.svg'
import icon_pv from '../images/icon_siterentals_80.svg'
//import icon_manpower from '../images/icon_manpower_80.svg'
//import icon_manpower_full from '../images/icon_manpower_full_80.svg'

import icon_plant from '../images/icon_ps_plant.svg';
import icon_plant_truck from '../images/icon_ps_plant_truck.svg';

import site_generic_full from '../images/icon_cog_full_80.svg';
import site_generic from '../images/icon_cog_80.svg';

import mytravel_logo from '../images/myTravel_logo_220w.png';
import sitestays_logo_login from '../images/sitestays_logo_long_180w.png';
import icon_mt_adf from '../images/icon_mt_adf.png';
import icon_mt_cog from '../images/icon_mt_cog.png';
import icon_mt_fifo from '../images/icon_mt_fifo.png';
import icon_mt_ldf from '../images/icon_mt_ldf.png';
import icon_mt_mytravel from '../images/icon_mt_mytravel.png';
import icon_mt_upload from '../images/icon_mt_upload.png';
//import icon_pv from '../images/icon_ems_pv.svg';
import icon_help from '../images/icon_ems_qrg_195.svg';
import icon_help150 from '../images/icon_ems_qrg_195-150.png';
import icon_bs from '../images/icon_bs.svg';
import icon_bs_full from '../images/icon_bs.svg';
import icon_tractor from '../images/icon_tractor.svg';
import icon_handshake from '../images/icon_handshake.svg';
import brookside_background_logo from '../images/brookside_background_logo.png';
import tractor2 from '../images/tractor-2.svg';
import wrenchgear from '../images/wrench-gear.svg';
import icon_management from '../images/icon_management.svg';
import profile from '../images/profile.svg';



export { 
  siterentals_logo_long,
  siterentals_logo_nav,
  sitestays_icon,
  blank,
  hero,
  client_logo_nav,
  client_logo_login,
  shadow_bottom_inner,
  login_su,
  glyphicon_truck,
  glyphicon_gas_station,
  glyphicon_cloud_upload,
  map_icon,
  icon_assettracking,
  icon_gas,
  icon_gas_full,
  icon_radio,
  icon_radio_full,
  icon_lems,
  icon_siteforecasting,
  icon_siterentals,
  icon_upload,
  icon_propane,
  icon_propane_full,
  icon_cog,
  icon_cog_full,
  icon_admin,
  icon_admin_full,
  icon_favorites,
  icon_favorites_full,
  icon_maintenancetables,
  icon_maintenancetables_full,
  icon_users,
  icon_users_full,
  icon_users2,
  icon_users2_full,
  site_generic,
  site_generic_full,
  icon_workrequests,
  icon_workrequests_full,
  icon_plant,
  icon_plant_truck,
  icon_workrequests_0,
  icon_ems_v1,
  icon_ems_v15,
  mytravel_logo,
  sitestays_logo_login,
  icon_mt_adf,
  icon_mt_cog,
  icon_mt_fifo,
  icon_mt_ldf,
  icon_mt_mytravel,
  icon_mt_upload,
  icon_sift,
  icon_sift_full,
  icon_pv,
  icon_dollar,
  icon_dollar_full,
  icon_help,
  icon_help150,
  icon_talkoot,
  icon_talkoot_full,
  icon_cranes,
  icon_cranes_full,
  icon_labour,
  icon_labour_full,
  icon_bs,
  icon_bs_full,
  icon_tractor,
  brookside_background_logo,
  tractor2,
  wrenchgear,
  icon_management,
  icon_handshake,
  profile,
 // icon_manpower,
 // icon_manpower_full,
}

const imageList = {
  'sitestays_logo_long': siterentals_logo_long,
  'sitestays_logo_nav': siterentals_logo_nav,
  'sitestays_icon': sitestays_icon,
  'blank': blank,
  'hero': hero,
  'client_logo_nav': client_logo_nav,
  'client_logo_login': client_logo_login,
  'shadow_bottom_inner': shadow_bottom_inner,
  'login_su': login_su,
  'glyphicon_truck': glyphicon_truck,
  'glyphicon_gas_station': glyphicon_gas_station,
  'glyphicon_cloud_upload': glyphicon_cloud_upload,
  'glyphicon_fuel_cards': glyphicon_fuel_cards,
  'map_icon': map_icon,
  'icon_assettracking': icon_assettracking,
  'icon_gas': icon_gas,
  'icon_gas_full': icon_gas_full,
  'icon_radio': icon_radio,
  'icon_radio_full': icon_radio_full,
  'icon_lems': icon_lems,
  'icon_siterentals': icon_siterentals,
  'icon_siteforecasting': icon_siteforecasting,
  'icon_upload': icon_upload,
  'icon_propane': icon_propane,
  'icon_propane_full' : icon_propane_full,
  'icon_cog': icon_cog,
  'icon_cog_full' : icon_cog_full,
  'icon_admin' : icon_admin,
  'icon_admin_full' : icon_admin_full,
  'icon_favorites' : icon_favorites,
  'icon_favorites_full' : icon_favorites_full,
  'icon_maintenancetables' : icon_maintenancetables,
  'icon_maintenancetables_full' : icon_maintenancetables_full,
  'icon_users' : icon_users,
  'icon_users_full' : icon_users_full,
  'icon_users2' : icon_users2,
  'icon_users2_full' : icon_users2_full,
  'site_generic' : site_generic,
  'site_generic_full' : site_generic_full,
  'icon_workrequests' : icon_workrequests,
  'icon_workrequests_full' : icon_workrequests_full,
  'icon_plant' : icon_plant,
  'icon_plant_truck' : icon_plant_truck,
  'icon_workrequests_0' : icon_workrequests_0,
  'icon_ems_v1' : icon_ems_v1,
  'icon_ems_v15' : icon_ems_v15,
  'mytravel_logo' : mytravel_logo,
  'sitestays_logo_login' : sitestays_logo_login,
  'icon_mt_adf' : icon_mt_adf,
  'icon_mt_cog' : icon_mt_cog,
  'icon_mt_fifo' : icon_mt_fifo,
  'icon_mt_ldf' : icon_mt_ldf,
  'icon_mt_mytravel' : icon_mt_mytravel,
  'icon_mt_upload' : icon_mt_upload,
  'icon_sift' : icon_sift,
  'icon_sift_full' : icon_sift_full,
  'icon_pv' : icon_pv,
  'icon_dollar' : icon_dollar,
  'icon_dollar_full' : icon_dollar_full,
  'icon_help' : icon_help,
  'icon_help150' : icon_help150,
  'icon_talkoot' : icon_talkoot,
  'icon_talkoot_full' : icon_talkoot_full,
  'icon_cranes' : icon_cranes,
  'icon_cranes_full' : icon_cranes_full,
  'icon_labour' : icon_labour,
  'icon_labour_full' : icon_labour_full,
  'icon_bs' : icon_bs,
  'icon_bs_full' : icon_bs_full,
  'icon_tractor' : icon_tractor,
  'brookside_background_logo' : brookside_background_logo,
  'tractor2': tractor2,
  'wrenchgear' : wrenchgear,
  'icon_management' : icon_management,
  'icon_handshake' : icon_handshake,
  'profile' : profile,
  //'icon_manpower' : icon_manpower,
  //'icon_manpower_full' : icon_manpower_full,
}

const images = {
  siterentals_logo_long: siterentals_logo_long,
  siterentals_logo_nav: siterentals_logo_nav,
  sitestays_icon: sitestays_icon,
  blank: blank,
  hero: hero,
  client_logo_nav: client_logo_nav,
  login_su: login_su,
  client_logo_login: client_logo_login,
  shadow_bottom_inner: shadow_bottom_inner,
  glyphicon_truck: glyphicon_truck,
  glyphicon_gas_station: glyphicon_gas_station,
  glyphicon_cloud_upload: glyphicon_cloud_upload,
  imageList: imageList,
  glyphicon_fuel_cards: glyphicon_fuel_cards,
  map_icon: map_icon,
  icon_assettracking: icon_assettracking,
  icon_gas: icon_gas,
  icon_gas_full: icon_gas_full,
  icon_radio: icon_radio,
  icon_radio_full: icon_radio_full,
  icon_lems: icon_lems,
  icon_siterentals: icon_siterentals,
  icon_siteforecasting: icon_siteforecasting,
  icon_upload: icon_upload,
  icon_propane: icon_propane,
  icon_propane_full : icon_propane_full,
  icon_cog: icon_cog,
  icon_cog_full : icon_cog_full,
  icon_admin : icon_admin,
  icon_admin_full : icon_admin_full,
  icon_favorites : icon_favorites,
  icon_favorites_full : icon_favorites_full,
  icon_maintenancetables : icon_maintenancetables,
  icon_maintenancetables_full : icon_maintenancetables_full,
  icon_users : icon_users,
  icon_users_full : icon_users_full,
  icon_users2 : icon_users2,
  icon_users2_full : icon_users2_full,
  site_generic : site_generic,
  site_generic_full : site_generic_full,
  icon_workrequests : icon_workrequests,
  icon_workrequests_full : icon_workrequests_full,
  icon_plant : icon_plant,
  icon_plant_truck : icon_plant_truck, 
  icon_workrequests_0 : icon_workrequests_0,
  icon_ems_v1 : icon_ems_v1,
  icon_ems_v15 : icon_ems_v15,
  mytravel_logo: mytravel_logo,
  sitestays_logo_login : sitestays_logo_login,
  icon_mt_adf : icon_mt_adf,
  icon_mt_cog : icon_mt_cog,
  icon_mt_fifo : icon_mt_fifo,
  icon_mt_ldf : icon_mt_ldf,
  icon_mt_mytravel : icon_mt_mytravel,
  icon_mt_upload : icon_mt_upload,
  icon_sift : icon_sift,
  icon_sift_full : icon_sift_full,
  icon_pv : icon_pv,
  icon_dollar : icon_dollar,
  icon_dollar_full : icon_dollar_full,
  icon_help: icon_help,
  icon_help150: icon_help150,
  icon_talkoot: icon_talkoot,
  icon_talkoot_full: icon_talkoot_full,
  icon_cranes: icon_cranes,
  icon_cranes_full: icon_cranes_full,
  icon_labour: icon_labour,
  icon_labour_full: icon_labour_full,
  icon_bs: icon_bs,
  icon_bs_full: icon_bs_full,
  icon_tractor : icon_tractor,
  tractor2 : tractor2,
  brookside_background_logo: brookside_background_logo,
  wrenchgear : wrenchgear,
  icon_management : icon_management,
  icon_handshake : icon_handshake,
  profile : profile,
  //icon_manpower : icon_manpower,
  //icon_manpower_full : icon_manpower_full,
};




export default images;
/*
import sitestays_logo_long from '../images/sitestays_only.png';
import sitestays_logo_nav from '../images/sitestays_only_small.png';
import sitestays_icon from '../images/sitestaysicon_250x250.png';
import blank from '../images/blank.png';
import hero from '../images/forthills_header3.jpg';
import suncor_logo_nav from '../images/suncor_logo_nav.png';
import suncor_logo_login from '../images/suncor_logo_med.png';
import shadow_bottom_inner from '../images/shadow_bottom_inner.png';

export {
  sitestays_logo_long,
  sitestays_logo_nav,
  sitestays_icon,
  blank,
  hero,
  suncor_logo_nav,
  suncor_logo_login,
  shadow_bottom_inner,
}
const imageList = {
  'sitestays_logo_long': sitestays_logo_long,
  'sitestays_logo_nav': sitestays_logo_nav,
  'sitestays_icon': sitestays_icon,
  'blank': blank,
  'hero': hero,
  'suncor_logo_nav': suncor_logo_nav,
  'suncor_logo_login': suncor_logo_login,
  'shadow_bottom_inner': shadow_bottom_inner,
}


const images = {
  sitestays_logo_long: sitestays_logo_long,
  sitestays_logo_nav: sitestays_logo_nav,
  sitestays_icon: sitestays_icon,
  blank: blank,
  hero: hero,
  shadow_bottom_inner: shadow_bottom_inner,
  imageList: imageList
};

export default images;
*/