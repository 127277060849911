
import React from 'react';
import './style.css';
import NavbarContainer from '../components/NavbarContainer';
//import ModalBackdrop from '../components/ModalBackdrop';
import Login from '../components/Login';
import ChangePassword from '../components/ChangePassword';
import { Glyphicon, Button, Alert } from 'react-bootstrap';
import { secret, cs } from '../config/data';
//import SecureLS from 'secure-ls';       // Beware Edge has an issue if we use compression, see: https://github.com/softvar/secure-ls/issues/2
import SecureStorage from 'secure-web-storage';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import API from '../lib/API';
import images from '../config/images';
import _ from 'lodash';
import Content from './Content';
import Utility from '../lib/Utility';
import EventCenter from '../lib/EventCenter';
import QRG from '../components/QRG'


moment().format();

class Main extends React.Component {

    // Set initial props
    static defaultProps = {
        webApplicationId: 106,      // This is set in App.js, so make sure you take care of that value.
    }

    constructor(props) {
        super(props);
        
        this.mounted = false;

        this.useLogoutEndpoint = false;         // If disabled, Logout will only wipe local cookies & storage, not call the logout endpoint (set to false if multiple people will use the same user account)
        this.useSessionTimeoutCheck = true;     // If "true", the user will be auto logged-out when their token expires
        this.autoForward = false;               // Will automatically redirect the user to a web application after log in if they only have one web application associated with their account.

        this.sessionTimeoutCheck = null;
        this.loginPressedTimestamp = moment().subtract(1, 'days');
        this.localStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, secret);

                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, secret);

                data = data.toString();

                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, secret);

                data = data.toString(CryptoJS.enc.Utf8);

                return data;
            }
        });

        EventCenter.subscribe('onUnauthorized', this.onUnauthorized);

        // Use state-oriented styles here, static ones should go into the CSS
        this.style = {

        };

        let user = this.getLocalUser();

        // Set initial state
        this.state = {
            isAuthenticated: false,
            changingPassword: false,
            passwordExpired: false,
            needToLoginWithPassword: false,
            errorMessage: '',
            loginError: '',
            user: user,
            statusMessages: [],
            applications: user && user.availableApplications ? user.availableApplications : [],
            loading: false,
            showQRG: false
        };
    }

    componentWillMount() {

        this.init();

    }

    componentDidMount() {
        this.mounted = true;
        EventCenter.subscribe('onPlantSelected', this.onPlantSelectedEvent);
        EventCenter.subscribe('handleChangeQRG', this.handleChangeQRG);

    }

    componentWillUnmount() {
        this.mounted = false;
        EventCenter.unsubscribe('handleChangeQRG');
        EventCenter.unsubscribe('onPlantSelected');

    }

    handleChangeQRG = ({ showQRG }) => {
        this.setState({ showQRG });
    }

    /*
    {
        "Id": 2,
        "Name": "2",
        "Url": "https://www.ODADevel.com/Suncor/Accommodations/Logon.aspx",
        "Role": "Admin"
    }, 
    */

    componentWillUpdate(nextProps, nextState) {

        let appArray = [];
        let { settings } = this.props.appStore;
        //Utility.info('nextState.user.availableApplications: '+JSON.stringify(nextState.user.availableApplications));
        //Utility.info('nextState.user.webApplications 1: '+JSON.stringify(nextState.user.webApplications));

        if (!nextState.user || !this.state.user) {
            return;
        }

        //Utility.log(nextState.user.webApplications);
        //Utility.log(this.state.user.webApplications);
        //Utility.log('-');
        if ((nextState.user.availableApplications.length === 0 || !_.isEqual(nextState.user.webApplications, this.state.user.webApplications)) && this.state.applications === nextState.applications) {
        //if (!_.isEqual(nextState.user.webApplications, this.state.user.webApplications)) {
            let webApplications = [...nextState.user.webApplications];

            for (let l = 0; l < webApplications.length; l++) {
                let app = webApplications[l];

                for (let m = 0; m < this.props.excludedApplications.length; m++) {
                    for (let n = 0; n < this.props.excludedApplications[m].id.length; n++) {
                        if (app.Id === this.props.excludedApplications[m].id[n]) {
                            webApplications.splice(l--, 1);
                            break;
                        }
                    }
                }
            }

            //Utility.info('nextState.user.webApplications 2: '+JSON.stringify(webApplications));
            let newApps = [];

            
            
            

            // Add supported web apps based on auth results
            for (let i = 0; i < webApplications.length; i++) {
                let app = { ...webApplications[i] };
                let foundApp = false;
                for (let j = 0; j < this.props.applications.length; j++) {
                    let supportedApp = this.props.applications[j];

                    //for (let k=0; k < supportedApp.id.length; k++){
                    // eslint-disable-next-line
                    if (supportedApp.id[settings.WEB_APPLICATION_ID_INDEX] === app.Id) {
                        app.id = supportedApp.id[settings.WEB_APPLICATION_ID_INDEX];
                        supportedApp.config.id = app.Id;
                        supportedApp.config.link = supportedApp.config.overrideUrl && supportedApp.config.overrideUrl[this.props.appStore.settings.ENV] ? supportedApp.config.overrideUrl[this.props.appStore.settings.ENV] : app.Url;
                        supportedApp.config.role = app.Role;
                        supportedApp.config.webApplications = app.webApplications ? app.webApplications : app.WebApplications ? app.WebApplications : [];

                        //Utility.log('supportedApp.config.webApplications: ');
                        //Utility.log(supportedApp.config.webApplications);
                        //Utility.log(supportedApp.config.webApplications && supportedApp.config.webApplications.length);
                        //Utility.log('app: ');
                        //Utility.log(app);

                        if (supportedApp.config.webApplications && supportedApp.config.webApplications.length){
                            let foundSubApp = false;

                            for (let z=0; z < supportedApp.config.webApplications.length; z++){
                                let swa = supportedApp.config.webApplications[z];
                                for (let m = 0; m < this.props.excludedApplications.length; m++) {
                                    for (let n = 0; n < this.props.excludedApplications[m].id.length; n++) {
                                        if (swa.Id === this.props.excludedApplications[m].id[n]) {
                                            supportedApp.config.webApplications.splice(z--, 1);
                                            break;
                                        }
                                    }
                                }
                            }

                            if (supportedApp.config.webApplications.length === 1){
                                // replace the app with the subApp since there's only one.
                                //Utility.log('only one');
                                let subApp = supportedApp.config.webApplications[0];

                                for (let l = 0; l < this.props.applications.length; l++) {
                                    let supportedSubApp = this.props.applications[l];

                                    Utility.log('subApp: ');
                                    Utility.log(subApp);
                                    Utility.log('supportedApp :');
                                    Utility.log(supportedSubApp);

                                    if (supportedSubApp.id[settings.WEB_APPLICATION_ID_INDEX] === subApp.Id){
                                        supportedSubApp.config.id = subApp.Id;
                                        supportedSubApp.config.link = supportedSubApp.config.overrideUrl && supportedSubApp.config.overrideUrl[this.props.appStore.settings.ENV] ? supportedSubApp.config.overrideUrl[this.props.appStore.settings.ENV] : subApp.Url;
                                        supportedSubApp.config.role = subApp.Role;
                                        supportedSubApp.config.webApplications = [];
                                        supportedSubApp.config.site = supportedApp.config.site;     // But keep the site rules in tact in case we're using multiple parent portal apps.
                                        supportedSubApp.config.link = supportedSubApp.config.overrideUrl && supportedSubApp.config.overrideUrl[this.props.appStore.settings.ENV] ? supportedSubApp.config.overrideUrl[this.props.appStore.settings.ENV] : subApp.Url;

                                        //Utility.log('**** swapping: ');
                                        //Utility.log(supportedSubApp);
                                        supportedApp = supportedSubApp;
                                        foundSubApp = true;
                                    }
                                }

                            } else {
                                for (let k=0; k < supportedApp.config.webApplications.length; k++){
                                    let subApp = supportedApp.config.webApplications[k];
                                    //Utility.log('subApp: ');
                                    //Utility.log(subApp);

                                    for (let l = 0; l < this.props.applications.length; l++) {
                                        let supportedSubApp = this.props.applications[l];
                                        
                                        Utility.log('supportedSubApp: ');
                                        Utility.log(supportedSubApp);

                                        if (supportedSubApp.id[settings.WEB_APPLICATION_ID_INDEX] === subApp.Id){
                                            Utility.log('FOUND subApp: ');
                                            Utility.log(supportedSubApp);
                                        
                                            subApp.icon = supportedSubApp.config.icon;
                                            subApp.icon_small = supportedSubApp.config.icon_small;
                                            subApp.name = supportedSubApp.config.name;
                                            subApp.site = supportedSubApp.config.site;
                                            subApp.important = supportedSubApp.config.important;
                                            subApp.link = supportedSubApp.config.overrideUrl && supportedSubApp.config.overrideUrl[this.props.appStore.settings.ENV] ? supportedSubApp.config.overrideUrl[this.props.appStore.settings.ENV] : subApp.Url;

                                            supportedApp.config.webApplications[k] = subApp;
                                            foundSubApp = true;
                                            //break;
                                        }
                                    }

                                    
                                }
                            }
                        }
                        Utility.log('pushing app: ');

                        app.config = { ...supportedApp.config };
                        if (app.config.roles && app.config.roles[app.config.role.toLowerCase()]){
                            if (app.config.roles[app.config.role.toLowerCase()].name){
                                app.config.name = app.config.roles[app.config.role.toLowerCase()].name;
                            }
                            if (app.config.roles[app.config.role.toLowerCase()].color){
                                app.config.color = app.config.roles[app.config.role.toLowerCase()].color;
                            }
                            if (app.config.roles[app.config.role.toLowerCase()].icon){
                                app.config.icon = app.config.roles[app.config.role.toLowerCase()].icon;
                            }
                            if (app.config.roles[app.config.role.toLowerCase()].icon_small){
                                app.config.icon_small = app.config.roles[app.config.role.toLowerCase()].icon_small;
                            }
                        }
                       
                        Utility.log(app);

                        appArray.push(_.cloneDeep(app));
                        foundApp = true;
                        //break;
                    }
                    //}
                }

                Utility.log('foundApp: '+foundApp);
                Utility.log(app);

                if (!foundApp){
                    // This app wasn't defined in our settings, so let's create a generic icon & name with the information we have

                    let newApp = { 
                        id: [app.Id, app.Id, app.Id],
                        Id: app.Id,
                        config: {
                                id: app.Id,
                                name: isNaN(app.Name) ? app.Name.toUpperCase() : this.getLastPartOfURL(app.Url).toUpperCase(),
                                link: app.Url,
                                role: app.Role,
                                icon: this.props.appStore.settings.SITE === 'SUNCOR' || this.props.appStore.settings.SITE === 'SYNCRUDE'  || this.props.appStore.settings.SITE === 'CNRL' ? 'images/icon_new_cog.svg' : images.site_generic,
                                icon_small: this.props.appStore.settings.SITE === 'SUNCOR' || this.props.appStore.settings.SITE === 'SYNCRUDE'  || this.props.appStore.settings.SITE === 'CNRL' ? 'images/icon_new_cog.svg' : images.site_generic_full,
                                color: this.props.appStore.settings.SITE === 'SUNCOR' || this.props.appStore.settings.SITE === 'SYNCRUDE'  || this.props.appStore.settings.SITE === 'CNRL' ? '#005596' : '#d9d9d9',
                                showBackground: true,
                                expandable: false,
                                webApplications: app.webApplications ? app.webApplications : app.WebApplications ? app.WebApplications : [],
                        },
                      };
                    app.webApplications = app.webApplications ? app.webApplications : app.WebApplications ? app.WebApplications : [];
                    Utility.log('app.webApplications: '+JSON.stringify(app.webApplications)); 

                    if (app.webApplications && app.webApplications.length){
                        let foundSubApp = false; 

                        // unknown app has subWebApplications
                        if (app.webApplications.length === 1){
                            // replace the app with the subApp since there's only one.
                            //Utility.log('only one');
                            let subApp = app.webApplications[0];

                            for (let l = 0; l < this.props.applications.length; l++) {
                                let supportedSubApp = this.props.applications[l];

                                Utility.log('subApp: ');
                                Utility.log(subApp);
                                Utility.log('supportedApp :');
                                Utility.log(supportedSubApp);

                                if (supportedSubApp.id[settings.WEB_APPLICATION_ID_INDEX] === subApp.Id){
                                    supportedSubApp.config.id = subApp.Id;
                                    supportedSubApp.config.link = supportedSubApp.config.overrideUrl && supportedSubApp.config.overrideUrl[this.props.appStore.settings.ENV] ? supportedSubApp.config.overrideUrl[this.props.appStore.settings.ENV] : subApp.Url;
                                    supportedSubApp.config.role = subApp.Role;
                                    supportedSubApp.config.webApplications = [];

                                    //Utility.log('**** swapping: ');
                                    //Utility.log(supportedSubApp);
                                    app = supportedSubApp;
                                    foundSubApp = true;
                                }
                            }

                            if (!foundSubApp){
                                // create a generic subapp icon
                                newApp.config.id = subApp.Id;
                                newApp.id = [subApp.Id, subApp.Id, subApp.Id];
                                newApp.Id = subApp.Id;
                                newApp.config.name = isNaN(subApp.Name) ? subApp.Name : this.getLastPartOfURL(subApp.Url).toUpperCase();
                                newApp.config.link = newApp.config.overrideUrl && newApp.config.overrideUrl[this.props.appStore.settings.ENV] ? newApp.config.overrideUrl[this.props.appStore.settings.ENV] : subApp.Url;
                                newApp.webApplications = subApp.webApplications;
                                newApp.name = newApp.config.name;
                            }

                        } else {
                            for (let k=0; k < app.webApplications.length; k++){
                                let subApp = app.webApplications[k];
                                //Utility.log('subApp: ');
                                //Utility.log(subApp);

                                for (let l = 0; l < this.props.applications.length; l++) {
                                    let supportedSubApp = this.props.applications[l];
                                    
                                    Utility.log('supportedSubApp: ');
                                    Utility.log(supportedSubApp);

                                    if (supportedSubApp.id[settings.WEB_APPLICATION_ID_INDEX] === subApp.Id){
                                        Utility.log('FOUND subApp: ');
                                        Utility.log(supportedSubApp);
                                    
                                        subApp.icon = supportedSubApp.config.icon;
                                        subApp.icon_small = supportedSubApp.config.icon_small;
                                        subApp.name = supportedSubApp.config.name;
                                        subApp.blockToken = supportedSubApp.config.blockToken;
                                        subApp.site = supportedSubApp.config.site;
                                        subApp.important = supportedSubApp.config.important;
                                        subApp.link = supportedSubApp.config.overrideUrl && supportedSubApp.config.overrideUrl[this.props.appStore.settings.ENV] ? supportedSubApp.config.overrideUrl[this.props.appStore.settings.ENV] : subApp.Url;
                                        subApp.Url = supportedSubApp.config.overrideUrl && supportedSubApp.config.overrideUrl[this.props.appStore.settings.ENV] ? supportedSubApp.config.overrideUrl[this.props.appStore.settings.ENV] : subApp.Url;

                                        newApp.config.webApplications[k] = subApp;
                                        app.webApplications[k] = subApp;

                                        foundSubApp = true;
                                        //break;
                                    }
                                }

                                if (!foundSubApp){
                                    // create a generic subapp icon
                                    
                                    let newSubApp = { 
                                        id: [subApp.Id, subApp.Id, subApp.Id],
                                        Id: subApp.Id,
                                        Url: subApp.Url,
                                        name: isNaN(subApp.Name) ? subApp.Name : this.getLastPartOfURL(subApp.Url).toUpperCase(),
                                        config: {
                                                id: subApp.Id,
                                                name: isNaN(subApp.Name) ? subApp.Name : this.getLastPartOfURL(subApp.Url).toUpperCase(),
                                                link: subApp.Url,
                                                role: subApp.Role,
                                                icon: 'images/icon_new_cog.svg',
                                                icon_small: images.site_generic_full,
                                                color: '#005596',
                                                showBackground: true,
                                                expandable: false,
                                                webApplications: subApp.webApplications,
                                        },
                                        };

                                    newApp.config.webApplications[k] = newSubApp;
                                    app.webApplications[k] = newSubApp;
                                }
                            }
                        }


                    }

                    newApps.push(newApp);
                }
            }

            // Add any fake apps (given ID < 0 in config)
            for (let i = 0; i < this.props.applications.length; i++){
                let app = this.props.applications[i];
                if (app.id[settings.WEB_APPLICATION_ID_INDEX] < 0){
                    app.Id = app.id[settings.WEB_APPLICATION_ID_INDEX];
                    app.config.link = app.config.overrideUrl && app.config.overrideUrl[this.props.appStore.settings.ENV] ? app.config.overrideUrl[this.props.appStore.settings.ENV] : app.Url;
                    appArray.push(app);
                }
            }

            // reorder as desired
            let orderedAppArray = [];


            //_.isEqual(appArray[j].config.site, this.props.applications[i].config.site)
            //appArray[j].site[0] === this.props.applications[i].site[0]

            for (let i = 0; i < this.props.applications.length; i++) {
                for (let j = 0; j < appArray.length; j++) {
                    // eslint-disable-next-line
                    if (appArray[j].Id == this.props.applications[i].id[settings.WEB_APPLICATION_ID_INDEX] && _.isEqual(appArray[j].config.site, this.props.applications[i].config.site)) {
                        if (appArray[j].config.link !== 'hide'){
                            orderedAppArray.push(appArray[j]);
                        }
                        //break;
                    }
                }
            }
            for (let i = 0; i < newApps.length; i++) {
                if (!appArray[i].config.link !== 'hide'){
                    orderedAppArray.push(newApps[i]);
                }
            }

            

            let helpApp = { 
                id: [1,1,1],
                Id: null,
                config: {
                        id: null,
                        site: [1,2,3,4],
                       "important": false,
                        name: this.props.appStore.settings.SITE === 'SUNCOR' || this.props.appStore.settings.SITE === 'SYNCRUDE'  || this.props.appStore.settings.SITE === 'CNRL' ? 'GUIDES' : 'Guides',
                        icon:  this.props.appStore.settings.MISC.guidesIcon ? this.props.appStore.settings.MISC.guidesIcon : images.icon_help,
                        link: (this.props.appStore.settings.MISC.hideStagingQRGs && this.props.appStore.settings.ENV === 'stage') ? 'exclude' : 'help',
                        role: null,
                        color: '#005596',
                        showBackground: true,
                        expandable: false,
                        webApplications: [],
                        
                },
              };

          
            if (this.props.appStore.settings.REFERENCE_GUIDES && this.props.appStore.settings.REFERENCE_GUIDES.length){
                orderedAppArray.push(helpApp);
            }
            
            nextState.user.availableApplications = [...orderedAppArray];
            Utility.info('appArray: '+JSON.stringify(orderedAppArray));

            if (settings.USECOOKIEVERSION === 1){
                this.localStorage.setItem('user' + settings.ENV, nextState.user);
            } else {
                this.localStorage.setItem('user.' + settings.TOKEN + '.' + settings.ENV, nextState.user);
            }
            this.setState({ applications: [ ...orderedAppArray] });

            if (this.autoForward && (moment().diff(this.loginPressedTimestamp, 'seconds') <= 2) && (orderedAppArray.length === 1)) {
                // there's only one app available to that user, so let's just navigate to it.
                //setTimeout(this.navigateToLink(orderedAppArray[0].config.link), 100);
                this.navigateToLink(orderedAppArray[0].config.link)
            }


        }
    }

    // ---- Member Methods (Use arrow syntax to auto-bind) ----

    init = (retries = 0) => {
        let a = this.easfq();
        if (a) {
            this.setState({ authenticatingWithToken: true });
            this.useSessionTimeoutCheck && this.sessionTimeoutCheck != null && window.clearInterval(this.sessionTimeoutCheck);
            this.resetStoredUser();
            
            let uandt = this.deas(a, cs);
            if (uandt.username && uandt.authToken) {
                let user = {}
                user.email = user.username = uandt.username;
                user.changePassword = false;
                user.role = '';
                user.odaKey = uandt.authToken;
                user.expiration = moment().add(120, 'minutes');
                user.webApplications = [];
                user.availableApplications = [];
                user.errorMessage = null;

                //this.loginUser(user);     // legacy call trusted the original token before checking with the server, let's not do that
                
                // ping the AuthorizeV2 endpoint to get the full set of user info before logging in.
                API.user = user;
                let params = { webApplicationId: this.props.webApplicationId, apiMethod: 'SSOLink' };

                //Utility.log('API.endpoints.authorize: '+API.endpoints.authorize);

                API.GET('AuthorizeV2', params, `${API.settings.API_HTTP_PROTOCOL}${API.settings.AUTH_DOMAIN}${API.settings.AUTH_BASE_PATH}`)
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson !== undefined && responseJson.odaKey !== undefined){
                            user.email = responseJson.email;
                            user.username = responseJson.username;
                            user.changePassword = responseJson.changePassword;
                            user.webApplicationID = responseJson.webApplicationID;
                            user.role = responseJson.role;
                            user.odaKey = responseJson.odaKey;
                            user.expiration = Date.parse(responseJson.expiration);
                            user.webApplications = responseJson.webApplications;
                            user.availableApplications = [];
                            user.errorMessage = responseJson.errorMessage;

                            user.profile = responseJson.profile;

                            this.loginUser(user);
                            //this.setUser(user);

                            window.history.replaceState({}, null, window.location.pathname.split('?a=')[0] + window.location.hash.split('?a=')[0]);
                        } else {
                            // No key was returned so let's assume the user isn't authorized with this key.
                            this.logoutUser();
                        }
                        
                    })
                    .catch((error) => {
                        Utility.error(error);
                        
                        if (retries++ === 0){
                            this.init(retries);
                        }

                    });
            }
        }
        if (this.checkForUserAndAuthToken(true) && this.useSessionTimeoutCheck) {
            this.sessionTimeoutCheck != null && window.clearInterval(this.sessionTimeoutCheck);
            this.sessionTimeoutCheck = setInterval(this.sessionTimeoutCheckHandler, 10000);
        }
    }

    /*
    init = () => {
        let a = this.easfq();
        if (a) {
            let uandt = this.deas(a, cs);
            if (uandt.username && uandt.authToken) {     // *** We really should be hitting an endpoint for this information
                let user = {}
                user.email = user.username = uandt.username;
                user.changePassword = false;
                user.role = '';
                user.odaKey = uandt.authToken;
                user.expiration = moment().add(120, 'minutes');
                user.webApplications = [];
                user.availableApplications = [];
                user.errorMessage = null;
                this.loginUser(user);
            }
        }
        if (this.checkForUserAndAuthToken(true) && this.useSessionTimeoutCheck) {
            this.sessionTimeoutCheck != null && window.clearInterval(this.sessionTimeoutCheck);
            this.sessionTimeoutCheck = setInterval(this.sessionTimeoutCheckHandler, 10000);
        }
    }
    */

    handleError = (error) => {
        this.setState({ errorMessage: error });
    }

    // -- Authentication methods

    getLocalUser = () => {
        let localUser = null;
        let { settings } = this.props.appStore;
        if (this.props.appStore.settings.USECOOKIEVERSION === 1){
            localUser = this.localStorage.getItem('user' + settings.ENV);
        } else {
            localUser = this.localStorage.getItem('user.' + this.props.appStore.settings.TOKEN + '.' + this.props.appStore.settings.ENV);
        }
        return !localUser ? null : localUser;
    }

    onPlantSelectedEvent = (plant) => {
        let user = this.state.user;
        if (user){
            user.selectedPlant = plant;
            this.setUser(user, false);
        }
    }

    checkForUserAndAuthToken = (update, calledFromTimeoutHandler) => {
        let user = this.getLocalUser();
        let updatedState = update ? 'State was updated.' : 'State was not updated.';
        if (!user || user.odaKey === null || user.odaKey === undefined || user.odaKey === '') {
            if (update || calledFromTimeoutHandler)
                this.logoutUser();

            Utility.info('checkForUserAndAuthToken: User does not exist in localStorage. ' + updatedState);
            return false;
        }

        //Utility.log('now: '+moment());
        //Utility.log('exp: '+user.expiration);
        //Utility.log(moment().isAfter(user.expiration));
        if (moment().isAfter(user.expiration)) {
            if (update || calledFromTimeoutHandler){
                // First call /AuthorizeV2 to see if the session timeout date has changed

                let params = { webApplicationId: this.props.webApplicationId, apiMethod: 'SessionTimeoutCheck' };

                API.GET(API.endpoints.authorize, params,`${API.settings.API_HTTP_PROTOCOL}${API.settings.AUTH_DOMAIN}${API.settings.AUTH_BASE_PATH}`)
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson !== undefined && responseJson.odaKey !== undefined){
                            user.email = responseJson.email;
                            user.username = responseJson.username;
                            user.changePassword = responseJson.changePassword;
                            user.webApplicationID = responseJson.webApplicationID;
                            user.role = responseJson.role;
                            user.odaKey = responseJson.odaKey;
                            user.expiration = Date.parse(responseJson.expiration);
                            user.webApplications = responseJson.webApplications;
                            user.availableApplications = [];
                            user.errorMessage = responseJson.errorMessage;
                
                            user.profile = responseJson.profile;

                        } 
                        if (user.odaKey){
                            this.setUser(user, false);
                        }

                        if (moment().isAfter(user.expiration)) {
                            this.logoutUser();
                        } else {
                            this.setState({ user });
                        }
                        
                    })
                    .catch((error) => {
                        Utility.error(error);
                        
                        // We're truly expired
                        this.logoutUser();

                    });

                
            }

            Utility.info('checkForUserAndAuthToken: User token has expired. Logging user out. ' + updatedState);
            return false;

        } else {
            // check to see if the cookie we got is from a different webApplication than this one, and check to see if this site is an SSO site, if so, hit AuthorizeV2 to get updated info for user.
            let needToCheckForUpdatedInformation = false;

            if (user.odaKey){
                this.setUser(user, false);
            }

            //Utility.log('--------');
            //Utility.log('update: '+update+' user.webApplicationID: '+user.webApplicationID+' this.props.appStore.settings.WEB_APPLICATION_ID: '+this.props.appStore.settings.WEB_APPLICATION_ID);

            if (update && user.webApplicationID !== this.props.appStore.settings.WEB_APPLICATION_ID){
                needToCheckForUpdatedInformation = true;

                user.webApplications = [];
                this.setState({ user });

                if (this.props.appStore.settings.SITE_USES_SUBWEBAPPLICATIONS){

                    let params = { webApplicationId: this.props.webApplicationId, apiMethod: 'SSO' };

                    API.GET(API.endpoints.authorize, params, `${API.settings.API_HTTP_PROTOCOL}${API.settings.AUTH_DOMAIN}${API.settings.AUTH_BASE_PATH}`)
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson !== undefined && responseJson.odaKey !== undefined){
                            user.email = responseJson.email;
                            user.username = responseJson.username;
                            user.changePassword = responseJson.changePassword;
                            user.webApplicationID = responseJson.webApplicationID;
                            user.role = responseJson.role;
                            user.odaKey = responseJson.odaKey;
                            user.expiration = Date.parse(responseJson.expiration);
                            user.webApplications = responseJson.webApplications;
                            user.availableApplications = [];
                            user.errorMessage = responseJson.errorMessage;

                            user.profile = responseJson.profile;

                        } 
                        if (user.odaKey){
                            this.setUser(user, false);
                        }
                        if (update){
                            this.setState({ user });
                        }
                        

                    })
                    .catch((error) => {
                        Utility.error(error);
                        
                        // This failed somehow so let's revert back to the original functionality of this method.
                        if (update){
                            this.setState({ user });
                        }

                    });

                }
            }

            
            if (update && !needToCheckForUpdatedInformation)
                this.setState({ user });

            Utility.info('checkForUserAndAuthToken: User exists in localStorage. ' + updatedState);
            //Utility.log(user);
            if (user.changePassword) {
                return false;
            } else {
                return true;
            }
        }
    }

    // Converts query string into encrypted auth token
    easfq = () => {
        var hash = window.location.hash;
        var query = window.location.search.substring(1);
        var vars = query.split('&');

        for (let i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === 'a') {
                return decodeURIComponent(pair[1]);
            }
        }
        let index = hash.indexOf('?a=',0);
        
        if (index !== -1){

            query = hash.substr(index + 1, hash.length - (index + 1));
            vars = query.split('&');
            for (let i = 0; i < vars.length; i++) {
                pair = vars[i].split('=');
                if (decodeURIComponent(pair[0]) === 'a') {
                    return decodeURIComponent(pair[1]);
                }
            }
        }
    }

    // Takes the encrypted auth string and the shared secret (cs) and returns the username and auth token 
    deas = (authString, cs) => {
        var key = CryptoJS.enc.Utf8.parse(cs);
        var iv = CryptoJS.enc.Utf8.parse(cs);
        var cfg = {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        };
        var decrypted = CryptoJS.AES.decrypt(unescape(authString), key, cfg).toString(CryptoJS.enc.Utf8);
        var delimiterindex = decrypted.indexOf(')', 0);
        var authToken = decrypted.substr(0, delimiterindex);
        var username = decrypted.substr(delimiterindex + 1, (decrypted.length - (delimiterindex + 1)));
        return { username: username, authToken: authToken };
    }

    resetStoredUser = (refreshing = false) => {
        let { settings } = this.props.appStore;
        if (settings.USECOOKIEVERSION === 1){
            this.localStorage.removeItem('user' + settings.ENV);
        } else {
            this.localStorage.removeItem('user.' + this.props.appStore.settings.TOKEN + '.' + this.props.appStore.settings.ENV);
        }
        this.clearCookie();
        if (!refreshing){
            this.setState({ user: null });
        }
    }

    onUnauthorized = ({ response }) => {
        this.logoutUser();
    }

    logoutUser = (refresh = false) => {
        this.useSessionTimeoutCheck && this.sessionTimeoutCheck != null && window.clearInterval(this.sessionTimeoutCheck);

        let params = { webApplicationId: this.props.webApplicationId };

        if (this.useLogoutEndpoint) {
            API.GET(API.endpoints.logout, params)
                .then((response) => response.json())
                .then((responseJson) => {
                    if (this.mounted) {
                        this.setState({ loading: false });
                    }
                    Utility.info('Main.logoutUser: User logged out.');
                    this.resetStoredUser(refresh);
                    if (refresh){
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    Utility.error(error);
                    /*
                                user.errorMessage = 'A network error occurred, please check your internet connectivity.';
                                this.handleError(user.errorMessage);
                                */
                    if (this.mounted) {
                        this.setState({ loading: false });
                    }
                    Utility.info('Main.logoutUser: User logged out.');
                    this.resetStoredUser(refresh);
                    if (refresh){
                        window.location.reload();
                    }
                });
        } else {
            Utility.info('Main.logoutUser: User logged out.');
            this.resetStoredUser(refresh);
            if (refresh){
                window.location.reload();
            }
        }

        // Log user out locally even if the server call fails
        //this.setState({ authenticatingWithToken: false });

    }

    loginUser = (user) => {
        this.loginPressedTimestamp = moment();
        this.setUser(user, true);
        this.setState({ needToLoginWithPassword: false, user }, () => {
            if (this.checkForUserAndAuthToken(true, false) && this.useSessionTimeoutCheck) {
                this.sessionTimeoutCheck != null && window.clearInterval(this.sessionTimeoutCheck);
                this.sessionTimeoutCheck = setInterval(this.sessionTimeoutCheckHandler, 10000);
            }
            Utility.info('Main.loginUser: User logged in.');
        });
        EventCenter.broadcast('onUserLoggedIn', {user: user});
    }

    setUser = (user, setState = true) => {
        let { settings } = this.props.appStore;
        API.user = user;
        this.props.appStore.setUser(user);
        if (settings.USECOOKIEVERSION === 1){
            this.localStorage.setItem('user' + settings.ENV, user);
        } else {
            this.localStorage.setItem('user.' + settings.TOKEN + '.' + settings.ENV, user);
        }
        if (setState){
            this.setState({ user });
        }
    }

    sessionTimeoutCheckHandler = () => {
        this.checkForUserAndAuthToken(false, true);
    }

    handleWillChangePassword = (passwordExpired = false) => {
        this.setState({ changingPassword: true, passwordExpired: passwordExpired });
    }

    handleCancelledChangePassword = () => {
        this.setState({ changingPassword: false });
    }

    handlePasswordChanged = (passwordExpired, newPassword) => {
        this.setState({ changingPassword: false, needToLoginWithPassword: passwordExpired ? newPassword : newPassword, passwordExpired: false });
    }

    // --- Utility Methods ---

    getLastPartOfURL = (url) => {
        url = url.replace(/\/+$/, "")
        if (url) {
            let urlArray = url.split('/');
            let endOfURL = urlArray[urlArray.length - 1];
            let nameArray = endOfURL.split('.');
            let endOfNames = nameArray[nameArray.length - 1];
            return endOfNames;
        } else {
            return '';
        }
    }

    clearCookie = () => {
        let { settings } = this.props.appStore;
        var someday = new Date().setFullYear(200, 0, 14);
        this.setCookie(settings.TOKEN, "", someday);
    }


    setCookie = (cname, cvalue, date) => {
        var d = new Date(date);
        //d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    createMotd = () => {
        //return {__html: "<B>Planned outage:</B> Access to these systems will be removed at 12:01 a.m. on November 29, 2019 in preparation for the implementation of the new system. On December 1, start using iLogistics (continue to use current systems for Syncrude bookings on Dec 1 if you also book for Syncrude). Any urgent changes must be sent to <a href='mailto:mytravel@suncor.com'>Suncor Travel Services</a> during this time."}
        
        //if (typeof(this.props.appStore.settings.SHOWMOTD) === 'object'){
            //let start = moment(this.props.appStore.settings.SHOWMOTD.start);
            //let end = moment(this.props.appStore.settings.SHOWMOTD.end);
            //if (moment().isBetween(start,end, 'days', '[]')){
                return {__html: this.props.appStore.settings.SHOWMOTD.msg};
            //} else {
            //    return null;
           // }
            /*
        } else {
            return {__html: this.props.appStore.settings.SHOWMOTD};

        }
        */
    }



    // ---- Render Methods ----

    render() {
        const style = { ...this.style, ...this.props.innerStyle };
        let { settings } = this.props.appStore;
        Utility.log(this.props.webApplicationId)

        return (
            <div ref={(ref) => this.myRef = ref} className='Main' style={style}>

                <div className='container-fluid'>
                    <NavbarContainer
                        applicationName={images.imageList[settings.APP_LOGO_NAV] ? images.imageList[settings.APP_LOGO_NAV] : settings.APP_LOGO_NAV}
                        clientLogo={images.imageList[settings.CLIENT_LOGO_NAV] ? images.imageList[settings.CLIENT_LOGO_NAV] : settings.CLIENT_LOGO_NAV}
                        appStore={this.props.appStore}
                        user={this.state.user} logoutClicked={() => this.logoutUser(true)}
                        changePasswordClicked={this.handleWillChangePassword}
                        env={this.props.env}
                        savedPlant={settings.SITES.length === 1 ? ({ selectedPlant: settings.SITES[0] }) : (this.state.user ? this.state.user.selectedPlant : null)}
                    />


                    {this.state.user && <div style={{ marginTop: '10px' }}><Content env={this.props.env} appStore={this.props.appStore} applications={this.state.applications} location={this.state.location} user={this.state.user} savedPlant={settings.SITES.length === 1 ? ({ selectedPlant: settings.SITES[0] }) : (this.state.user && this.state.user.selectedPlant ? this.state.user.selectedPlant : null)} /></div>}

                    {this.props.children}

                    {this.props.appStore.settings.SHOWMOTD ? <div className="motd" dangerouslySetInnerHTML={this.createMotd()} /> : null}

                    

                    {(this.state.errorMessage !== '') ?
                        <Alert bsStyle="danger">
                            {this.state.errorMessage}
                        </Alert> : null
                    }

                    <ChangePassword
                        location={this.props.location}
                        appStore={this.props.appStore}
                        changingPassword={this.state.changingPassword}
                        passwordExpired={this.state.passwordExpired}
                        userChangedPassword={this.handlePasswordChanged}
                        userCancelledChangePassword={this.handleCancelledChangePassword}
                        webApplicationId={this.props.webApplicationId}
                        user={this.state.user}
                    />

                    <Login location={this.props.location}
                        appStore={this.props.appStore}
                        env={this.props.env}
                        isAuthenticated={this.checkForUserAndAuthToken(false, false)}
                        loginError={this.state.loginError}
                        userLoggedIn={this.loginUser}
                        webApplicationId={this.props.webApplicationId}
                        changePasswordCallback={this.handleWillChangePassword}
                        needToLoginWithPassword={this.state.needToLoginWithPassword}
                        localStorage={this.localStorage}
                        resetStoredUser={this.resetStoredUser}
                        savedPlant={settings.SITES.length === 1 ? ({ selectedPlant: settings.SITES[0] }) : (this.state.user && this.state.user.selectedPlant ? this.state.user.selectedPlant : null)}
                    />

                    <QRG
                        location={this.props.location}
                        appStore={this.props.appStore}
                        showQRG={this.state.showQRG}
                        closeHelp={() => this.handleChangeQRG({ showQRG: false })}
                        passwordExpired={this.state.passwordExpired}
                        webApplicationId={this.props.webApplicationId}
                        user={this.state.user}
                        reference_guides={settings.REFERENCE_GUIDES}
                    />
                </div>
            </div>
        );
    }
}

export default Main;


// legacy cookie JS below for reference

    /*
    checkSessionTimeout = () => {
        if (this.state.isAuthenticated) {
            var cookie = this.getCookie("oda.readyreports.cookie");
            if (cookie.trim() === "")
                this.sessionTimeout();
        }
    }

    sessionTimeout = () => {
        this.logoutUser();
        this.setState({ loginError: "Session Timeout!" });
    }

    userLoggedIn = () => {
        if (this.checkCookie()) {
            this.canChangePassword();
        }
    }

    getCookie = (cname) => {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setCookie = (cname, cvalue, date) => {
        var d = new Date(date);
        //d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    checkCookie = () => {
        var cookie = this.getCookie("oda.readyreports.cookie");
        if (cookie.trim() === "") {
            this.logoutUser();
            return false;
        }
        this.setState({ isAuthenticated: true, user: user });
        return true;
    }

    logoutUser = () => {
        var someday = new Date().setFullYear(200, 0, 14);
        this.setCookie("oda.readyreports.cookie", "", someday);
        this.setState({ isAuthenticated: false, loginError: '', errorMessages: [], user: emptyUser });
    }
    

    canChangePassword = () => {
        if (!this.checkCookie())
            return;

        /*
        var spoiler = new Date().getMilliseconds();//this forces IE to stop caching.
        var url = `${this.props.location}Login/ValidateClaim/?claim=ChangePassword&Spoiler=${spoiler}`;
        var xhr = new XMLHttpRequest();
        var errorMessage = "";
        xhr.open('get', url, true);
        xhr.onload = function () {
            var response = JSON.parse(xhr.responseText);
            if (response.ErrorMessage !== "") {
                if (response.ErrorMessage === "Invalid Token!")
                    this.sessionTimeout();
                else
                    errorMessage = response.ErrorMessage;
            }
            else {
                this.setState({ canChangePassword: response.Response, errorMessage: response.ErrorMessage });
            }
            //Call this every time to set or clear.
            this.updateErrors({ name: "CanChangePassword", message: errorMessage });
        }.bind(this);
        xhr.send();
        */
    //}


    // -- 
