// DashItem is the basic subcomponent of a DashCollection, allowing for the display of basic info and the expansion into a DashItemModal

import React from 'react';
import { Glyphicon, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { cs } from '../../../config/data';
//import { AES } from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
//import API from '../../../lib/API';
import Utility from '../../../lib/Utility';
import images from '../../../config/images';
import EventCenter from '../../../lib/EventCenter';
import QRG from '../../../components/QRG';
import _ from 'lodash';
import './style.css';

class DashItem extends React.Component {

	// Set initial props
    static defaultProps = {
        xOffset: 0,
        yOffset: 0,
        useEncryption: true,
    }

    constructor(props) {
        super(props);

        this.mounted = false;

        this.expandedWidth = 800;
        this.expandedHeight = 500;
        this.collapsedWidth = 256;
        this.collapsedHeight = 256;

        /*
        position: absolute;
      opacity: 0;
      pointer-events: none;
      transform-origin: top left;
      overflow: hidden;
      contain: content;
      border-radius: 3px;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
      background: #FFF;
      will-change: transform;
      animation-duration: 200ms;
      animation-timing-function: step-end;
        */
       this.state = {
        showQRG: false,
        closeModal: false
       };

        
        this.style = {
            position: 'relative',
            overflow: 'hidden',
            contain: 'content',
            //boxShadow: '0 4px 5px rgba(0, 0, 0, 0.2)',
            willChange: 'transform',
            animationDuration: '200ms',
            animationTimingFunction: 'step-end',
            display: 'absolute',
            textAlign: 'center',
            padding: '20px',
            margin: '20px',
            width: this.collapsedWidth+'px',
            height: this.collapsedHeight+'px',
            icon: this.props.config.icon,
            backgroundColor: this.props.config.color,
            backgroundRepeat: 'no-repeat',
            transition: '0.2s transform ease',
            WebkitTransition: '-webkit-transform 0.2s ease',
            borderRadius: this.props.appStore.settings.MISC.roundedCorners ? 20 : 0,
            backgroundSize: 'contain',
            
        }

        Utility.log('DashItem: ');
        Utility.log(this.props.config);
        Utility.log(this.props.config.icon && (!this.props.config.webApplications || this.props.config.webApplications.length === 0));

        if (this.props.config.icon && (!this.props.config.webApplications || this.props.config.webApplications.length === 0)){
            this.style.backgroundImage = 'url('+this.props.config.icon+')';
            //this.style.backgroundColor = 'rgba(0,0,0,0.8)';
            //this.style.backgroundBlendMode = 'darken';
            //this.style.backgroundImage = 'linear-gradient(rgba(255,255,255,0.7),rgba(255,255,255,0.7)), url('+this.props.config.icon+')';
        }

        if (!this.props.config.webApplications || this.props.config.webApplications.length === 0){
            this.style.cursor = 'pointer';
        }

        if (this.props.config.showBackground){
            this.style.boxShadow = '0 4px 5px rgba(0, 0, 0, 0.2)'
            this.expandIconOffset = '30px';
        } else {
             this.style.backgroundColor = 'rgba(0,0,0,0)' 
            this.expandIconOffset = '48px';
        }

        

        this.placeholderStyles = {
            position: 'fixed',
            top: 0,
            left: '50%',
            display: 'absolute',
            textAlign: 'center',
            padding: '20px',
            margin: '20px',
            width: this.expandedWidth+'px',
            height: this.expandedHeight+'px',
            marginTop: -this.expandedHeight/2,
            marginLeft: -this.expandedWidth/2,
            backgroundColor: this.props.config.color,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            transition: '0.5s transform ease',
            WebkitTransition: '-webkit-transform 0.2s ease',
            opacity: 1.0,
            visibility: 'visible',
        };

        if (this.props.placeholder){
            this.style = {...this.style, ...this.placeholderStyles};
        }

        this.contentStyle = {
            willChange: 'transform',
            contain: 'content',
            animationDuration: '200ms',
            animationTimingFunction: 'step-end',
        }

        this.subWebApplicationStyles = [
            [], // 0 subWebApplications
            [{ left: 220, top: 41}], // 1 subWebApplication
            [{ left: 31, top: 30, width: 92, height: 92 }, { left: 133, top: 30, width: 92, height: 92 }], // 2 subWebApplications
            [{ left: 10, top: 41, width: 72, height: 72 }, { left: 92, top: 41, width: 72, height: 72 }, { left: 174, top: 41, width: 72, height: 72 }], // 3 subWebApplications
            [{ left: 51, top: 0, width: 72, height: 72 }, { left: 133, top: 0, width: 72, height: 72 }, { left: 51, top: 82, width: 72, height: 72 }, { left: 133, top: 82, width: 72, height: 72 }], // 4 subWebApplications
            [{ left: 10, top: 0, width: 72, height: 72 }, { left: 92, top: 0, width: 72, height: 72 }, { left: 174, top: 0, width: 72, height: 72 }, { left: 51, top: 82, width: 72, height: 72 }, { left: 133, top: 82, width: 72, height: 72 }], // 5 subWebApplications
            [{ left: 10, top: 0, width: 72, height: 72 }, { left: 92, top: 0, width: 72, height: 72 }, { left: 174, top: 0, width: 72, height: 72 }, { left: 10, top: 82, width: 72, height: 72 }, { left: 82, top: 82, width: 72, height: 72 }, { left: 164, top: 82, width: 72, height: 72 }], // 6 subWebApplications
        ];

        /*
        this.subWebApplicationStyles = [
            [], // 0 subWebApplications
            [{ left: 220, top: 41}], // 1 subWebApplication
            [{ left: 10, top: 20, width: 113, height: 113 }, { left: 133, top: 20, width: 113, height: 113 }], // 2 subWebApplications
            [{ left: 10, top: 41, width: 72, height: 72 }, { left: 82, top: 41, width: 72, height: 72 }, { left: 164, top: 41, width: 72, height: 72 }], // 3 subWebApplications
            [{ left: 51, top: 0}, { left: 133, top: 0}, { left: 51, top: 82}, { left: 133, top: 82}], // 4 subWebApplications
            [{ left: 10, top: 0, width: 72, height: 72 }, { left: 82, top: 0, width: 72, height: 72 }, { left: 164, top: 0, width: 72, height: 72 }, { left: 51, top: 82, width: 72, height: 72 }, { left: 133, top: 82, width: 72, height: 72 }], // 5 subWebApplications
            [{ left: 10, top: 0, width: 72, height: 72 }, { left: 82, top: 0, width: 72, height: 72 }, { left: 164, top: 0, width: 72, height: 72 }, { left: 10, top: 82, width: 72, height: 72 }, { left: 82, top: 82, width: 72, height: 72 }, { left: 164, top: 82, width: 72, height: 72 }], // 6 subWebApplications
        ];
        */

        /*
        this.subWebApplicationStyles = [
            [], // 0 subWebApplications
            [{ left: 220, top: 41}], // 1 subWebApplication
            [{ left: 51, top: 41}, { left: 133, top: 41}], // 2 subWebApplications
            [{ left: 10, top: 41}, { left: 82, top: 41}, { left: 164, top: 41}], // 3 subWebApplications
            [{ left: 51, top: 0}, { left: 133, top: 0}, { left: 51, top: 82}, { left: 133, top: 82}], // 4 subWebApplications
            [{ left: 10, top: 0}, { left: 82, top: 0}, { left: 164, top: 0}, { left: 51, top: 82}, { left: 133, top: 82}], // 5 subWebApplications
            [{ left: 10, top: 0, width: 72, height: 72 }, { left: 82, top: 0}, { left: 164, top: 0}, { left: 10, top: 82}, { left: 82, top: 82}, { left: 164, top: 82}], // 6 subWebApplications
        ];
        */

        // Set initial state
        this.state = {
            isAuthenticated: false,
            canChangePassword: false,
            errorMessages: [],
            loginError: '',
            statusMessages: [],
            expanded: this.props.config.expanded,
        };
    }

    componentDidMount() {
        this.mounted = true;

        this.updateRect();
        

        this.init();
    }

    componentWillUnmount() {

        this.mounted = false;
    }

    componentDidUpdate() {
        this.updateRect();
    }


    // ---- Member Methods (Use arrow syntax to auto-bind) ----

    init = () => {
        /*
        if (this.props.config.id == 2 || this.props.config.id[0] == 2){
            this.generateTestURLs();
        }
        */
       
    }

    handleClick = () => {
        Utility.log('click');
        if (this.props.config.link && this.props.config.link !== '' && (!this.props.config.webApplications || this.props.config.webApplications.length === 0)){
            let link = this.props.config.link;
            let checkLink = this.props.config.link;

            if (link === 'exclude') return null;

            //console.log('this.props.user.odaKey='+this.props.user.odaKey);
            //console.log('this.props.user.username='+this.props.user.username);
            if (this.props.user.odaKey !== ''){

                let delimiter = '?';
                if (link.indexOf('?',0) !== -1){
                    delimiter = '&';
                }
                if (!this.props.useEncryption){
                    link += delimiter + 'a=' + this.fixedEncodeURIComponent(this.props.user.odaKey) + '&u=' + this.fixedEncodeURIComponent(this.props.user.username);
                    link += '&rnd=' + Math.floor(Math.random() * 10000000);
                } else {
                    //console.log('unencrypted u='+this.props.user.username+'   a='+this.props.user.odaKey);
                    //var salt = randombytes(16);
                    //var key = CryptoJS.PBKDF2(csecret, CryptoJS.enc.Utf8.parse(salt), { keySize: 512/32, iterations: 1000 });

                    var key = CryptoJS.enc.Utf8.parse(cs);  // '7061737323313233' '7362906312841932'
                    var iv = CryptoJS.enc.Utf8.parse(cs);
                    var cfg = {
                            keySize: 128 / 8,
                            iv: iv,
                            mode: CryptoJS.mode.CBC,
                            padding: CryptoJS.pad.Pkcs7
                        };
                    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(this.props.user.odaKey + ')' + this.props.user.username), key, cfg);

                    var encstr = this.fixedEncodeURIComponent(encrypted.toString());

                    /*
                    var decrypted = CryptoJS.AES.decrypt(unescape(encstr), key, cfg).toString(CryptoJS.enc.Utf8);
                    var delimiterindex = decrypted.indexOf(')',0);
                    var authToken = decrypted.substr(0,delimiterindex);
                    var username = decrypted.substr(delimiterindex + 1,(decrypted.length - (delimiterindex + 1)));
                    alert('u: '+username+', a: '+authToken+'  full:'+decrypted);
                    */

                    /*
                    alert(encrypted); // U2FsdGVkX1+iX5Ey7GqLND5UFUoV0b7rUJ2eEvHkYqA=
                    alert(encrypted.key); // 74eb593087a982e2a6f5dded54ecd96d1fd0f3d44a58728cdcd40c55227522223
                    alert(encrypted.iv); // 7781157e2629b094f0e3dd48c4d786115
                    alert(encrypted.salt); // 7a25f9132ec6a8b34
                    alert(encrypted.ciphertext); // 73e54154a15d1beeb509d9e12f1e462a0
                    */
                    
                    if (!this.props.config.blockToken){
                        link += delimiter + 'a=' + encstr;
                        link += '&rnd=' + Math.floor(Math.random() * 10000000);
                    } else {
                        link += delimiter + 'rnd=' + Math.floor(Math.random() * 10000000);
                    }

                }

            }
            if (checkLink === "help"){
                //this.handleChangeQRG();
                EventCenter.broadcast('handleChangeQRG', {showQRG: true });
            }
            else {
                window.open(link, "_blank");
            }
        }




        /*
        if (this.props.config.link){
            API.openTabWithAuthHeader(this.props.config.link);
        }
        */

    }

    handleSubWebAppClick = (config) => {
        console.log(config);
        if (config.link){
            let link = config.link;

            if (link === 'exclude') return null;

            if (this.props.user.odaKey !== ''){

                let delimiter = '?';
                if (link.indexOf('?',0) !== -1){
                    delimiter = '&';
                }
                if (!this.props.useEncryption){
                    link += delimiter + 'a=' + this.fixedEncodeURIComponent(this.props.user.odaKey) + '&u=' + this.fixedEncodeURIComponent(this.props.user.username);
                } else {
                    
                    var key = CryptoJS.enc.Utf8.parse(cs);
                    var iv = CryptoJS.enc.Utf8.parse(cs);
                    var cfg = {
                            keySize: 128 / 8,
                            iv: iv,
                            mode: CryptoJS.mode.CBC,
                            padding: CryptoJS.pad.Pkcs7
                        };
                    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(this.props.user.odaKey + ')' + this.props.user.username), key, cfg);

                    var encstr = this.fixedEncodeURIComponent(encrypted.toString());
                    
                    if (!config.blockToken){
                        link += delimiter + 'a=' + encstr;
                    }
                }

            }
            window.open(link, "_blank");
        }

    }

    /*
    handleShowQRG = () => {
        Utility.log('DASHITEM - OPEN');
        this.setState({ showQRG: true });
    }
    
    handleCloseQRG = () => {
        Utility.log('DASHITEM - CLOSE');
        this.setState({ showQRG: false });
    }

    handleChangeQRG = ({ showQRG }) => {
        if(!showQRG){
            this.handleCloseQRG();
        }
        else{
            this.handleShowQRG();
        }
    }
    */

    getEncryptedAuthStringFromQueryString = () => {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === 'a') {
                return decodeURIComponent(pair[1]);
            }
        }
    }

    // Takes the encrypted auth string and the shared secret (cs) and returns the username and auth token 
    decryptEncryptedAuthString = (authString, cs) => {
        var key = CryptoJS.enc.Utf8.parse(cs);    
        var iv = CryptoJS.enc.Utf8.parse(cs);
        var cfg = {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            };
        var decrypted = CryptoJS.AES.decrypt(unescape(authString), key, cfg).toString(CryptoJS.enc.Utf8);
        var delimiterindex = decrypted.indexOf(')',0);
        var authToken = decrypted.substr(0,delimiterindex);
        var username = decrypted.substr(delimiterindex + 1,(decrypted.length - (delimiterindex + 1)));
        return { username: username, authToken: authToken };
    }


    encryptKeyAndUsername = (odaKey, username) => {
        var key = CryptoJS.enc.Utf8.parse(cs);  // '7061737323313233' '7362906312841932'
        var iv = CryptoJS.enc.Utf8.parse(cs);
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(odaKey + ')' + username), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        
        //return(encrypted.toString());
        return this.fixedEncodeURIComponent(encrypted);
    }

    handleExpand = () => {
        //console.log('expand: '+this.props.config.id);
        this.updateRect();

        let newConfig = { ...this.props.config };
        console.log('newConfig.boundingRect: '+this.traceRect(this.collapsedRect));
        newConfig.boundingRect = this.collapsedRect;
        this.props.handleExpandItem(newConfig, this.updateRect);
    }

    // ---- Render Methods ----

    updateRect = () => {
        let positionInfo = null;
        if (this.myRef !== undefined){
            positionInfo = this.myRef.getBoundingClientRect();
            if (positionInfo !== null && positionInfo !== undefined){
                if (this.collapsedRect === undefined || this.rectsAreIdentical(positionInfo, this.collapsedRect)){
                    let newConfig = { ...this.props.config };
                    newConfig.boundingRect = positionInfo;
                    this.props.handleReportBoundingRect(newConfig, this.updateRect);
                }
                this.collapsedRect = positionInfo;
            }
        }
        return positionInfo;
    }

    renderSubWebApplications = () => {
        Utility.log('config: ');
        let swa = this.props.config.webApplications.slice(0,6);

        if (this.props.config.isOwnSubApplication){
            let subconfig = _.clone(this.props.config);
            subconfig.Url = subconfig.link;
            //subconfig.role = subApp.Role;
            swa.push(subconfig);
        }

        Utility.log(swa);
        
        let styles = [ ...this.subWebApplicationStyles[swa.length] ];
        let tooltips = [];

        for (let i=0; i < swa.length; i++){
            let swa_name = swa[i].name;
            if (!swa_name){
                swa_name = swa[i].Name;
            }
            if (swa_name){
                tooltips[i] = (
                  <Tooltip id={swa_name.replace(/\s/g,'')}>
                    <strong>{swa_name}</strong>
                  </Tooltip>
                );
            } else {
                {/* <Tooltip id={(`tt_${i.toString()}`)}></Tooltip> */}
            }

            /*
            if (swa[i].config.link === 'exclude'){
                if (!this.props.appStore.settings.MISC.dontGhostExcluded){
                    styles[i].opacity = '0.4';
                    styles[i].filter = 'blur(1.0px)';
                }
                styles[i].cursor = 'default';
            }
            */
        }

        for (let i=0; i < styles.length; i++){
            let style = { ...styles[i] };
            //style.width = 72;
            //style.height = 72;
            style.backgroundColor = 'rgba(0,0,0,0.15)';
            style.cursor = 'pointer';
            style.position = 'absolute';
            style.boxShadow = 'inset 0 0 5px rgba(0,0,0,0.3)';
            //style.display = 'inline-block';
            
            if (swa[i].icon_small){
                style.backgroundImage = 'url('+swa[i].icon_small+')';
            } else {
                style.backgroundImage = 'url('+images.icon_cog_full+')';
            }

            styles[i] = style;
            styles[i].backgroundSize = 'contain';
        }

        
        
        
        
        return (
            <div style={{ left: 0, top: this.props.appStore.settings.SITE === 'SUNCOR' || this.props.appStore.settings.SITE === 'SYNCRUDE'  || this.props.appStore.settings.SITE === 'CNRL' ? 30 : 93, width: 256, height: 163, position: 'absolute' }}>

               {swa.map((app, i) => {
                    return (
                        <OverlayTrigger key={'overlaytrigger_'+i} placement="top" overlay={tooltips[i]}>
                            <div key={'subweb_'+i} style={styles[i]} onClick={() => this.handleSubWebAppClick(swa[i])} >
                                {/* swa.icon_small ? <div key={'subwebicon_'+i} style={{ left: 10, top: 10, width: 62, height: 62, backgroundImage: 'url('+swa.icon_small+')' }} /> : null */}
                            </div>
                        </OverlayTrigger>
                        );
                })}
            </div>
            );

    }

    render() {
        //let xOffset = this.props.config.expanded ? 0 : 0;
        //let yOffset = this.props.config.expanded ? 0 : 0;
        //let zOffset = this.props.config.expanded ? 0 : 0;
        //console.log(`${this.props.config.id}: xOffset: ${xOffset} yOffset: ${yOffset}`);
        //let positionInfo = document.getElementById('DashItem-'+this.props.config.id).getBoundingClientRect();
        /*
        let positionInfo = null;
        if (this.myRef !== undefined){
            positionInfo = this.myRef.getBoundingClientRect();
            if (positionInfo !== null){

                this.collapsedRect = positionInfo;
                
                //console.log(this.props.config.id+ ' positionInfo: '+positionInfo.left+'x'+positionInfo.top);
                //if (!this.props.config.expanded){
                //    this.collapsedRect = positionInfo;
                //}
                
            }
        }
        */

        /*
        let expandedPlaceholder = this.props.handleGetPlaceholder();
        if (expandedPlaceholder !== undefined){
            this.expandedRect = expandedPlaceholder.getBoundingClientRect();
            //console.log('expandedRect: '+this.expandedRect.top);
        }
        */
        
        //const transform = `perspective(500px) translate3d(${xOffset}px,${yOffset}px,${zOffset})`;
        
        let style = {
            ...this.style,
            visibility: this.props.config.expanded ? 'hidden' : 'visible',

            //transform,
            //WebkitTransform: transform,
            //zIndex: this.props.config.expanded ? 1000 : 1,
            //width: this.props.config.expanded ? this.expandedWidth : this.collapsedWidth,
            //height: this.props.config.expanded ? this.expandedHeight : this.collapsedHeight,
        };

        let textStyle = {};
        
        if (this.props.appStore.settings.SITE === 'CEDASYNCRUDE'){
            style.backgroundColor = '#efc479';  // #103b86 regular  #efc479 inverted
            //style.backgroundImage = 'linear-gradient(rgba(255,255,255,0.7),rgba(255,255,255,0.7)), url('+this.props.config.icon+')';
            //style.background = 'rgba(255,255,255,.7), rgba(255,255,255,0.7)';
            style.filter = 'invert(100%)';
            textStyle.color = '#000000';
            textStyle.textShadow = 'none';
        }

        if (this.props.config.name.length > 35){
            textStyle.marginTop = 166;
        }

        if (this.props.config.link === 'exclude'){
            //style.backgroundColor = '#999999';
            if (!this.props.appStore.settings.MISC.dontGhostExcluded){
                style.opacity = '0.4';
                style.filter = 'blur(1.0px)';
            }
            style.cursor = 'default';
        }

        /*
        if (this.props.config.expanded){
            style.position = 'fixed';
            style.top = '50%';
            style.left = '50%';
            style.width = this.expandedWidth;
            style.height = this.expandedHeight;
            style.marginTop = -this.expandedHeight/2;
            style.marginLeft = -this.expandedWidth/2;
        }
        */

        //const refFunc = (ref) => { this.myRef = ref; this.props.handleReportPlaceholder(ref); return ref; }

    	return (

    		<div onClick={this.handleClick} ref={(ref) => this.myRef = (ref !== null ? ref : this.myRef)} className={this.props.config.expanded ? "DashItem DashItem--expanded" : (this.props.appStore.settings.SITE === 'CEDASYNCRUDE') ? "DashItem2" : (this.props.appStore.settings.SITE === 'BROOKSIDE') ? "DashItem3" : "DashItem"} style={style}>
                <div style={{ width: '100%', height: '100%', cursor: 'pointer' }}>
                    <div ref={(ref) => this.contentRef = ref} className={this.props.config.expanded ? "DashItem__contents DashItem__contents--expanded" : "DashItem__contents"}>
        	    		{this.props.config.name.length > 0 && this.props.config.icon ? <h2 className={this.props.appStore.settings.SITE === 'SUNCOR' || this.props.appStore.settings.SITE === 'SYNCRUDE' || this.props.appStore.settings.SITE === 'CNRL' ? 'DashItem-Title-New' : 'DashItem-Title'} style={textStyle}>{this.props.config.name}</h2> : <h2 className='DashItem-Title' style={textStyle}>{this.props.config.name}</h2> }
                    </div>
                    {(!this.props.config.expanded && this.props.config.webApplications && this.props.config.webApplications.length) ? this.renderSubWebApplications() : null}
                </div>
                { this.props.config.expandable ? <a onClick={this.handleExpand}><div style={{ position: 'absolute', bottom: '0', right: '0', cursor: 'pointer', width: this.expandIconOffset, height: this.expandIconOffset, zIndex: '100' }}><Glyphicon glyph="resize-full" style={{ position: 'absolute', top: '0', left: '0', color: 'rgba(0,0,0,0.6)', backgroundColor: 'rgba(0,0,0,0.1)', padding: '3px', border: '1px solid rgba(0,0,0,0.6)' }}/></div></a> : null }
                {/* 
                <QRG
                        className="content"
                        location={this.props.location}
                        appStore={this.props.appStore}
                        showQRG={this.state.showQRG}
                        closeHelp={this.handleCloseQRG}
                        webApplicationId={this.props.webApplicationId}
                        user={this.state.user}
                />
                */}
            </div>
    	);
    }

    // ---- Utility Methods ----

    rectsAreIdentical = (rect1, rect2) => {
        if (rect1.left !== rect2.left) return false;
        if (rect1.top !== rect2.top) return false;
        if (rect1.width !== rect2.width) return false;
        if (rect1.height !== rect2.height) return false;
        return true;
    }

    rectDifference = (rect1, rect2) => {
        let obj = { left: 0, top: 0, bottom: 0, right: 0, width: 0, height: 0 };
        obj.left = rect2.left - rect1.left;
        obj.top = rect2.top - rect1.top;
        obj.bottom = rect2.bottom - rect1.bottom;
        obj.right = rect2.right - rect1.right;
        obj.width = rect2.width - rect1.width;
        obj.height = rect2.height - rect1.height;
    }

    traceRect = (rect) => {
        return('Rect: left: '+rect.left+' top:'+rect.top+' w: '+rect.width+' h: '+rect.height);
    }

    fixedEncodeURIComponent = (str) => {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
        });
    }


    
}



export default DashItem;
