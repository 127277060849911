import React, { Component } from 'react';
import './App.css';
import Main from './containers/Main';
import API from '../src/lib/API';
import Utility from './lib/Utility';
//import 'airbnb-browser-shims';

// p: https://suncor.sitestays.com/IDF/

class App extends Component {
  constructor(props){
    super(props);

    let { settings } = this.props.appStore;
    API.settings = settings;

    this.state = {
      location: window.location.pathname,
      webApplicationId: settings.WEB_APPLICATION_ID,  
      excludedApplications: settings.EXCLUDED_APPLICATIONS,
      applications: settings.WEB_APPLICATIONS,
      env: settings.ENV,
    };
    Utility.debug = settings.DEBUG !== null ?  settings.DEBUG : false;
  }

  render() {
    if (this.props.appStore.settings.WEB_APPLICATION_ID)
    return (
      <div className="App">
        <Main location={this.state.location}  
          appStore={this.props.appStore} 
          webApplicationId={this.state.webApplicationId}
          applications={this.state.applications} 
          excludedApplications={this.state.excludedApplications}
          env={this.state.env} />
            {this.props.children}
      </div>
    );
    else
      return '';
  }
}

export default App;

/*
      <div className="App">
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2>Welcome to React</h2>
        </div>
        <p className="App-intro">
          To get started, edit <code>src/App.js</code> and save to reload.
        </p>
      </div>
      */