// Navbar represent the global navigation bar at the top of the application

import React from 'react';
import './style.css';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem, Glyphicon, Tooltip, OverlayTrigger  } from 'react-bootstrap';
import LazyHero from 'react-lazy-hero';
import images from '../../config/images';
import Utility  from '../../lib/Utility';
import EventCenter from '../../lib/EventCenter';


class NavbarContainer extends React.Component {

	// Set initial props
    static defaultProps = {
        user: null,
    }

    constructor(props) {
        super(props);

        this.mounted = false;

        this.useShadow = true;

        // Set initial state
        this.state = {
            canChangePassword: false,
            errorMessages: [],
            loginError: '',
            statusMessages: [],
            selectedPlant: this.props.savedPlant,  //this.props.user ? this.props.user.selectedPlant : null,
        };
    }

    componentDidMount() {
        this.mounted = true;
        EventCenter.subscribe('onPlantSelected', this.onPlantSelected);
        this.init();
    }

    componentWillUnmount() {
        this.mounted = false;
        EventCenter.unsubscribe('onPlantSelected', this.onPlantSelected);
    }


    // ---- Member Methods (Use arrow syntax to auto-bind) ----

    init = () => {

    }

    onNavItemSelected = (eventKey, event) => {
      if (eventKey === 3.0) {
        // logout
        this.props.logoutClicked();
      } else if (eventKey === 3.1){
        this.props.changePasswordClicked();
      } else if (eventKey === 3.2){
        EventCenter.broadcast('onShowPlantMenu', {});
      } else if (eventKey === 3.3){
        EventCenter.broadcast('onShowLogInAsModal', {});
      } 
      else if (eventKey === 1){

      }
    }

    onPlantSelected = (plant) => {
      this.setState({ selectedPlant: plant });
    }

    onPlantNameClicked = (e) => {
      e.preventDefault();
      EventCenter.broadcast('onShowPlantMenu', {});
    }


    // ---- Render Methods ----

    getUserName = () => {
        if (this.props.user !== null){
          return this.props.user.email;
        }
        return '';
    }

    renderTooltip = (props) => {
      return (
        <Tooltip id="button-tooltip" {...props}>
          Simple tooltip
        </Tooltip>
      );
    }


    render() {
        let { settings } = this.props.appStore;

        if (this.props.appStore.settings.SITE === 'SUNCOR' || this.props.appStore.settings.SITE === 'SYNCRUDE' || this.props.appStore.settings.SITE === 'CNRL'){
          // new render behavior
          return (
            <div className='NavbarContainer'>
                {
              <LazyHero color={'#FFFFFF'} minHeight='60px' opacity={0.0} parallaxOffset={50}>
                  
              </LazyHero>
                }
              <div className='NavbarInner-New'>
                  <Navbar collapseOnSelect fluid={true}>
                      <Navbar.Header>
                        <Navbar.Brand style={{ display: 'flex', alignItems: 'center'}}>
    
                          <img src={this.props.applicationName} style={{ maxWidth: this.props.appStore.settings.NAVBAR_APP_LOGO_WIDTH ? this.props.appStore.settings.NAVBAR_APP_LOGO_WIDTH : '300px', height: 'auto', padding: '0px 24px', margin: this.props.appStore.settings.NAVBAR_APP_LOGO_MARGIN }}  alt={''} />
                          {<img src={this.state.selectedPlant && this.state.selectedPlant.logo ? this.state.selectedPlant.logo : this.props.clientLogo} style={{ maxWidth: '307px', height: 'auto', padding: '0px 24px', margin: this.props.appStore.settings.NAVBAR_CLIENT_LOGO_MARGIN }} alt={''} />}
                          
                          {/* <img src={this.props.applicationName} style={{ maxWidth: this.props.appStore.settings.NAVBAR_APP_LOGO_WIDTH ? this.props.appStore.settings.NAVBAR_APP_LOGO_WIDTH : '300px', height: 'auto', padding: '0px 24px', marginBottom: '-5px', marginLeft: '-10px' }}  alt={''} />
                          {<img src={this.state.selectedPlant && this.state.selectedPlant.logo ? this.state.selectedPlant.logo : this.props.clientLogo} style={{ maxWidth: '190px', height: 'auto', padding: '0px 24px', marginLeft: '-70px', marginTop: '-1px'}} alt={''} />} */}
                          {this.props.env !== 'prod' ? <span style={{ color: '#FF0000' }}>{this.props.env}</span> : null }
                          {this.state.selectedPlant ? <a href='#' onClick={this.onPlantNameClicked} className='nav-plant-header'>{this.state.selectedPlant.name}</a> : null}
                        </Navbar.Brand>
                        <Navbar.Toggle />
                      </Navbar.Header>
                      <Navbar.Collapse>
                        <Nav>
                          {/* 
                          <NavItem eventKey={1} href="#">LMS</NavItem>
                          <NavItem eventKey={2} href="#">ADF</NavItem>
                          <NavItem eventKey={4} href="#">LDF</NavItem>
                          <NavItem eventKey={5} href="#">FIFO</NavItem>
                          */}
                        </Nav>
                        
                        <Nav pullRight onSelect={this.onNavItemSelected}>
                          {/* <NavItem eventKey={3.5}>
                          <OverlayTrigger placement="bottom"
                              overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    Guides
                                  </Tooltip>
                                }
                              >
                              <img src={images.icon_help} style={{ position: 'absolute', width: '110%', height: 'auto', top:10, bottom: 0, left: 0, right: 0 }}/>
                                
                              </OverlayTrigger>
                          </NavItem> */}
                          {/* <div className='navbar-profile-container'> */}
                            <div className='navbar-profile'>
                              <img src={images.profile} alt={''} /><br />
                            </div>
                            {/* <div className='navbar-profile-container-inner'> */}
                              <NavItem eventKey={0}><strong><div className='navbar-username' style={settings.NAVBAR_BKG_COLOR !== '#FFF' ? { color: '#333'  } : {}} >{this.getUserName()}</div></strong></NavItem>
                              <NavDropdown onClick={(e) => e.stopPropagation()} eventKey={3} title={<div style={settings.NAVBAR_BKG_COLOR !== '#FFF' ? { display: "inline-block", color: '#333'  } : {display: "inline-block" }} ><Glyphicon glyph="option-vertical" /></div>} id="basic-nav-dropdown">
                                <MenuItem eventKey={3.1}>Change Password</MenuItem>
                                {this.props.appStore.settings.SITES.length !== 1 ? <MenuItem eventKey={3.2}>Select Site</MenuItem> : null}
                                <MenuItem divider />
                                {this.props.user && this.props.user.profile && this.props.user.profile.isSuperAdmin ? <MenuItem eventKey={3.3}>Log In As...</MenuItem> : null}
                                <MenuItem eventKey={3.0}>Log Out</MenuItem>
                              </NavDropdown>
                            {/* </div> */}
                            <div className='navbar-location'>{}</div>
                          {/* </div> */}
                        </Nav>
                        
                      </Navbar.Collapse>
                    </Navbar>
              </div>
              
            </div>
          );
        } 
        
       
        // original render behavior
        return (
          <div className='NavbarContainer'>
            <LazyHero imageSrc={settings.NAVBAR_BKG_IMAGE ? settings.NAVBAR_BKG_IMAGE.indexOf('images/',0) !== -1 ? settings.NAVBAR_BKG_IMAGE : images[settings.NAVBAR_BKG_IMAGE] : images.hero} color={settings.NAVBAR_BKG_COLOR} minHeight='60px' opacity={settings.NAVBAR_BKG_OPACITY} parallaxOffset={50}>
                
                {this.useShadow && <img src={images.shadow_bottom_inner} style={{ position: 'absolute', width: '100%', height: '15px', bottom: 0, left: 0, right: 0, borderBottom: '1px solid rgba(0,0,0,0.3)' }} alt={''} /> }
            </LazyHero>
            <div className='NavbarInner'>
                <Navbar collapseOnSelect fluid={true}>
                    <Navbar.Header>
                      <Navbar.Brand style={{ display: 'flex', alignItems: 'center'}}>

                        <img src={this.props.applicationName} style={{ maxWidth: this.props.appStore.settings.NAVBAR_APP_LOGO_WIDTH ? this.props.appStore.settings.NAVBAR_APP_LOGO_WIDTH : '300px', height: 'auto', padding: '0px 24px', margin: this.props.appStore.settings.NAVBAR_APP_LOGO_MARGIN }}  alt={''} />
                        {<img src={this.state.selectedPlant && this.state.selectedPlant.logo ? this.state.selectedPlant.logo : this.props.clientLogo} style={{ maxWidth: '190px', height: 'auto', padding: '0px 24px', margin: this.props.appStore.settings.NAVBAR_CLIENT_LOGO_MARGIN }} alt={''} />}
                        
                        {/* <img src={this.props.applicationName} style={{ maxWidth: this.props.appStore.settings.NAVBAR_APP_LOGO_WIDTH ? this.props.appStore.settings.NAVBAR_APP_LOGO_WIDTH : '300px', height: 'auto', padding: '0px 24px', marginBottom: '-5px', marginLeft: '-10px' }}  alt={''} />
                        {<img src={this.state.selectedPlant && this.state.selectedPlant.logo ? this.state.selectedPlant.logo : this.props.clientLogo} style={{ maxWidth: '190px', height: 'auto', padding: '0px 24px', marginLeft: '-70px', marginTop: '-1px'}} alt={''} />} */}
                        {this.props.env !== 'prod' ? <span style={{ color: '#FF0000' }}>{this.props.env}</span> : null }
                        {this.state.selectedPlant ? <a href='#' onClick={this.onPlantNameClicked} className='nav-plant-header'>{this.state.selectedPlant.name}</a> : null}
                      </Navbar.Brand>
                      <Navbar.Toggle />
                    </Navbar.Header>
                    <Navbar.Collapse>
                      <Nav>
                        {/* 
                        <NavItem eventKey={1} href="#">LMS</NavItem>
                        <NavItem eventKey={2} href="#">ADF</NavItem>
                        <NavItem eventKey={4} href="#">LDF</NavItem>
                        <NavItem eventKey={5} href="#">FIFO</NavItem>
                        */}
                      </Nav>
                      
                      <Nav pullRight onSelect={this.onNavItemSelected}>
                        {/* <NavItem eventKey={3.5}>
                        <OverlayTrigger placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-bottom`}>
                                  Guides
                                </Tooltip>
                              }
                            >
                            <img src={images.icon_help} style={{ position: 'absolute', width: '110%', height: 'auto', top:10, bottom: 0, left: 0, right: 0 }}/>
                              
                            </OverlayTrigger>
                        </NavItem> */}
                        <NavItem eventKey={0}><strong><div style={settings.NAVBAR_BKG_COLOR !== '#FFF' ? { color: '#FFF'  } : {}} >{this.getUserName()}</div></strong></NavItem>
                        <NavDropdown onClick={(e) => e.stopPropagation()} eventKey={3} title={<div style={settings.NAVBAR_BKG_COLOR !== '#FFF' ? { display: "inline-block", color: '#FFF'  } : {display: "inline-block" }} ><Glyphicon glyph="option-vertical" /></div>} id="basic-nav-dropdown">
                          <MenuItem eventKey={3.1}>Change Password</MenuItem>
                          {this.props.appStore.settings.SITES.length !== 1 ? <MenuItem eventKey={3.2}>Select Site</MenuItem> : null}
                          <MenuItem divider />
                          {this.props.user && this.props.user.profile && this.props.user.profile.isSuperAdmin ? <MenuItem eventKey={3.3}>Log In As...</MenuItem> : null}
                          <MenuItem eventKey={3.0}>Log Out</MenuItem>
                        </NavDropdown>
                      </Nav>
                      
                    </Navbar.Collapse>
                  </Navbar>
            </div>
            
          </div>
    	);
    }
}

export default NavbarContainer;
