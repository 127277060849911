//import 'babel-polyfill';
/*
import 'airbnb-browser-shims';
import 'core-js/stable';
import 'core-js/modules/es.promise';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.object.assign';
import 'core-js/modules/es.object.keys';
import 'core-js/modules/es.symbol';
import 'core-js/modules/es.symbol.async-iterator';
*/
//import 'regenerator-runtime/runtime';
import 'bootstrap3/dist/css/bootstrap.css';				// bootstrap 3 import, comment if you want to remove bootstrap 3 from the project
import 'bootstrap3/dist/css/bootstrap-theme.css';		// bootstrap 3 import, comment if you want to remove bootstrap 3 from the projet
//import 'bootstrap/dist/css/bootstrap.css';			// bootstrap 4 import
//import 'jquery';										// optional jQuery import
//require('bootstrap'); 								// if you want the jQuery Plugins (bootstrap 4)
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AppStore from './config/AppStore';
import SettingsLoader from './config/SettingsLoader';

//import registerServiceWorker from './registerServiceWorker';	// This can lead to some weird caching issues, uncommenting this for heavy sites might not be a bad idea but for light ones keep it out.

let mSettings = new SettingsLoader();

 mSettings.load()
.then(settings => {
    console.log('settings');
    console.log(settings)
    let appStore = new AppStore(settings);
    appStore.settings = settings;
    
    //ReactDOM.render(<Router basename={'/'} history={window.matchMedia('(display-mode: standalone)').matches ? createHashHistory() : createBrowserHistory()}><App appStore={appStore} /></Router>, document.getElementById('root'));
    //ReactDOM.render(<HashRouter basename='/'><App appStore={appStore} /></HashRouter>, document.getElementById('root'));
    
    // Check to see if browser is IE
    let ua = window.navigator.userAgent;
    let msie = ua.indexOf('MSIE ');
    let trident = ua.indexOf('Trident/');
    let isIE = msie > -1 || trident > -1;
    if (isIE) {
      document.getElementById('root').style.display = 'flex';
      document.getElementById('root').style.justifyContent = 'center';
      document.getElementById('root').style.alignItems = 'center';
      document.getElementById('root').style.flexDirection = 'column';
      document.getElementById('root').style.height = '100vh';
      document.getElementById('root').style.width = '100vw';
      document.getElementById('root').style.backgroundColor = '#ffffff';
      document.getElementById('root').style.color = '#000000';
      document.getElementById('root').style.fontSize = '2em';
      document.getElementById('root').style.fontWeight = 'bold';
      document.getElementById('root').style.textAlign = 'center';
      document.getElementById('root').style.padding = '0px 10px';
      // add this element to root
      document.getElementById('root').innerHTML = '<p>Sorry, for security purposes, this application no longer supports Internet Explorer, please visit this site with another browser.</p>';
    } else {
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(<App appStore={appStore} />)
    }

});
//registerServiceWorker();										// This can lead to some weird caching issues, uncommenting this for heavy sites might not be a bad idea but for light ones keep it out.

