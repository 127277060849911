// Dashboard - v1.0
// Dave Cole
// Copyright 2017 On Demand Analysis
// Dashboard is the root container for the SiteServices landing page/dashboard, it contains a Navbar, a DashCollection, and other useful components.

import React from 'react';
import './style.css';
//import $ from 'jquery';
import moment from 'moment';
//import Navbar from '../components/Navbar';
//import NavbarContainer from '../components/NavbarContainer';
import DashCollection from '../components/DashCollection';
import EventCenter from '../../lib/EventCenter';

//import ExpandableDiv from '../components/ExpandableDiv';
//import ToggleDiv from '../components/ToggleDiv';
//import CollapsableTabs from '../components/CollapsableTabs';
//import images from '../../config/images';
//import _ from 'lodash';

moment().format();

class Dashboard extends React.Component {

	// Set initial props
    static defaultProps = {
        
    }

    constructor(props) {
        super(props);

        this.mounted = false;

        this.style = {
            
        };

        // Set initial state
        this.state = {
            errorMessages: [],
            statusMessages: [],
            
        };
    }

    componentDidMount() {
        this.mounted = true;

        
        this.init();
        
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillUpdate(nextProps, nextState){

    }


    // ---- Member Methods (Use arrow syntax to auto-bind) ----

    init = () => {

    }

    

   
    // ---- Render Methods ----

    render() {
        
        //if (this.props.user && this.props.applications && this.props.applications.length === 0) EventCenter.broadcast('onUnauthorized', {});
    	return (
    		<div className='Dashboard' style={this.style}>
	    		<div className='container-fluid'>
                    

                    <DashCollection dashItems={this.props.applications} user={this.props.user} appStore={this.props.appStore}/>


	    		</div>
    		</div>
    	);
    }
}

export default Dashboard;
