// Simple global event manager singleton (yeah, it's an anti-pattern, I know...)

class EventCenter {
    constructor() {
        this._type = 'EventCenterInstance';
        this.events = {};
    }

    subscribe(name, handler){
        this.events[name] ? this.events[name].handlers.push(handler) : this.events[name] = { handlers: [handler] };
    }

    unsubscribe(name, handler){
        let index = this.events[name].handlers.indexOf(handler);
        if (index > -1){
            this.events[name].handlers.splice(index,1);
        }
    }

    broadcast(name, args){
        if (this.events[name]){
            for (let i=0; i < this.events[name].handlers.length;i++){
                this.events[name].handlers[i].call(null,args);
            }
        }
    }

    get type(){
        return this._type;
    }

    set type(value){
        this._type = value;
    }
}

export default new EventCenter();
