/* eslint-disable global-require */

// The below user store has been deprecated and is now held in Main.js's state.user object, stored locally as a single source of truth.
export const user = {
	email: '',
	username: '',
	changePassword: false,
	role: '',
	odaKey: '',
	expiration: new Date(),
	webApplications: [],
	availableApplications: [],
	errorMessage: '',
};

export const emptyUser = {
	email: '',
	username: '',
	changePassword: false,
	role: '',
	odaKey: '',
	expiration: new Date(),
	webApplications: [],
	availableApplications: [],
	errorMessage: '',
};

export const secret = 'd6c6615d-dccb-42cd-8a78-a2e35e9abf83';
// we should use '@System.Configuration.ConfigurationManager.AppSettings["varName"]'; to get cs
export const cs = '7362906312841932';